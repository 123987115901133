import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Divider, Typography as T } from '@mui/material';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { TEXT_APP_DOMAIN } from '../../flags';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import { BetaFeature, TextWithCopy } from '../Group/GroupPublicControls';

/**
 * @param {Object} props
 * @param {TemplateData|null|false} props.templateData
 * @param {string} props.collectionId
 * @param {boolean} props.isLoading
 * @param {"folder"|"space"} props.collectionType
 */
export function TemplateShare({ collectionType, templateData, collectionId, isLoading }) {
  const { pathname } = useLocation();

  return (
    <>
      {isLoading
        ? <div style={{ paddingTop: '20px', marginBottom: 14, justifyContent: 'center', display: 'flex' }}>
          <CircularProgress size={100} thickness={1.5}/>
        </div>
        :
        <>
          <Divider sx={{ my: 3 }} />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            mb: 2
          }}
          >
            <PermMediaOutlinedIcon fontSize="small" sx={{ mr: 1 }}/>
            <T variant="h6">{
              templateData
                ? templateData.publishingState === 'approved'
                  ? 'Listed in the templates library'
                  : 'Edit your submission to the template library'
                : 'Add template to the library'}</T>

            <BetaFeature />
          </Box>

          <T paragraph gutterBottom>
          Templates can be listed in our public <a href="/templates">templates library</a>,
          where users can browse through free and paid templates, and import them.</T>

          {templateData && <T sx={{
            fontSize: '14px',
            textAlign: 'right',
            mb: 1,
          }}>
          Status: <b>{templateData.publishingState.replace('_', ' ')}</b>
          </T>}

          <Box sx={{ display: 'grid',
            gridTemplateColumns: 'auto min-content',
            gap: '12px',
            alignItems: 'center'
          }}>

            {templateData && templateData.publishingState === 'approved'
              ? <TextWithCopy url={`${TEXT_APP_DOMAIN}/templates/get/${templateData && templateData.paidUuid}`} />
              : <div></div>
            }

            <Button
              component={Link}
              to={{ pathname: `/template/${collectionType}/${collectionId}`, state: { prevPath: pathname } }}
              color="primary"
              variant="outlined"
              sx={{
                minWidth: 'max-content',
              }}
            >
              {templateData ? 'Edit the template' : 'Submit to the templates library'}
            </Button>
          </Box>
        </>}
    </>

  );
}