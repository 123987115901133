import React from 'react';
import Callout from '../Callout/Callout';
import WarningIcon from '@mui/icons-material/WarningRounded';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { billingIssue } from '../../flags';
import AsyncButton from '../AsyncButton/AsyncButton';


const CONFIGS = {
  pro: {
    msg: <span><b><WarningIcon style={{ verticalAlign: 'middle', color: '#bf7326', marginRight: 6 }} /> Billing failure</b>: Please update your billing information promptly or you will lose access to Text Blaze Pro features.</span>,
    showCardButton: true
  },
  proNoCard: {
    msg: <span><b><WarningIcon style={{ verticalAlign: 'middle', color: '#bf7326', marginRight: 6 }} /> No card</b>: Please add your billing information or you will lose access to Text Blaze Pro features when your credits run out.</span>,
    showCardButton: true,
    buttonText: 'Add credit card'
  },
  orgOwner: {
    msg: <span><b><WarningIcon style={{ verticalAlign: 'middle', color: '#bf7326', marginRight: 6 }} /> Billing failure</b>: Please update your billing information promptly or you will lose access to Text Blaze organization features. Contact <a href="mailto:support@blaze.today">support@blaze.today</a> with any questions.</span>,
    showCardButton: true
  },
  orgOwnerTrial: {
    msg: <span><b><WarningIcon style={{ verticalAlign: 'middle', color: '#bf7326', marginRight: 6 }} /> Trial ends</b>: Your trial is about to end.. Please add your billing information promptly or you will lose access to Text Blaze organization features. Contact <a href="mailto:sales@blaze.today">sales@blaze.today</a> with any questions.</span>,
    showCardButton: true,
    buttonText: 'Add credit card'
  },
  orgOwnerNoCard: {
    msg: <span><b><WarningIcon style={{ verticalAlign: 'middle', color: '#bf7326', marginRight: 6 }} /> No card</b>: Please add your billing information or you will lose access to Text Blaze organization features when your credits run out.</span>,
    showCardButton: true,
    buttonText: 'Add credit card'
  },
  orgNonOwner: {
    msg: <span><b><WarningIcon style={{ verticalAlign: 'middle', color: '#bf7326', marginRight: 6 }} /> Organization Account Issue</b>: Please have your Text Blaze administrator contact <a href="mailto:support@blaze.today">support@blaze.today</a> as soon as possible to resolve this issue.</span>,
    showCardButton: false
  },
  enterpriseOrgOwner: {
    msg: <span><b><WarningIcon style={{ verticalAlign: 'middle', color: '#bf7326', marginRight: 6 }} /> Organization Account Issue</b>: Please update your billing information promptly or you will lose access to Text Blaze organization features. Contact <a href="mailto:support@blaze.today">support@blaze.today</a> as soon as possible to resolve this issue.</span>,
    showCardButton: false
  }
};

/**
 * 
 * @param {object} props 
 * @param {boolean=} props.micro
 * @param {((type, issue, email: string) => Promise)=} props.action
 * @param {boolean=} props.onlyAction
 */
function BillingIssueBase(props) {
  let data = useTypedSelectorShallowEquals((store) => billingIssue(store));

  /*
  For testing:

  data = {
    type: { type: 'user' },
    issue: {},
    email: 'foo@example.com',
    userType: 'individual'
  };

  data = {
    type: { type: 'org', orgId: 'abc' },
    issue: { attempt_count: 1 },
    email: 'foo@example.com',
    userType: 'owner'
  };

  data = {
    type: { type: 'org', orgId: 'abc' },
    issue: { attempt_count: 3 },
    email: 'foo@example.com',
    userType: 'editor'
  };*/
  if (!data) {
    return null;
  }
  
  let {
    type,
    issue,
    email,
    userType,
    isEnterprise
  } = data;
  const getConfig = () => {
    if (type.type === 'user') {
      if (issue.type === 'no_card') {
        if (issue.card_added) {
          return null;
        }
        return CONFIGS.proNoCard;
      }
      return CONFIGS.pro;
    }
    switch (userType) {
    case 'owner':
      if (isEnterprise) {
        if (issue.type === 'no_card') {
          return null;
        }
        return CONFIGS.enterpriseOrgOwner;
      } else if (issue.type === 'trialling_ends') {
        if (issue.card_added) {
          return null;
        }
        return CONFIGS.orgOwnerTrial;
      } else if (issue.type === 'no_card') {
        if (issue.card_added) {
          return null;
        }
        return CONFIGS.orgOwnerNoCard;
      } else {
        return CONFIGS.orgOwner;
      }
    case 'editor':
      // We want to give owners a chance to address it before it is surfaced to 
      // editors.
      if (issue.attempt_count > 1 || issue.type === 'unpaid') {
        return CONFIGS.orgNonOwner;
      } else {
        return null;
      }
    default: 
      // We want to give owners and editors a chance to address it
      // before it is surfaced to members.
      if (issue.attempt_count > 2 || issue.type === 'unpaid') {
        return CONFIGS.orgNonOwner;
      } else {
        return null;
      }
    }
  };
  let config = getConfig();
  if (!config) {
    return null;
  }
  const actionButton = props.action && config.showCardButton && (!props.micro) && <AsyncButton
    size="small"
    variant="outlined"
    onClick={async (done) => {
      try {
        await props.action(type, issue, email);
      } finally {
        done();
      }
    }}
  >
    {config.buttonText || 'Update credit card'}
  </AsyncButton>;
  if (props.onlyAction) {
    return actionButton;
  }

  return <Callout
    warning
  >
    <div
      data-testid="billing-issue-container"
      style={{
        textAlign: 'center',
        paddingLeft: !props.micro && 120,
        paddingRight: !props.micro && 120
      }}
    >
      <span style={{ marginRight: 12 }}>{config.msg}</span>
      {actionButton}
    </div>
  </Callout>;
}


const BillingIssue = React.memo(BillingIssueBase);
export default BillingIssue;