import React from 'react';
import { useTypedSelector } from '../../hooks';

const UserLink = React.forwardRef(/**
  * @param {Parameters<React.ElementType>['0'] & { email: string, disabled?: boolean }} props
  */
  ({
    email,
    disabled,
    children,
    ...otherProps
  }, ref) => {
    const showLink = useTypedSelector(state => !!state?.orgState?.org?.id);
    if (!showLink || disabled) {
      return <span ref={ref} {...otherProps}>{children}</span>;
    }
    return <a ref={ref} href={`#user/${email}`} {...otherProps}>{children}</a>;
  }
);

export default UserLink;