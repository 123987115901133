import React, { useState, useRef } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AppsIcon from '@mui/icons-material/Apps';
import { List, ListItemAvatar, Avatar, ListItemText, ListItemButton, Chip } from '@mui/material';
import DbIcon from '@mui/icons-material/GridOn';
import TbIcon from '@mui/icons-material/TextSnippetOutlined';
import PbIcon from '@mui/icons-material/WebOutlined';
import AiIcon from '@mui/icons-material/AutoFixHigh';
import { log } from '../../logging/logging';
import AppLink from '../Utilities/AppLink';
import { isBlaze, isElectronApp, isPageBlazeEnabled } from '../../flags';
import { useTypedSelector } from '../../hooks';
import { aiTheme, dataTheme, pageTheme, textTheme } from '../Theme/themes';
import { wasOnboardedWithAiBlaze } from '../../aiBlaze';



/**
 * @param {object} props
 * @param {AppType} props.appType
 */
function SwitcherMenuBase(props) {
  let [open, setOpen] = useState(false);
  let ref = useRef();
  const enablePageBlaze = useTypedSelector(state => isPageBlazeEnabled(state));
  const isAiOnboarded = useTypedSelector(state => wasOnboardedWithAiBlaze(state.userState) || isBlaze(state.userState));
  const isDesktop = isElectronApp();

  return <>
    <Tooltip
      title="Applications"
    >
      <IconButton
        onClick={() => {
          setOpen(!open);

        }}
        color="inherit"
        size="small"
        sx={{
          marginRight: {
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2
          }
        }}
        ref={ref}
      >
        <AppsIcon/>
      </IconButton>
    </Tooltip>
    {open &&
      <Popover
        open
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItemButton
              selected={props.appType === 'TEXT'}
              component={AppLink}
              appType="TEXT"
              to="/snippets/"
              onClick={() => {
                if (props.appType !== 'TEXT') {
                  log({
                    action: 'App switch',
                    label: 'TEXT'
                  });
                }

                setOpen(false);
              }}
            >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: textTheme.palette.secondary.main }}>
                  <TbIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Text Blaze" secondary="Snippets and templates" />
            </ListItemButton>
            {isAiOnboarded && !isDesktop && <ListItemButton
              selected={props.appType === 'AI'}
              component={AppLink}
              appType="AI"
              to="/"
              onClick={() => {
                if (props.appType !== 'AI') {
                  log({
                    action: 'App switch',
                    label: 'AI'
                  });
                }

                setOpen(false);
              }}
            >
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: aiTheme.palette.secondary.main }}>
                  <AiIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<>AI Blaze <Chip size="small" label="Beta" variant="outlined" style={{ marginLeft: 12 }}/></>} secondary="AI writing and editing" />
            </ListItemButton>}
            <ListItemButton
              selected={props.appType === 'DATA'}
              component={AppLink}
              appType="DATA"
              to="/spaces/"
              onClick={() => {
                if (props.appType !== 'DATA') {
                  log({
                    action: 'App switch',
                    label: 'DATA'
                  });
                }

                setOpen(false);
              }}
            >
              <ListItemAvatar >
                <Avatar style={{ backgroundColor: dataTheme.palette.secondary.main }}>
                  <DbIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<>Data Blaze <Chip size="small" label="Beta" variant="outlined" style={{ marginLeft: 12 }}/></>} secondary="Tables and sheets" />
            </ListItemButton>
            {enablePageBlaze && <ListItemButton
              selected={props.appType === 'PAGE'}
              component={AppLink}
              appType="PAGE"
              to="/sites/"
              onClick={() => {
                if (props.appType !== 'PAGE') {
                  log({
                    action: 'App switch',
                    label: 'PAGE'
                  });
                }

                setOpen(false);
              }}
            >
              <ListItemAvatar >
                <Avatar style={{ backgroundColor: pageTheme.palette.secondary.main }}>
                  <PbIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<>Page Blaze <Chip size="small" label="Beta" variant="outlined" style={{ marginLeft: 12 }}/></>} secondary="Dashboards and apps" />
            </ListItemButton>}
          </List>
        </div>
      </Popover>}
  </>;
}


const SwitcherMenu = React.memo(SwitcherMenuBase);
export default SwitcherMenu;