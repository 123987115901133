
import { httpsCallable } from 'firebase/functions';
import { getTBFunctions } from './firebase_utilities';

/**
 * @type {import('firebase/functions').HttpsCallable<any, any>}
 */
let bapi = httpsCallable(getTBFunctions(), 'bapi');

export async function subscribeProPayment(data) {
  const response = await bapi({
    type: 'subscribe_pro_payment',
    data
  });
  return response.data;
}

export async function subscribeOrgPayment(data) {
  const response = await bapi({
    type: 'subscribe_org_payment',
    data
  });
  return response.data;
}


export function unsubscribePro() {
  return bapi({
    type: 'unsubscribe_pro'
  });
}


export function checkChangeBillingFrequency(data) {
  return bapi({
    type: 'check_change_billing_frequency',
    data
  });
}

export function changeBillingFrequency(data) {
  return bapi({
    type: 'change_billing_frequency',
    data
  });
}


export function cancelUnsubscribePro() {
  return bapi({
    type: 'cancel_unsubscribe_pro'
  });
}


export function getBillingInformation(data) {
  return bapi({
    type: 'get_billing_information',
    data
  });
}


export function changeCustomerDescription(data) {
  return bapi({
    type: 'set_billing_description',
    data
  });
}


export function listOrgInvoices(data) {
  return bapi({
    type: 'list_org_invoices',
    data
  });
}


export async function restartBilling() {
  const response = await bapi({
    type: 'restart_billing_payment'
  });
  return response.data;
}

/**
 * @param {{entityId: string, entityType: ('group' | 'org'), users_added: string[]}} data
 */
export function sendShare({
  entityId,
  entityType,
  users_added
}) {
  return bapi({
    type: 'send_share',
    data: {
      [`${entityType}_id`]: entityId,
      users_added
    }
  });
}

/**
 * @param {{group_id: string, unpublic?: boolean}} data
 */
export function groupPublic(data) {
  return bapi({
    type: 'group_public',
    data
  });
}

/**
 * 
 * @param {{emails: string[], referral_code: string}} data 
 * @returns 
 */
export function sendReferrals(data) {
  return bapi({
    type: 'referrer_invite',
    data
  });
}

/**
 * 
 * @param {{emails: string[]}} data 
 * @returns 
 */
export function sendInvite(data) {
  return bapi({
    type: 'invite',
    data
  });
}
/**
 * 
 * @param {{emails: string[], referral_code: string, platform: string}} data 
 * @returns 
 */
export function sendInviteReferralCredits(data) {
  return bapi({
    type: 'invite-for-credit',
    data
  });
}

/**
 * @param {{referral_code: string}} data 
 */
export function applyCode(data) {
  return bapi({
    type: 'apply_code',
    data
  });
}


/**
 * 
 * @param {{force_new_code?: boolean}=} data 
 * @returns 
 */
export function getUserReferralCode(data) {
  return bapi({
    type: 'get_user_referral_code',
    data
  });
}


/**
 * @param {{org_id: string}} data 
 */
export function joinOrg(data) {
  return bapi({
    type: 'join_organization',
    data
  });
}


export function organizationMembers(data) {
  return bapi({
    type: 'organization_members',
    data
  });
}

/**
 * 
 * @param {string} email 
 * @returns {Promise<{
 *  uid: string,
 *  name: string,
 *  imageUrl: string,
 *  email: string,
 *  emailVerified: boolean
 * }>}
 */
export async function organizationMember(email) {
  const response = await bapi({
    type: 'organization_member',
    data: {
      email
    }
  });
  return response.data;
}


/**
 * @param {{target_email: string}} data
 */
export function sendOrgInvite(data) {
  return bapi({
    type: 'send_org_invite',
    data
  });
}


export function resendUnacceptedOrgInvites(data) {
  return bapi({
    type: 'resend_org_invites',
    data
  });
}


export function leaveOrganization(data) {
  return bapi({
    type: 'leave_organization',
    data
  });
}


/**
 * @param {{groupId: string, action: string}} data
 */
export function updateOrgAssociation(data) {
  return bapi({
    type: 'update_group_org_association',
    data
  });
}


export function publishSnippet(data) {
  return bapi({
    type: 'publish_snippet',
    data
  });
}


export function getHistory(data) {
  return bapi({
    type: 'get_history',
    data
  });
}

/**
 * Lists all the groups of a user, who has sole ownership
 * @param {string} uid 
 * @returns {Promise<GroupObjectType[]>}
 */
export async function listGroups(uid) {
  const result = await bapi({
    type: 'list_groups',
    data: {
      uid
    }
  });
  return result.data;
}

/**
 * Generate setup intent.
 * @param {object} data
 * @param {('org')=} data.type
 * @param {string=} data.orgId
 * @returns {Promise<{ setupId: string, setupSecret: string }>}
 */
export async function generateSetupIntent(data) {
  const response = await bapi({
    type: 'setup_intent',
    data
  });
  return response.data;
}


/**
 * Sets/remove team size.
 * @param {number=} size - null/undefined will remove the team size
 * @returns {Promise<string>}
 */
export async function changeTeamSize(size) {
  const response = await bapi({
    type: 'change_team_size',
    data: {
      new_size: size
    }
  });
  return response.data;
}

/**
 * Gets the pending payment intent
 */
export async function pendingPayment() {
  const response = await bapi({
    type: 'pending_payment'
  });
  return response.data;
}


/**
 * Restores a group.
 * @param {string} groupId
 */
export async function restoreGroup(groupId) {
  const response = await bapi({
    type: 'restore_group',
    data: {
      groupId
    }
  });
  return response.data;
}


/**
 * Restores a snippet.
 * @param {string} snippetId
 */
export async function restoreSnippet(snippetId) {
  const response = await bapi({
    type: 'restore_snippet',
    data: {
      snippetId
    }
  });
  return response.data;
}

/**
 * @param {string} id
 * @param {string} slug
 */
export async function templatesSlugUniqueness(id, slug) {
  const response = await bapi({
    type: 'templates_slug_uniqueness',
    data: {
      id,
      slug
    }
  });

  return response.data?.unique;
}

/**
 * @param {string} id
 * @param {string} handle
 */
export async function templatesHandleUniqueness(id, handle) {
  const response = await bapi({
    type: 'templates_handle_uniqueness',
    data: {
      id,
      handle
    }
  });

  return response.data?.unique;
}

/**
 * @param {string} id
 */
export function incrementTemplateInstallationsCounter(id) {
  return bapi({
    type: 'incrementTemplateInstallationsCounter',
    data: {
      id
    }
  });
}