import { deleteTokenCookie, getTokenCookie } from '../flags';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { toast } from '../messageToast';
import useOnMount from './useOnMount';
import { deleteSessionCookie } from '../session_utils';

export default function useTokenCookie() {
  useOnMount(() => {
    const token = getTokenCookie();
    if (!token) {
      return;
    }

    // clear the __session cookie so that a new one can be created
    deleteSessionCookie();

    signInWithCustomToken(getAuth(), token)
      .then(deleteTokenCookie)
      .catch(err => toast('Failed to login. Please try again after sometime. ' + err.message, {
        intent: 'danger'
      }));
  });
}
