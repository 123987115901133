import React from 'react';
import { CreateDatabaseDialog } from './CreateDatabaseDialog';
import { DatabaseAirtableImportDialog } from './DatabaseAirtableImportDialog';
import { DatabaseTemplatesDialog } from './DatabaseTemplatesDialog';
import { makeConfig } from '../SnippetPreview/preview_utilities';
import { sync, updateFullGroupPermissions } from '../../Sync/syncer';
import { Environment } from '../../snippet_processor/DataContainer';
import { createDom } from '../../snippet_processor/SnippetProcessor';
import { compressDelta, decompressDelta } from '../../delta_proto/DeltaProto';
import { featureUsage } from '../Version/limitations';
import { DatabaseSnapshotsDialog } from './DatabaseSnapshotsDialog';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import T from '@mui/material/Typography';
import { doSafeTableRequest } from '../../hooks/useTables';
import { storage } from '../../utilities';
import { isOrg, uid, usersSettingsRef } from '@store';
import { TrashedSpacesDialog } from './TrashedSpacesDialog';
import { useTypedSelectorDeepEquals } from '../../hooks.js';
import { createGroup, createSnippet } from '../../data.jsx';
import { pollJob } from './job_polling_util.js';
import { orgId } from '../../flags.js';
import { getState } from '../../getState.js';
import { makeRef } from '../../firebase_utilities.js';
import { generateAppRoute } from '../../hooks/useAppNavigate.js';
import { toast } from '../../messageToast.jsx';

export const DEFAULT_TABLE_NAME = 'New table';

let createDatabaseDialog;
let airtableImportDialog;
let templatesDialog;
let snapshotsDialog;

export function showAirtableImportDialog(config) {
  airtableImportDialog.show(config);
}

export function showTemplatesDialog(config) {
  templatesDialog.show(config);
}

export const databaseDialogs = () => <>
  <CreateDatabaseDialog
    ref={(comp) => {
      createDatabaseDialog = comp;
    }}
    itemType="space"
    renderMoreActions={(onClose, config) => <>
      <T variant="subtitle1" style={{ fontWeight: 'bold' }}>You can also...</T>
      <ul className="more-actions">
        <li>
          <Button
            component={Link}
            onClick={() => {
              onClose();
              showTemplatesDialog(config);
            }}
          >Create from a template</Button>
        </li>
        <li>
          <Button
            component={Link}
            onClick={() => {
              onClose();
              showAirtableImportDialog(config);
            }}
          >Import from Airtable</Button>
        </li>
      </ul>
    </>}
    createFn={async(config, name, icon, onClose, done) => {
      const { createDefaultTable, maxOrder, onError, onCreated } = config;
      let databaseId = null, tableId = null;
      try {
        let newDatabase = await doSafeTableRequest('applications/application/',
          'POST',
          {
            name,
            icon,
            type: 'database'
          }, { toastMessage: 'An error occurred creating the space.' });
        databaseId = newDatabase.id;
      } catch {
        if (onError) {
          onError();
        }
        done();
        return;
      }

      if (createDefaultTable) {
        try {
          let res = await doSafeTableRequest(`database/tables/database/${databaseId}/`,
            'POST',
            {
              name,
            }, { toastMessage: 'An error occurred creating the space.' });
          tableId = res.id;
        } catch {
          if (onError) {
            onError();
          }
        }
      }

      await storage.update(usersSettingsRef, {
        [`options.databases.${databaseId}.favorite`]: true,
        [`options.databases.${databaseId}.order`]: (maxOrder || 0) + 1
      });

      onClose();
      if (onCreated) {
        onCreated({ databaseId, tableId });
      }

      done();
    }}
  />
  <DatabaseAirtableImportDialog
    ref={(comp) => {
      airtableImportDialog = comp;
    }}
  />
  <DatabaseTemplatesDialog
    ref={(comp) => {
      templatesDialog = comp;
    }}
  />
  <DatabaseSnapshotsDialog
    ref={(comp) => {
      snapshotsDialog = comp;
    }}
  />
</>;

export function showSnapshotsDialog(config) {
  snapshotsDialog.show(config);
}

/**
 * @param {{ createDefaultTable: boolean, openImportedButtonLabel?: string }} [config]
 * @returns {Promise<any>}
 */
export function createNewDatabase(config = {
  createDefaultTable: true
}) {
  return new Promise((resolve, reject) => {
    createDatabaseDialog.show({
      ...config,
      onCreated: (ids) => {
        resolve(ids);
      },
      onError: (ids) => {
        reject(ids);
      },
    });
  });
}

/**
 *
 * @param {Partial<SnippetObjectType>} snippet
 * @returns
 */
async function extractSnippetUsages(snippet) {
  const environmentConfig = makeConfig({
    config: {
      stage: 'preview',
      doNotPullInAddons: true,
      addons: sync.activeAddons(),
      snippet: {},
      user: {},
      findSnippet: (name) => {
        let snippets = sync.getSnippetsByShortcut(name.toLocaleLowerCase());
        if (snippets && snippets.length) {
          return {
            delta: snippets[0].data.content.delta.toUint8Array()
          };
        }
      }
    }
  });
  const env = new Environment({}, environmentConfig);
  const dom = await createDom(snippet.content.delta.toUint8Array ?
    decompressDelta(snippet.content.delta.toUint8Array()) :
    decompressDelta(snippet.content.delta), env);
  return featureUsage(dom, env);
}

async function extractDatabaseIds(snippet) {
  return Object.keys(await extractDatabaseUsages(snippet));
}

/**
 * @param {Partial<SnippetObjectType>} snippet
 * @returns {Promise<import('../Version/limitations').FeaturesUsageType['DATABASES']>}
 */
export async function extractDatabaseUsages(snippet) {
  const usage = await extractSnippetUsages(snippet);
  if (usage.DATABASES) {
    return usage.DATABASES;
  }

  return {};
}

export async function updateGroupDatabaseUsages(snippet) {
  const databaseIds = await extractDatabaseIds(snippet);
  for (const databaseId of databaseIds) {
    await updateFullGroupPermissions(snippet.group_id, databaseId);
  }
}

/**
 *
 * @param {string} date
 * @returns {boolean}
 */
export function hasNeverBeenOpened(date) {
  // Never opened, since the date is  "0001-01-01T00:00:00Z".
  return !date || date === '0001-01-01T00:00:00Z';
}

/**
 * Create a spaces configured instance of the trashed dialog.
 * @return {JSX.Element}
 */
export const spacesTrashDialog = () => <TrashedSpacesDialog
  groupType="application"
  trashEndpoint="trash/"
  labels={{
    groupType: 'space',
    groupTypeCapitalized: 'Space',
    groupTypePlural: 'spaces',
  }}
  urlHash="#trash"
/>;


/**
 *
 * @param {DeltaType} delta
 * @param {string} findId
 * @param {string} replaceId
 * @return {DeltaType}
 */
export function replaceSnippetSpaceIdInDelta(delta, findId, replaceId) {
  const ops = delta.ops;
  const inserts = ops.map(op => typeof op.insert === 'string' ? op.insert : '|');
  const snippetText = inserts.join('');
  // keep the original positions for each operation before modifying them
  const positions = new Array(inserts.length);
  positions[0] = 0;
  for (let i = 1; i < inserts.length; i++) {
    positions[i] = positions[i - 1] + inserts[i - 1].length;
  }

  // the regex to find the space=ID part in the snippet text
  const replacementLength = replaceId.length - findId.length;
  const regex = new RegExp(`space=\\s*${findId}\\s*[;}]`, 'g');
  let match;
  let currentIndex = 0;
  let matchesInTheCurrentOp = 0;
  // find space=ID parts
  while ((match = regex.exec(snippetText)) !== null) {
    matchesInTheCurrentOp++;
    const targetPosition = match.index + match[0].indexOf(findId);
    // move the current index to the operation where the =ID part begins
    while (
      currentIndex < ops.length - 1 &&
      positions[currentIndex + 1] < targetPosition
    ) {
      currentIndex++;
      matchesInTheCurrentOp = 1;
    }

    const currentPosition = positions[currentIndex];
    // shift the target position by replacements applied in the current operation
    const actualPosition = targetPosition + (matchesInTheCurrentOp - 1) * replacementLength;
    // calculate the number of chars to delete from the next operations
    let charsToDelete = findId.length - (ops[currentIndex].insert.length - (actualPosition - currentPosition));
    // insert the replaced id into this operation
    ops[currentIndex].insert = ops[currentIndex].insert.slice(0, actualPosition - currentPosition) +
      replaceId + ops[currentIndex].insert.slice(actualPosition - currentPosition + findId.length);

    // delete the remaining chars from the next operations
    while (charsToDelete > 0) {
      currentIndex++;
      const charsToDeleteIteration = Math.min(ops[currentIndex].insert.length, charsToDelete);
      ops[currentIndex].insert = ops[currentIndex].insert.slice(charsToDeleteIteration);

      charsToDelete -= charsToDeleteIteration;
    }
  }

  // remove empty operations
  delta.ops = delta.ops.filter(op => typeof op.insert !== 'string' || !!op.insert.length);
  return delta;
}


/**
 *
 * @param {string} dbId
 */
export function useLinkedGroups(dbId) {
  return useTypedSelectorDeepEquals((store) => {
    const userGroups = store.userState.groups;
    const groups = store.dataState.groups;
    const linkedGroups = [];
    const filteredGroups = {};
    for (const [id, group] of Object.entries(groups)) {
      filteredGroups[id] = {
        id: group.id,
        name: group.name,
        icon: group.options?.icon,
      };
    }

    if (userGroups) {
      const enabledGroups = Object.keys(userGroups).map(id => ({
        id,
        userInfo: userGroups[id]
      })).filter(x => !x.userInfo.disabled && groups[x.id]);

      for (const g of enabledGroups) {
        if (groups[g.id] && groups[g.id].connected && groups[g.id].connected.database_queries) {
          for (const spaceId of Object.keys(groups[g.id].connected.database_queries)) {
            if (spaceId === dbId) {
              linkedGroups.push({
                ...filteredGroups[g.id],
                snippets: groups[g.id].snippets,
              });
            }
          }
        }
      }
    }

    return linkedGroups;
  });
}


/**
 *
 * @param {import('./DatabasePublicSpaceEmbed').BundleInfo=} bundle
 * @param {string=} spaceId
 * @param {string=} templateId
 *  */
export async function saveBundleOrSpace(bundle, spaceId, templateId) {
  async function copyBundle() {
    // STEP: create a new folder
    const newGroupId = await createGroup({
      name: bundle.name,
    }, true);

    // STEP: replace database ids in snippets
    const newSnippets = bundle.snippets.map(snippet => ({
      delta: snippet.delta || snippet.content,
      name: snippet.name,
      shortcut: snippet.shortcut,
      ...(snippet.quickentry ? { options: { quick_entry: snippet.quickentry === 'yes' } } : {})
    }));

    let newApplicationId;
    if (spaceId) {
      // STEP: copy the space
      const response = await doSafeTableRequest(`applications/${spaceId}/duplicate/async/`, 'POST', {});
      if (response.error) {
        throw new Error(response.detail);
      }

      const jobResponse = await pollJob(response.id);
      newApplicationId = jobResponse.duplicated_application.id;

      // STEP: replace database ids in snippets
      for (const snippet of newSnippets) {
        snippet.delta = replaceSnippetSpaceIdInDelta(snippet.delta, spaceId, newApplicationId);
      }
    }

    // STEP: copy updated snippets to the new folder
    for (const snippet of newSnippets) {
      await createSnippet(Object.assign({}, snippet, {
        delta: compressDelta(snippet.delta),
        group_id: newGroupId
      }),true);
    }

    if (spaceId) {
      // STEP: update folder connected settings
      const queries = new Set();
      for (const snippet of bundle.snippets) {
        if (snippet.connected && snippet.connected['databaseQueryWhitelist'] && snippet.connected['databaseQueryWhitelist'][spaceId]) {
          const databaseQueryWhitelist = snippet.connected['databaseQueryWhitelist'][spaceId];
          databaseQueryWhitelist.forEach(query => queries.add(query));
        }
      }
      const connected = {
        is_connected: true,
        connector: isOrg() ? ('o:' + orgId(getState())) : ('u:' + uid()),
        database_queries: {
          [newApplicationId]: Array.from(queries),
        },
      };
      await storage.update(makeRef('groups', newGroupId), {
        connected
      });
    }

    return {
      groupId: newGroupId,
      applicationId: newApplicationId,
    };
  }
  let newApplicationId;
  let folderUrl;

  try {
    if (bundle && bundle.snippets) {
      try {
        const { groupId, applicationId } = await copyBundle();
        newApplicationId = applicationId;
        folderUrl = generateAppRoute(`/folder/${groupId}`, 'TEXT').url;
      } catch (error) {
        if (error !== 'snippet limitations') {
          toast('Error copying the bundle.', {
            duration: 6000,
            intent: 'danger'
          });
        }
        return;
      }
    } else if (templateId) {
      const response = await doSafeTableRequest(`templates/install/${templateId}/`, 'POST', {});
      if (response.error) {
        toast(response.detail, {
          duration: 6000,
          intent: 'danger'
        });
        return;
      }

      newApplicationId = response[0].id;
    } else if (spaceId) {
      const response = await doSafeTableRequest(`applications/${spaceId}/duplicate/async/`, 'POST', {});
      if (response.error) {
        toast(response.detail, {
          duration: 6000,
          intent: 'danger'
        });
        return;
      }

      const jobResponse = await pollJob(response.id);
      newApplicationId = jobResponse.duplicated_application.id;
    }
  } catch (error) {
    toast(error.message, {
      duration: 6000,
      intent: 'danger'
    });
  }

  return { newApplicationId, folderUrl };
}