import React from 'react';
import PhotoIcon from '@mui/icons-material/PhotoOutlined';
import Alert from '@mui/material/Alert';
import T from '@mui/material/Typography';
import { ICON_MAPPING } from '../SnippetEditor/EmbeddedCommand/embedded_utilities';
import { snippetOrPrompt } from '../../aiBlaze';


/**
 * @param {string} name
 * 
 * @return {import('./DataDialog').DataDialogConfigDef}
 */
export const importConfig = (name) => ({
  title: `Import ${name} snippet`,
  info: 'There may be errors or incompatibilities with the import process. Make sure to check the new snippet after import.',
  applyButton: 'Import',
  fields: [
    {
      name: 'Snippet name',
      placeholder: 'New Snippet',
      id: 'name',
      type: 'text'
    },
    {
      name: 'Snippet shortcut',
      placeholder: '/do',
      sanitize: true,
      id: 'shortcut',
      type: 'text'
    },
    {
      name: `Paste your ${name} snippet contents`,
      placeholder: `${name} Snippet...`,
      id: 'contents',
      type: 'html'
    }]
});


/** @type {import('./DataDialog').DataDialogConfigDef} */
export const imageConfig = {
  title: 'Edit image',
  applyButton: 'Apply',
  icon: <PhotoIcon />,
  onUpdated: /** @type {any} */ (function (field, value) {
    let ratio = this.state.config.defaults.aspectRatio;
    if (field === 'preserveAspect') {
      if (value) {
        this.setValue('height', Math.round(this.getField('width').value * ratio), true);
      }
    } else {
      if (this.getField('preserveAspect').value || this.getField('preserveAspect').value === undefined) {
        if (field === 'width') {
          this.setValue('height', Math.round(value * ratio), true);
        } else {
          this.setValue('width', Math.round(value / ratio), true);
        }
      }
    }
  }),
  fields: [{
    id: 'width',
    name: 'Image width (pixels)',
    type: 'number',
    min: 1
  }, {
    id: 'height',
    name: 'Image height (pixels)',
    type: 'number',
    min: 1
  }, {
    id: 'preserveAspect',
    name: 'Preserve aspect ratio',
    type: 'boolean',
    clearUntouched: true
  }]
};


/** @type {import('./DataDialog').DataDialogConfigDef} */
export const changeEmailConfig = {
  title: 'Change account email address',
  applyButton: 'Send verification email',
  footer: <>
    <T style={{
      marginTop: 18,
      marginBottom: 18
    }}>
      You will receive a verification email to your new address. When you click the verification link in that email, the account address will be changed. Text Blaze will log you out when you submit the change request.
    </T>

    <Alert severity="warning">
      When you change your email, you will lose access to any folders that have been shared with your old email address. They will need to be re-shared with the new email address.
    </Alert>
  </>,
  fields: [{
    name: 'New email',
    id: 'email',
    type: 'text',
    placeholder: 'john@smith.com'
  }, {
    name: 'Confirm new email',
    id: 'email2',
    type: 'text',
    placeholder: 'john@smith.com'
  }, {
    name: 'Existing password',
    id: 'password',
    type: 'password'
  }]
};


/** @type {import('./DataDialog').DataDialogConfigDef} */
export const changeBusinessOrganizationEmailConfig = {
  title: 'Change organization email address',
  applyButton: 'Change email',
  fields: [{
    name: 'New Email',
    id: 'email',
    type: 'text',
    placeholder: 'invoices@acme.com'
  }, {
    name: 'Confirm New Email',
    id: 'email2',
    type: 'text',
    placeholder: 'invoices@acme.com'
  }]
};

/**
 * @type {Object.<string, {title: string, info: React.ReactElement, items: string[], defaults?: Object, fields?: any[], commandName?: string, icon?: object}>}
 */
export const dialogConfigs = {
  'KEY': {
    title: 'custom key',
    info: <span>Triggers a custom keypress that can be use to execute events or commands in the page. Web Browser level commands like Ctrl-C for Copy cannot be triggered. Learn more in the <a href="https://blaze.today/guides/autopilot/" target="_blank" rel="noopener noreferrer">Autopilot Guide</a>.</span>,
    items: ['POSITIONAL'],
    icon: ICON_MAPPING.key
  },
  'WAIT': {
    title: 'wait',
    info: <span>Delays autopilot actions a set time period. Learn more in the <a href="https://blaze.today/commands/wait/" target="_blank" rel="noopener noreferrer">Wait Command Guide</a>.</span>,
    items: ['delay'],
    defaults: {
      delay: '+1s'
    },
    icon: ICON_MAPPING.wait
  },
  'FORMTEXT': {
    title: 'text field',
    info: <span>Allows you to enter a single line of arbitrary text. Learn more in the <a href="https://blaze.today/guides/forms/" target="_blank" rel="noopener noreferrer">Forms Guide</a>.</span>,
    items: ['name', 'default', 'cols'],
    icon: ICON_MAPPING.formsingle_line
  },
  'FORMPARAGRAPH': {
    title: 'paragraph field',
    info: <span>Allows you to enter multiple lines of arbitrary text. Learn more in the <a href="https://blaze.today/guides/forms/" target="_blank" rel="noopener noreferrer">Forms Guide</a>.</span>,
    items: ['name', 'default', 'cols', 'rows'],
    icon: ICON_MAPPING.formmulti_line
  },
  'FORMMENU': {
    title: 'dropdown menu field',
    info: <span>Creates a menu of choices the user can select. Learn more in the <a href="https://blaze.today/guides/forms/" target="_blank" rel="noopener noreferrer">Forms Guide</a>.</span>,
    items: ['values', 'name', 'multiple', 'cols'],
    defaults: {
      values: [{ value: 'Choice A' }, { value: 'Choice B', selected: true }, { value: 'Choice C' }]
    },
    icon: ICON_MAPPING.formmenu
  },
  'FORMTOGGLE': {
    title: 'toggle field',
    info: <span>Creates a form toggle with an optional block of text that may be  enabled or disabled. Learn more in the <a href="https://blaze.today/guides/forms/" target="_blank" rel="noopener noreferrer">Forms Guide</a>.</span>,
    items: ['name', 'default'],
    fields: [{
      id: 'contents',
      name: 'contents (optional)',
      placeholder: 'Contents of the block',
      type: 'textarea',
      helper: 'If omitted, the toggle field will display "yes" or "no" depending on what is selected (these values can be overridden).'
    }],
    defaults: {
      default: true
    },
    icon: ICON_MAPPING.formtoggle_start
  },
  'FORMDATE': {
    title: 'date/time field',
    info: <span>Allows you to select a date. Learn more in the <a href="https://blaze.today/guides/forms/" target="_blank" rel="noopener noreferrer">Forms Guide</a>.</span>,
    items: ['POSITIONAL', 'name', 'start', 'end'],
    defaults: {
      '%positional': 'YYYY-MM-DD'
    },
    icon: ICON_MAPPING.formdate
  },
  '=': {
    title: 'dynamic formula',
    info: <span>You can enter formulas like '2+2/10'. You can include the name of fields in your formulas (e.g. if you had a field called 'widgets', you could use 'widgets * 10'). Learn more in the <a href="https://blaze.today/guides/formulas/" target="_blank" rel="noopener noreferrer">Formula Command Guide</a>.</span>,
    items: ['POSITIONAL', 'format'],
    icon: ICON_MAPPING.calc_icon
  },
  'IF': {
    title: 'dynamic if/else',
    info: <span>Conditionally includes one of two blocks based on a formula value. Learn more in the <a href="https://blaze.today/guides/rules-validation/" target="_blank" rel="noopener noreferrer">If Command Guide</a>.</span>,
    items: ['POSITIONAL'],
    fields: [{
      id: 'contents',
      name: 'if formula is "Yes"',
      helper: 'This is shown when the formula evaluates to "yes"',
      type: 'textarea'
    }, {
      id: 'elsecontents',
      name: 'if formula is "No" (optional)',
      helper: 'This is shown when the formula evaluates to "no"',
      type: 'textarea'
    }],
    icon: ICON_MAPPING.if_icon
  },
  'REPEAT': {
    title: 'dynamic repeat',
    info: <span>Repeats a part of the snippet multiple times. Learn more in the <a href="https://blaze.today/commands/repeat/" target="_blank" rel="noopener noreferrer">Repeat Command Guide</a>.</span>,
    items: ['POSITIONAL'],
    fields: [{
      id: 'contents',
      name: 'contents',
      helper: 'The contents to be repeated',
      type: 'textarea'
    }],
    icon: ICON_MAPPING.repeat_icon
  },
  'DBSELECT': {
    title: 'Read from table',
    info: <span>Selects data from Data Blaze.</span>,
    items: ['space', 'POSITIONAL', 'multiple', 'menu', 'name'],
    defaults: {
      menu: true
    },
    commandName: 'dbselect',
    icon: ICON_MAPPING.remotedbselect
  },
  'DBINSERT': {
    title: 'Insert row into table',
    info: <span>Inserts data into Data Blaze.</span>,
    items: ['space', 'POSITIONAL', 'autoaddfields'],
    commandName: 'dbinsert',
    defaults: {
      autoaddfields: true
    },
    icon: ICON_MAPPING.remotedbinsert
  },
  'DBUPDATE': {
    title: 'Update table row',
    info: <span>Updates data in Data Blaze.</span>,
    items: ['space', 'POSITIONAL', 'autoaddfields'],
    commandName: 'dbupdate',
    defaults: {
      autoaddfields: true
    },
    icon: ICON_MAPPING.remotedbupdate
  },
  'DBDELETE': {
    title: 'Delete table row',
    info: <span>Deletes data in Data Blaze.</span>,
    items: ['space', 'POSITIONAL'],
    commandName: 'dbdelete',
    icon: ICON_MAPPING.remotedbdelete
  },
  'NOTE': {
    title: 'note',
    info: <span>Notes will not be inserted as part of the snippet. You can use them to include directions or reminders in your snippets that will not be inserted.</span>,
    items: [],
    fields: [{
      id: 'contents',
      name: 'note contents',
      placeholder: 'Contents of the note...',
      type: 'textarea',
      required: true
    }],
    icon: ICON_MAPPING.notenote_start
  },
  'ERROR': {
    title: 'error',
    info: <span>The error command allows you to display an error in a snippet. It can also prevent insertion of form snippets until the error is fixed.</span>,
    items: ['POSITIONAL'],
    fields: [
      {
        id: 'block',
        name: 'Block form submission',
        type: 'boolean',
        required: false
      }
    ],
    icon: ICON_MAPPING.alert
  },
  'IMPORT': {
    title: 'imported ' + snippetOrPrompt,
    info: <span>Imports another {snippetOrPrompt} within this one. This allows you to reuse a {snippetOrPrompt}'s content within multiple {snippetOrPrompt}s</span>,
    items: ['POSITIONAL'],
    icon: ICON_MAPPING.import
  },
  'URLLOAD': {
    title: 'load from url',
    info: <span>Loads data from a URL into the snippet. For example: to fetch data from an API. Learn more in <a href="https://blaze.today/connected/urlload/" target="_blank" rel="noreferrer">the documentation</a></span>,
    items: ['POSITIONAL'],
    icon: ICON_MAPPING.remoteurl_load,
    commandName: 'urlload',
  },
  'URLSEND': {
    title: 'send to url',
    info: <span>Sends data to a URL after the snippet is inserted. Learn more in <a href="https://blaze.today/connected/urlsend/" target="_blank" rel="noreferrer">the documentation</a></span>,
    items: ['POSITIONAL', 'body'],
    commandName: 'urlsend',
    icon: ICON_MAPPING.remoteurl_ping,
  },
  'IMAGE': {
    title: 'dynamic image',
    info: <span>Inserts an image into the snippet. The image's URL can contain dynamic form values.</span>,
    items: ['POSITIONAL'],
    commandName: 'image',
    icon: ICON_MAPPING.image,
  },
  'RUN': {
    title: 'run code block',
    info: <span>Triggers a code block exactly once, when this command is displayed.</span>,
    items: ['POSITIONAL'],
    icon: ICON_MAPPING.run,
  },
  'BUTTON': {
    title: 'button',
    info: <span>Triggers a code block each time the button is pressed.</span>,
    items: ['POSITIONAL', 'label'],
    icon: ICON_MAPPING.button,
  },
};
