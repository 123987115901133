import objEquals from 'fast-deep-equal/es6';

/**
 * This module defines all simple logic without any processor imports
 * that is used by extension and desktop in common
 */

/**
 * 
 * @param {string} res 
 * @param {string} urlPart 
 * @returns {'string'|{ error: 'Could not parse URL'}}
 */
export function parseURL(res, urlPart) {
  try {
    let url = new URL(res);
    return {
      url: res,
      // Remove trailing ':'
      protocol: url.protocol.slice(0, url.protocol.length - 1),
      domain: url.hostname,
      port: url.port,
      path: url.pathname,
      query: url.search,
      hash: url.hash
    }[urlPart];
  } catch (_) {
    return { error: 'Could not parse URL' };
  }
}

const JOIN_SYMBOL = '<TB>';
/** @param {import('./DownstreamProcess').SiteSelectorItem} item */
export function getSiteItemGroupingKey(item) {
  return item.page + JOIN_SYMBOL + item.group;
}

/**
 * @param {import('./DownstreamProcess').ConfigDefType['usedSiteTabSelections']} selections 
 * @param {Parameters<import('./DownstreamProcess').NativeGetSiteDataFn>[0]} item 
 */
export function selectorFn(selections, item) {
  // The || X is for backwards compatibility with extension versions 2.13.x and older
  const allData = selections?.[getSiteItemGroupingKey(item)]?.res || selections?.[item.page]?.res;

  if (allData) {
    for (const { item: itemUsed, res } of allData) {
      if (objEquals(itemUsed, item)) {
        if (typeof res === 'string' && item.part === 'url') {
          return parseURL(res, item.urlPart);
        } else {
          return res;
        }
      }
    }
  }

  return undefined;
}

/**
 * @typedef {{ store: Object<string, Object<string, any>>, formConfig: NextFormDataType['configDef'] }} NativeRemoteBlockDataType
 * @typedef {{ type: 'string', htmlStrArr?: (string|{ tag: string, type: string })[], textStrArr: (string|{ tag: string, type: string })[], snippetCursorMoveCount?: number, isEmpty?: boolean }} BasicReplacement
 * @typedef {(BasicReplacement | import('./ParseNode').default)} ReplacementPart
 * @typedef {function(import('./ParseNode').default['info']) : Promise<object>} NativeRemoteCommandFn
 * @typedef {function() : { html: string, text: string } | Promise<{ html: string, text: string }>} NativeGetClipboardFn
 * @typedef {FeatureUsageReturnType['SITE_SELECTORS'][0]} SiteSelectorItem
 * @typedef {(string | string[] | { error: string; })[] & { type?: 'self' | 'base'; }} SiteSelectionResult
 * @typedef {(item: SiteSelectorItem) => (string | string[] | { error: string; })} NativeGetSiteDataFn
 * @typedef {(sidechannel: import('./ParseNode').default[], blockData: NativeRemoteBlockDataType) => void} NativeSideChannelFn
 * @typedef {function(string): (Promise<{ delta: Uint8Array }>|{ delta: Uint8Array })} NativeFindSnippetFn
 * @typedef {{ tabId: number, data: { res: (string | string[] | { error: string; }), index: number, item: object}[], title: string, favicon: string }[]} AllTabsDataType
 * @typedef {(items: SiteSelectorItem[], tabId: number, frameId: number) => Promise<{ selectorData: AllTabsDataType, needsTabSelectInSiteCommand: boolean, usedSiteTabSelections: ConfigDefType['usedSiteTabSelections'] }>} NativeGetAllTabsDataFn
 **/

/**
 * @typedef {'shortcut'|'omnibox'|'widget'|'context_menu'|'assistant'|'sidebar'} ExtensionInsertionType   
 * 
 * @typedef {Omit<ActiveAddonType, 'addon'> & { addon: { deltaArray: number[], options?: SnippetObjectType['options'], addonOptions?: AddonOptionsType }}} CleanedAddonType the addon sent from background page after cleaning
 * 
 * @typedef {Omit<ActiveAddonType, 'addon'> & { addon: { data : { content: { delta: { toUint8Array: () => Uint8Array }}, options?: SnippetObjectType['options'] }, addonOptions?: AddonOptionsType }}} ConfigDefFormAddon the addon reconstructed in form page after receiving the message
 * 
 * @typedef {Awaited<ReturnType<typeof import('../components/Version/limitations').proUsage>>} ProUsageReturnType
 * @typedef {Awaited<ReturnType<typeof import('../components/Version/limitations').featureUsage>>} FeatureUsageReturnType
 */

/** @typedef {{ skipElementChecks?: boolean, onlyClipboardCopy?: boolean, customUIMessage?: string, }} FormRestoreConfig */
/** @typedef {{ domain: string, windowId: number, tabId: number, frameId: number, quickentry: boolean, date: Date, locale: string, user: ReturnType<typeof import('../../js/engine_utilities').userMemberData>, snippet: { id: string, shortcut: string, trigger: string, folderid: string }, randomSeed: number, addons: object, findSnippet: NativeFindSnippetFn, commandWhitelist: object, isOrg: boolean, connectedSettings: ReturnType<typeof import('../flags').getConnectedConfigOptions>, insertionType: ExtensionInsertionType, typedShortcutText: string, usedCommandsWhitelist: FeatureUsageReturnType['COMMANDS'], usedLambdaWhitelist: FeatureUsageReturnType['LAMBDAS'], usedSiteSelectors: SiteSelectorItem[], usedSiteSelectorData: AllTabsDataType, editorData?: { isDocs?: boolean, isLexical?: boolean, isDraftJS?: boolean }, usedSiteTabSelections: Record<string, { tabId: number, res: AllTabsDataType[0]['data'] }>, needsTabSelectInSiteCommand: boolean, showNotification?: (notificationData: { message: string, title: string }) => void, onFormDirty?: (dirtyState: boolean) => any, appType?: 'TEXT'|'AI' }} ConfigDefType */
/** @typedef {{ formId: string, windowId: number, tabId: number, frameId: number, snippetId: string, groupId: string, delta: DeltaType, addons: Record<string, CleanedAddonType>, name: string, snippetType: 'text'|'html', shortcut: string, isPro: boolean, isOrg: boolean, maxFreeProSnippets: number, countUsage: boolean, message: string, appendage: string, featureUsage: ProUsageReturnType, typedShortcutText: string, insertionType: ExtensionInsertionType, isOmnibox: boolean, userId: string, configDef: Omit<ConfigDefType, 'addons'> & { addons: Record<string, ConfigDefFormAddon> }, locale: string, formWindowId?: number, isUsingProFeatures: boolean, aiData?: { aiAction?: 'write'|'polish'|'chat', promptText?: string, promptDelta?: DeltaType, rawDelta?: number[], hostnameAccepted?: boolean, precedingText?: string, rawPromptText?: string, }, targetId?: string, renderControls?: Function | "form" | "chat-preview", formSubmitRef?: React.MutableRefObject<any>, usageProSnippets: number, formRestoreConfig?: FormRestoreConfig, fbToken?: string }} NextFormDataType */

/** @typedef {(store: Object<string, import('./DataContainer').DataContainer>, config?: FormRestoreConfig) => void} OnFormAcceptFn */
/** @typedef {() => void} OnFormRejectFn */

/** 
 * Clean addon circularity to support serialization
 * 
 * @param {Object<string, ActiveAddonType>} addons
 */
export function getCleanedAddons(addons) {
  /** @type {Record<string, CleanedAddonType>} */
  const cleanedAddons = {};

  for (const addonName in addons) {
    const addon = addons[addonName];

    const cleanedAddon = {
      deltaArray: Array.from(addon.addon.data.delta),
      // keeping this for backwards compatibility with older
      // frontend/micro popups. they should not crash
      // TODO: can remove this four months from today
      delta: addon.addon.data.delta,
      options: addon.addon.data.options,
      addonOptions: addon.addon.addonOptions,
    };

    cleanedAddons[addonName] = Object.assign({}, addon, { addon: cleanedAddon });
  }
  return cleanedAddons;
}

/**
 * @param {ReturnType<typeof getCleanedAddons>} cleanedAddons 
 */
export function restoreAddonFromCleaned(cleanedAddons) {
  /** @type {Record<string, ConfigDefFormAddon>} */
  const restoredAddons = {};
  // Note this should probably be optimized
  // We want to only construct the delta on use
  for (const addonId in cleanedAddons) {
    const cleanAddon = cleanedAddons[addonId];
    // @ts-ignore For backwards compatibility of new frontend with older extension/app
    const deltaArray = cleanAddon.addon.deltaArray ? cleanAddon.addon.deltaArray : Object.values(cleanAddon.addon.delta);
    restoredAddons[addonId] = Object.assign({}, cleanAddon, {
      addon: {
        data: {
          // Need to reconvert it to Uint8Array after message passing:
          content: {
            delta: { toUint8Array: () => new Uint8Array(deltaArray) }
          },
          options: cleanAddon.addon.options
        },
        addonOptions: cleanAddon.addon.addonOptions,
      }
    });
  }
  return restoredAddons;
}

const DEFAULT_OUTPUT_TAG = 'P';

/**
 * @param {Element} oldElm
 * @param {string} targetTag 
 */
function changeOutputTag(oldElm, targetTag) {
  if (targetTag === DEFAULT_OUTPUT_TAG || !(oldElm instanceof HTMLElement) || oldElm.tagName.toLowerCase() === 'table') {
    return;
  }
  
  const newElm = document.createElement(targetTag);
  // copy all attributes
  [...oldElm.attributes].forEach(attr => {
    newElm.setAttribute(attr.name, attr.value);
  });
  newElm.append(...[...oldElm.childNodes]);
  oldElm.replaceWith(newElm);
}

/*
 * The header tags are derived from the header conversion logic
 * Please cross-ref the code in contentScript:getHeaderTag 
 */
const HEADER_CONVERSION_TAGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
/**
 * Styleable tags are those onto which we can attach the
 * computed style attributes.
 * p, ul, ol, blockquote, li are derived by processing Quill delta
 * The headers are generated in changeOutputTag
 */
export const STYLEABLE_TAGS = ['P', 'UL', 'OL', 'BLOCKQUOTE', 'LI'].concat(HEADER_CONVERSION_TAGS);

/**
 * 
 * @param {string} html 
 */
export function isStyleableTagPresent(html) {
  return html.includes('<p') || html.includes('<ul') || html.includes('<ol') || html.includes('<blockquote');
}

function attachStyles(el, styles) {
  if (el instanceof HTMLElement) {
    const isStyleableElm = STYLEABLE_TAGS.includes(el.tagName);
    if (isStyleableElm) { 
      let cssText = '';
      for (const key in styles) {
        if (!el.style[key]) {
          // When we update using the style property, it will automatically remove the ‘mso-para-margin’ and ‘mso-padding-alt’ attributes.
          cssText += `${key}:${styles[key]};`;
        }
      }
      // It would append ‘null’ if we don’t check whether the element has a style attribute, which is completely unnecessary.
      cssText = cssText + (el.hasAttribute('style') ? el.getAttribute('style') : '');
      el.setAttribute('style', cssText);
      el.setAttribute('data-mce-style', cssText);
      if (['OL', 'UL'].includes(el.tagName)) {
        for (const p of el.children) {
          attachStyles(p, styles);
        }
      }
    }
  }
}

/**
 * 
 * @param {string | undefined} html 
 * @param {object | undefined} styles 
 * @param {string} targetTag
 */
export function applyContextualStyles(html, styles, targetTag) {
  if (html !== undefined) {
    targetTag = targetTag || DEFAULT_OUTPUT_TAG;
    if (styles && Object.keys(styles).length) {
      if (isStyleableTagPresent(html)) {
        const el = document.createElement('div');
        el.innerHTML = html;

        const staticChildList = [...el.children];
        for (const child of staticChildList) {
          changeOutputTag(child, targetTag);
        }
        for (const p of el.children) {
          attachStyles(p, styles);
        }

        html = el.innerHTML;
      } else {
        const el = document.createElement('span');
        el.innerHTML = html;
    
        for (const key in styles) {
          el.style[key] = styles[key];
        }
        html = el.outerHTML;
      }
    }
  }
  return html;
}

/**
 * @param {ReplacementPart[]} replacement 
 */
export function convertReplacementPartsToText(replacement) {
  let textString = '', htmlString = undefined;
  for (const part of replacement) {
    if (part.type === 'string') {
      // Filter out {cursor} commands from the replacement part
      for (const x of part.textStrArr) {
        if (typeof x === 'string') {
          textString += x + ' ';
        }
      }
      textString += '\n';
      if (part.htmlStrArr) {
        if (!htmlString) {
          htmlString = '';
        }
        for (const x of part.htmlStrArr) {
          if (typeof x === 'string') {
            htmlString += x + ' ';
          }
        }
        htmlString += '\n';
      }
    }
  }

  // Remove trailing newlines from the end
  textString = textString.trimEnd();
  if (htmlString) {
    htmlString = htmlString.trimEnd();
  }

  return { textString, htmlString };
}

/**
 * Please keep in sync with contentScript.js
 * @param {string} type 
 * @param {{htmlStr?: string, textStr: string}} replacementData 
 * @returns true iff the given replacement data is empty
 */
export function isEmptyInsert(type, replacementData) {
  if (type === 'html') {
    const element = document.createElement('span');
    element.style.opacity = '0';
    element.innerHTML = replacementData.htmlStr;
    // We check to see if there is an image tag, as that could show up as empty text.
    if (/^\s*$/.test(element.innerText) && !element.querySelector('img') && !element.querySelector('table')) {
      element.remove();
      return true;
    }
    element.remove();
  } else if (type === 'text') {
    if (replacementData.textStr === '') {
      return true;
    }
  }
  return false;
}

/**
 * Collapse consecutive paragraphs into a single paragraph
 * with line breaks in between the contents
 * For example: <p>a</p><p>b</p><p>c</p> is converted to <p>a<br/>b<br/>c</p>
 * 
 * While doing this, we avoid losing paragraph-specific styles like text alignment,
 * so we don't combine those paragraphs
 * @param {string} html 
 * @returns {string}
 */
export function joinConsecutiveParagraphTagsForCKE4(html) {
  /**
   * @param {Element} node 
   */
  function joinParagraphs(node) {
    // First join the paragraphs of children internally
    for (const child of [...node.childNodes]) {
      if (child instanceof Element) {
        // join their paragraphs internally
        joinParagraphs(child);
      }
    }

    /**
     * @param {number} index 
     */
    function isCollapsibleParagraphOrBreak(index) {
      if (index >= node.childNodes.length) {
        return false;
      }
      const child = node.childNodes[index];
      return (child instanceof HTMLParagraphElement && child.style.textAlign === '' && child.style.direction === '') || child instanceof HTMLBRElement;
    }

    for (let i = 0; i < node.childNodes.length - 1; i++) {
      if (isCollapsibleParagraphOrBreak(i) && isCollapsibleParagraphOrBreak(i + 1)) {
        // Both i-th child and i+1-th child are a valid paragraph
        // So insert all children from the next paragraph into the current paragraph

        // First add an inline break separator if we didn't add one already
        node.childNodes[i].appendChild(document.createElement('br'));

        const nextChild = /** @type {Element} */ (node.childNodes[i + 1]);

        // Skip the next paragraph if it was just containing a newline
        if (nextChild.tagName !== 'BR' && (nextChild.children.length !== 1 || nextChild.children[0].tagName !== 'BR')) {
          // Push all children of next paragraph into the i-th paragraph
          (/** @type {Element} */ (node.childNodes[i])).append(...nextChild.childNodes);
        }

        // Remove the next child from the DOM
        nextChild.remove();

        // Decrement the index because we removed the next child
        i--;
      }
    }
  }

  const node = document.createElement('div');
  node.innerHTML = html;
  joinParagraphs(node);
  let resultHTML = node.innerHTML;

  // Re-convert the <br> to be self-closing, like how we output it in the snippet processor
  // Any actual <br> text by user would instead be &lt;br&gt; so we wouldn't accidentally replace it
  resultHTML = resultHTML.replaceAll('<br>', '<br/>');
  return resultHTML;
}