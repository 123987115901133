import { equals, toStr } from './Equation';
import ParseNode from './ParseNode';
import { DataRequiredError, ParseError } from './ParserUtils';

let updatedAtCounter = 0;

/**
 * @typedef {object} Config
 * @property {{snippetType?: 'html'|'text'}=} typeObj - for use in the extension
 * @property {Object<string, DataContainer>=} store
 * @property {Object<string, Object<string, any>>=} storeSnapshots - used in form rendering to get a copy of the store state at the start of rendering in case the state mutates during rendering which can break the caching
 * @property {object=} extraTestCommands - used in testing to add testing commands
 * @property {('preview'|'insertion'|'tokenization')=} stage
 * @property {string=} type -- 'text' or 'html'
 * @property {('attribute'|'addon'|'')=} mode - note you can still be in an addon without this set to 'addon
 * @property {*[]=} quickItems
 * @property {boolean=} quickentry
 * @property {boolean=} noAutoFocus
 * @property {import('./DownstreamProcess').SiteSelectorItem[]} [usedSiteSelectors]
 * @property {import('./DownstreamProcess').ConfigDefType['usedSiteSelectorData']} [usedSiteSelectorData]
 * @property {import('./DownstreamProcess').ConfigDefType['usedSiteTabSelections']} [usedSiteTabSelections]
 * @property {(hint: 'html'|'text') => (import('./ParseNode').default|Promise<import('./ParseNode').default>|string)=} clipboard
 * @property {(arg: { info: import('./ParseNode').InfoType }) => Promise<object>=} remoteFn - for {urlload} and {urlsend}
 * @property {import("../components/FormRenderer/RemoteStore").default=} remoteStore - for {urlload} and {urlsend}
 * @property {import("../components/FormRenderer/SQLStore").default=} sqlStore - for {db*}
 * @property {import('./DownstreamProcess').NativeGetSiteDataFn} [selectorFn] - for {site}
 * @property {(import('./DownstreamProcess').NativeFindSnippetFn|((arg: string)=>void))=} findSnippet
 * @property {string[]=} usedCommandsWhitelist - list of commands used in the snippet. Other commands won't be allowed (e.g. commands converted from a string via toFn). This is to ensure we can statically analyze the snippet behavior
 * @property {string[]=} usedLambdaWhitelist - list of lambdas that can use commands
 * @property {string[]=} commandWhitelist - user/org specified command whitelist
 * @property {function=} commandWhitelistErrorFn
 * @property {((command: ActiveAddonType) => string)=} invalidDomainErrorFn specify custom output function for invalid domain
 * @property {string[]=} connectedAddonWhitelist - applies to connected addons
 * @property {string[]=} loadHostWhitelist - applies to urlload, urlsend and dynamic images
 * @property {string[]=} pingHostWhitelist - applies to urlsend and dynamic images
 * @property {Object<string, string[]>=} databaseQueryWhitelist - applies to {tables} commands
 * @property {string=} connectedAddonWhitelistErrorTemplate
 * @property {string=} loadHostWhitelistErrorTemplate
 * @property {string=} pingHostWhitelistErrorTemplate
 * @property {string=} databaseQueryWhitelistErrorTemplate
 * @property {function=} blockingErrorFn
 * @property {boolean=} unsafe - For the forums
 * @property {'clipboard'[]} [unsafeOverrides]
 * @property {boolean=} noData - whether form data is not allowed (e.g. in the `default` of a `formtext`)
 * @property {boolean=} rootAddonParentIsNoData - for cases when you have something like `{formtext: default={cmd-pck: {=x}}}` -- the parentWrapperEnv for the addon should have noData set even though that addon itself can use it's own data
 * @property {number=} randomSeed
 * @property {Object<string, any>=} user
 * @property {Object<string, any>=} snippet
 * @property {('EXTENSION'|'PAGE'|'DESKTOP')=} application
 * @property {*=} moment
 * @property {Date=} date
 * @property {boolean=} useRealtimeDates - for code block
 * @property {*=} numberFormatter
 * @property {string=} locale
 * @property {object=} state
 * @property {string=} addonNamespace - used during parsing, not evaluation
 * @property {Object<string, ActiveAddonType>=} addons
 * @property {string=} domain
 * @property {boolean=} doNotPullInAddons - don't pull in addons when creating the dom
 * @property {{ commands: Object<string, import('./Commands').CommandDef|ActiveAddonType>, validAnywhere: string[], validInAttributes: string[]}=} commandCache
 * @property {any=} andFormatter
 * @property {any=} orFormatter
 * @property {{location?: string, position?: object}=} focusing - used to maintain focus when editing a form across React `key` prop hierarchy changes
 * @property {Object<string, {id: string, name: string}>=} missingAddons
 * @property {Object<string, {id: string, name: string}>=} installableAddons
 * @property {Object<string, ({status: 'SUCCESS'}|{status:'ERROR', message: string})>=} doCommandsRan
 * @property {boolean=} forceRerenderMode
 * @property {boolean=} isOneoffFormula
 * @property {Set<string>=} currentFixedAssignedGlobals
 * @property {import('./DownstreamProcess').ConfigDefType['editorData']} [editorData]
 * @property {import('./DownstreamProcess').NativeGetAllTabsDataFn} [getDataFromAllMatchingTabs] 
 * @property {import('./DownstreamProcess').ConfigDefType['needsTabSelectInSiteCommand']} [needsTabSelectInSiteCommand]
 * @property {(isDirty: boolean) => void} [onFormDirty]
 * @property {{ onRemoteUpdate?: Function, onChange?: (allowDelay?: boolean, isUserInput?: boolean) => void}} [callbacks]
 * @property {import('./DownstreamProcess').ConfigDefType['showNotification']} [showNotification]
 * @property {('TEXT'|'AI')=} appType
 */


export const MAX_LOCATION_DEPTH = 200;

let signatureCounter = 0;

export class Environment {
  /**
   * @param {(DataContainer|{})=} data
   * @param {Config=} config
   * @param {string[]=} locations
   */
  constructor(data = Object.create(null), config = {}, locations = []) {
    if (data instanceof DataContainer) {
      this.data = data;
    } else {
      this.data = new DataContainer(data);
    }

    this.locations = locations;

    /** @type {Config} */
    this.config = config;
  }

  /**
   * @param {Config} addedConfig
   * @param {any=} newLocation - optional location to append
   * 
   * @return {Environment}
   */
  derivedConfig(addedConfig, newLocation = null) {
    let newEnv = this.derived();
    newEnv.config = Object.assign({}, this.config, addedConfig);
    if (newLocation) {
      newEnv.locations = newEnv.locations.concat(newLocation);
      this.checkLocationLength(newEnv);
    }
    return newEnv;
  }

  /**
   * @param {object|DataContainer} addedData
   * 
   * @return {Promise<Environment>}
   */
  async derivedData(addedData) {
    let newEnv = this.derived();
    /** @type {DataContainer} */
    let addedContainer;
    if (addedData instanceof DataContainer) {
      addedContainer = addedData;
    } else {
      addedContainer = new DataContainer(addedData);
    }
    
    newEnv.data = addedContainer;
    addedContainer.parent = this.data;
    await addedContainer.ready;

    return newEnv;
  }

  /**
   * @param {string} key
   * 
   * @return {boolean}
   */
  hasCache(key) {
    if (this.config.store && this.config.store._cache) {
      return key in this.config.store._cache;
    }
    return false;
  }

  /**
   * @param {string} key
   * 
   * @return {any}
   */
  getCache(key) {
    if (this.config.store && this.config.store._cache) {
      return this.config.store._cache[key];
    }
  }

  /**
   * @param {string} key
   * @param {any} value
   */
  setCache(key, value) {
    if (!this.config.store) {
      this.config.store = {};
    }
    if (!this.config.store._cache) {
      this.config.store._cache = Object.create(null);
    }
    this.config.store._cache[key] = value;
  }


  /**
   * @return {Environment}
   */
  derived() {
    return new Environment(this.data, this.config, this.locations);
  }

  /**
   * Used to determine who can refresh change logs or execute derived for a store. This is
   * paired with the `owners` property.
   * 
   * @return {string}
   */
  ownerId() {
    for (let i = this.locations.length - 1; i >= 0; i--) {
      if (this.locations[i].startsWith('local_data - ') || this.locations[i].startsWith('s_command - ') || this.locations[i].startsWith('embedded_command - ')) {
        return this.locations.slice(0, i + 1).join(' <> ');
      }
    }

    // This should never happen. If it does the root issue needs to be fixed
    console.error('Invalid path location', this.locations);
    // console.log(new Error().stack);
    return this.locations.join(' <> ');
  }

  /**
  * @param {string|DataUpdateChangeType[]} nameOrArray
  * @param {any=} value - omitted if nameOrArray is an array
  * @param {{callback?: function, onError?: function}=} options
  */
  async updateData(nameOrArray, value, options = { callback: null, onError: null }) {
    try {
      if (!Array.isArray(nameOrArray)) {
        let name = nameOrArray;
        let update = { name, value };
        await this.data.bulkUpdate([update], this);
        if (options.callback) {
          options.callback();
        }
      } else {
        let array = nameOrArray;
        await this.data.bulkUpdate(array, this);
        if (options.callback) {
          options.callback();
        }
      }
    } catch (err) {
      if (options.onError) {
        options.onError(err);
      } else {
        throw err;
      }
    }
  }

  /**
   * @param {string} loc 
   * @returns {Environment}
   */
  derivedLocation(loc) {
    let newEnv = this.derived();
    newEnv.locations = newEnv.locations.concat(loc);
    this.checkLocationLength(newEnv);
    return newEnv;
  }

  /**
   * @returns {string}
   */
  locationString() {
    return this.locations.join(' <l> ');
  }

  checkLocationLength(env) {
    if (env.locations.length > MAX_LOCATION_DEPTH) {
      // This is to catch thing like recursive {import}'s that are within attributes which we
      // can't check with our parser.js logic. Also recursive equation calls which aren't caught
      // up by our dependency graph check.
      //
      // A value of 8 is enough for all our tests to pass so 200 should provide more than enough
      // depth. In fact, it is noticeably slow in a simple test case, so is probably not usable in
      // practice even if intended.
      throw new ParseError('Snippet exceeded depth – do you have recursion in your snippet?');
    }
  }
}

/**
 * @typedef {(env: Environment, changes: string[], recursionStack: DerivedChangeFn[]) => Promise<void>} DerivedChangeFn
 * @typedef {{ name: string, value: any, create_in_root_if_needed?: boolean, is_local?: boolean, final_name?: string }}DataUpdateChangeType
*/

export class DerivedChange {
  /**
   * @param {string[]} dependencies 
   * @param {DerivedChangeFn} fn
   */
  constructor(dependencies, fn) {
    this.dependencies = dependencies;
    this.fn = fn;
  }
}


export class DataContainer {
  /**
   * @param {object} data 
   * @param {DerivedChange[]=} derived 
   * @param {Environment=} env 
   * @param {DataContainer=} parent
   */
  constructor(data, derived = [], env = null, parent = null) {
    /** @type {number} */
    this.updatedAtIndex = updatedAtCounter++;
    /** @type {number} */
    this.cachedDataHashUpdatedAtIndex = null;
    /** @type {string} */
    this.cachedDataHash = null;


    /** @type {DataContainer} */
    this.parent = parent;

    // Ensure initial data load happens
    if (data === null) {
      /** @type {Object<string, any>} */
      this.data = null;
    } else {
      this.data = Object.assign(Object.create(null), data);
    }
    this.changeLog = Object.create(null);

    // Used to update derived equations in children
    // when parent data container updates
    /** @type {DataContainer[]} */
    this.children = [];

    // Optional setting used to keep track of `locations` owners for
    // env.config.store when structuring snippets.
    // We need these owners so we know who can clear change logs
    // for each store.
    /** @type {string} */
    this.owner = null;

    // Location path used for derived calculations
    // IMPORTANT: This property can be reassigned but not mutated as an instance may be shared:
    //   DO: x.locations = x.locations.concat('x')
    //   DON'T: x.locations.push('x')
    /** @type {string[]} */
    this.locations = null;


    // Variables defined with var
    /** @type {Set<string>} */
    this.locals = new Set();

    this.derived = derived;
    if (!derived || !derived.length) {
      this.ready = Promise.resolve();
    } else {
      if (derived.length) {
        this.ready = this.updateDerived(null, env);
      }
    }
  }

  resetChangeLog() {
    this.changeLog = Object.create(null);
    if (this.parent) {
      this.parent.resetChangeLog();
    }
  }


  /**
   * @return {string}
   */
  getDataHash() {
    let updateIndex = this.collapsedUpdateIndex();
    if (this.cachedDataHash && updateIndex === this.cachedDataHashUpdatedAtIndex) {
      return this.cachedDataHash;
    }

    let data = this.collapseData();

    let keys = Object.keys(data || {});
    keys.sort();
    
    let hash = 'data:' + keys.map(x => {
      let d = data[x];
      let dataStr;
      if (d === null || d === undefined) {
        dataStr = 'null';
      } else if (d instanceof ParseNode) {
        if (d.tag === 'text') {
          d = d.info.message;
        } else if (d.tag === 'html') {
          d = d.info.message;
        } else {
          throw new ParseError('Invalid data hash node type: ' + d.type + ' ' + d.tag);
        }
        dataStr = 't:' + d;
      } else if (d instanceof Object && !(d.type === 'lambda' || d.keys)) {
        // e.g. the headers map
        dataStr = 'o:' + JSON.stringify(d);
      } else {
        dataStr = 's:' + toStr(data[x]);
      }

      return x + '=' + dataStr;
    }).join('|');

    this.cachedDataHash = hash;
    this.cachedDataHashUpdatedAtIndex = updateIndex;

    return hash;
  }

  /**
   * @return {object}
   */
  getChangeLog() {
    return Object.assign(Object.create(null), this.changeLog);
  }
  
  /**
   * @param {string} name
   * @param {any} newVal
   */
  updateChangeLogEntry(name, newVal) {
    let existingOrigVal = this.changeLog[name] && this.changeLog[name].origVal;

    if (existingOrigVal === undefined) {
      if (this.data[name] === undefined || !equals(newVal, this.data[name])) {
        this.changeLog[name] = Object.assign({
          origVal: this.data[name]
        }, this.changeLog[name], {
          newVal
        });
      }
    } else {
      if (equals(newVal, existingOrigVal)) {
        delete this.changeLog[name];
      } else {
        this.changeLog[name].newVal = newVal;
      }
    }
  }

  /**
   * Get that value of a given name
   * 
   * @param {string} selector 
   * 
   * @return {object}
   */
  get(selector) {
    if (this.data === null) {
      throw new DataRequiredError();
    }

    let name = selector.toLocaleLowerCase();
    if (this.data[name] !== undefined) {
      return this.data[name];
    } else if (this.parent && this.parent.data) {
      return this.parent.get(name);
    }
  }

  /**
   * @return {Object<string, any>} 
   */
  getData() {
    return this.data;
  }

  /**
   * @return {Object<string, any>} 
   */
  collapseData() {
    if (this.parent) {
      let parentData = this.parent.collapseData();
      return Object.assign(Object.create(null), parentData, this.getData());
    } else {
      return Object.assign(Object.create(null), this.getData());
    }
  }

  /**
   * @return {number} 
   */
  collapsedUpdateIndex() {
    if (this.parent) {
      let parentData = this.parent.collapsedUpdateIndex();
      return Math.max(this.updatedAtIndex, parentData);
    } else {
      return this.updatedAtIndex;
    }
  }

  /**
   * Whether the item exists.
   * 
   * @param {string|object} selector
   * 
   * @return {boolean}
   */
  defined(selector) {
    if (this.data === null) {
      throw new DataRequiredError();
    }

    if (typeof selector === 'string') {
      let name = selector.toLocaleLowerCase();
      if (this.data[name] !== undefined) {
        return true;
      }
      if (this.parent && this.parent.data) {
        return this.parent.defined(name);
      }
    } else {
      if (this.data[selector.base] !== undefined) {
        return true;
      }
      if (this.parent && this.parent.data) {
        return this.parent.defined(selector);
      }
    }

    return false;
  }

  /**
   * Whether the item exists and is local
   * 
   * @param {string|object} selector
   * 
   * @return {boolean}
   */
  definedLocal(selector) {
    if (this.data === null) {
      throw new DataRequiredError();
    }

    if (typeof selector === 'string') {
      let name = selector.toLocaleLowerCase();
      if (this.data[name] !== undefined) {
        if (this.locals.has(name)) {
          return true;
        }
      }
      if (this.parent && this.parent.data) {
        return this.parent.definedLocal(name);
      }
    } else {
      if (this.data[selector.base] !== undefined) {
        if (this.locals.has(selector.base)) {
          return true;
        }
      }
      if (this.parent && this.parent.data) {
        return this.parent.definedLocal(selector);
      }
    }

    return false;
  }

  /**
   * Whether the item exists in this data container.
   * 
   * @param {string|object} selector
   * 
   * @return {boolean}
   */
  has(selector) {
    if (this.data === null) {
      throw new DataRequiredError();
    }

    if (typeof selector === 'string') {
      if (this.data[selector.toLocaleLowerCase()] !== undefined) {
        return true;
      }
    } else {
      if (this.data[selector.base] !== undefined) {
        return true;
      }
    }
    return false;
  }


  
  /**
   * @param {string} name
   * @param {*} newVal
   * @param {Environment} env
   * @param {DerivedChangeFn[]=} recursionStack
   */
  async update(name, newVal, env, recursionStack = []) {
    return this.bulkUpdate([{ name, value: newVal }], env, recursionStack);
  }

  /**
   * @param {DataUpdateChangeType[]} changes - name/value
   * @param {Environment} env
   * @param {DerivedChangeFn[]=} recursionStack
   */
  async bulkUpdate(changes, env, recursionStack = []) {
    let changed = [];

    /** @type {DataUpdateChangeType[]} */
    let pendingParent = [];

    for (let change of changes) {
      let name = change.name.toLocaleLowerCase();
      change.final_name = name;
      let origVal = this.data[name];

      if (
        !change.is_local && (
          this.parent
          && this.parent.data
          && this.parent.parent // the root stores for the global space and addons are one level deep
          && !this.has(name)
          && (this.defined(name) || change.create_in_root_if_needed)
        )
      ) {
        // We don't own this data, but a parent does, so let's kick up the chain
        pendingParent.push(change);
      } else {
        let skipDerived = false;
        let newVal = change.value;
        if (origVal === undefined && newVal === undefined) {
          continue;
        }
        if (origVal === null && newVal === null) {
          continue;
        }
        if (!(origVal === null || origVal === undefined || newVal === null || newVal === undefined)) {
          if (origVal.type === 'error' && newVal.type === 'error' && origVal.message === newVal.message && origVal.error === newVal.error) {
            continue;
          }
          if (equals(origVal, newVal)) {
            // console.log('is equals', origVal, newVal);
            skipDerived = true;
          }
        }
        // console.log('THE CHANGE', change.name, this.data[change.name], newVal);
        
        this.updateChangeLogEntry(name, newVal);


        if (newVal?.type === 'lambda') {
          // add a random signature each time a lambda is reassigned
          // so dependencies will be updated
          newVal = Object.assign({}, newVal);
          newVal.signature = signatureCounter++;
        }
        
        this.updatedAtIndex = updatedAtCounter++;

        this.data[name] = newVal;

        if (change.is_local) {
          this.locals.add(name);
        }

        if (!skipDerived) {
          changed.push(change);
        }
      }
    }

    if (pendingParent.length) {
      await this.parent.bulkUpdate(pendingParent, env, recursionStack);
    }

    let ups = [];
    for (let c of changed) {
      ups.push(c.final_name.toLocaleLowerCase());
    }
    if (ups.length) {
      await this.updateDerived(ups, env, recursionStack);
    }
  }

  /**
   * @param {string[]} changedNames
   * @param {Environment} env
   * @param {DerivedChangeFn[]=} recursionStack
   */
  async updateDerived(changedNames, env, recursionStack = []) {
    if (recursionStack.length > 25) {
      console.warn('Recursion Budget for UpdateDerived exceeded');
      return; 
    };

    let ds;
    if (changedNames === null) {
      // update everything
      ds = this.derived;
    } else {
      ds = this.derived.filter(x => {
        let deps = x.dependencies;
        for (let dep of deps) {
          if (changedNames.includes(dep)) {
            return true;
          }
        }
        return false;
      });
    }
    for (let d of ds) {
      let newEnv = new Environment(this, env.config, env.locations);
      await newEnv.data.ready;
      await d.fn(newEnv, changedNames, recursionStack);
    }
    for (let child of this.children) {
      await child.updateDerived(changedNames, env, recursionStack);
    }
  }

  /**
   * @returns {Object<string, any>}
   */
  flattenData() {
    let flatData = this.parent ? this.parent.flattenData() : {};
    for (const selector in this.data) {
      flatData[selector] = toStr(this.data[selector]);
    }
    return flatData;
  }
}