import { getDataFromClient } from '../../desktop_shared_utils';
import { getKeyboardShortcutFromExtension, } from '../../extension';
import { CURRENT_PLATFORM } from '../../flags';

/** @type {Record<SupportedPlatformNames, string>} */
const DEFAULT_SHORTCUTS_BY_PLATFORM = {
  browser: `${navigator.platform.startsWith('Mac') ? 'Cmd' : 'Ctrl'}+Shift+Space`,
  windows: 'Ctrl+Shift+/',
  mac: 'Cmd+Shift+/'
};

let keyboardShortcut = DEFAULT_SHORTCUTS_BY_PLATFORM[CURRENT_PLATFORM];
let supportsShortcutChange = false;

// To update the shortcut in the desktop app if the user has changed the shortcut as we are not destroying the window
export function updateKeyboardShortcut() {
  if (CURRENT_PLATFORM === 'browser') {
    getKeyboardShortcutFromExtension().then((shortcut) => {
      keyboardShortcut = shortcut;
    });
  } else {
    getDataFromClient({ type: 'assistant-shortcut' }).then((res) => {
      if (!res) {
        return;
      }
      const { shortcut, changeable } = res;
      if (shortcut) {
        keyboardShortcut = shortcut;
        supportsShortcutChange = !!changeable;
      }
    });
  }
}

export function getCurrentPlatformKeyboardShortcut() {
  return keyboardShortcut;
}

export function getSupportsChange() {
  return supportsShortcutChange;
}