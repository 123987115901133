import React from 'react';
import { store, isOrg } from '@store';
import { databaseDialogs } from '../Database/database_utilities';
import { dialogs } from '../../message';
import { StatusNotification } from '../StatusNotification/StatusNotification';
import UserDialog from '../Dialog/UserDialog';


const CommonDialogs = () => {
  return <>
    {dialogs({ store, isOrg })}
    <StatusNotification />
    {databaseDialogs()}
    <UserDialog />
  </>;
};

export default CommonDialogs;