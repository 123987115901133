import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import moment from 'moment';
import { uidToEmail } from '../PermissionList/utilities';
import { checkOrg } from '../../flags';
import Tooltip from '@mui/material/Tooltip';
import { useTypedSelector } from '../../hooks';
import UserLink from '../Utilities/UserLink';


/**
 * @param {object} props
 * @param {boolean} props.selected
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {number} props.modifiedDate
 * @param {string} props.modifiedUID
 * @param {number} props.createdDate
 * @param {string} props.createdUID
 */
function ModifiedBadgeBase(props) {
  let uid = useTypedSelector(store => store.userState.uid);
  let isOrg = useTypedSelector(store => checkOrg(store));
  let snippetView = useTypedSelector(store => store.userState.views && store.userState.views[props.snippetId]);
  let groupView = useTypedSelector(store => store.userState.views && store.userState.views[props.groupId]);
  let {
    modifiedDate,
    modifiedUID,
    createdDate,
    createdUID
  } = props;


  let [modifiedName, setModifiedName] = useState(null);
  let [modifiedAgo, setModifiedAgo] = useState(null);

  if (!isOrg) {
    return null;
  }

  if (!modifiedDate && !createdDate) {
    return null;
  }

  let modifiedFlag = null;
  let modifiedType = null;

  if (modifiedDate && createdDate && modifiedDate === createdDate) {
    modifiedType = 'added';
  } else {
    modifiedType = 'updated';
  }

  if (modifiedUID !== uid) {
    if (snippetView && modifiedDate && snippetView < modifiedDate) {
      modifiedFlag = true;
      modifiedType = 'updated';
    } else if (!snippetView && groupView && createdDate && createdDate > groupView) {
      modifiedFlag = true;
      modifiedType = 'added';
      modifiedDate = createdDate;
      modifiedUID = createdUID;
    } else if (!snippetView && groupView && modifiedDate && modifiedDate > groupView) {
      modifiedFlag = true;
      modifiedType = 'updated';
    }
  }

  if (!modifiedFlag && !props.selected) {
    return null;
  }

  async function loadModifiedName() {
    if (!modifiedAgo) { // don't redo this work
      if (modifiedUID === uid) {
        setModifiedName('you');
      } else {
        setModifiedName(await uidToEmail(modifiedUID));
      }

      setModifiedAgo(moment(modifiedDate).fromNow());
    }
  }
  
  return <Tooltip
    onOpen={() => loadModifiedName()}
    title={modifiedName ? 
      (<>
        <span>{modifiedType === 'updated' ? 'Updated by ' : 'Added by '}</span> 
        <UserLink
          email={modifiedName}
          style={{ color: 'inherit' }}
          onClick={(evt) => evt.stopPropagation()}
        >{modifiedName}</UserLink>
        <span> {modifiedAgo}</span>
      </>)
      : '...'
    }
    placement="bottom-start"
    arrow
  >
    <Badge
      className="updated-badge"
      style={ { backgroundColor: modifiedFlag ? (modifiedType === 'updated' ? '#6ac4de' : '#8bc36f') : 'rgba(122, 122, 122, .2)' } }
      variant="dot" />
  </Tooltip>;
}


const ModifiedBadge = React.memo(ModifiedBadgeBase);
export default ModifiedBadge;
