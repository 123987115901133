import { createStore, combineReducers } from 'redux';
import { getExtensionCredentials, getVersion, sendToExtension, checkExtensionInstalled, isExtensionStateSynced, getExtensionLoggedInStatus, getExtensionMetadata } from './extension';
import { log, setLogUID, setLogOrgID, setFunctions } from './logging/logging';
import { storage } from './utilities';
import { isDev, checkOrg, checkPro, getReferralCookie, getLPCookie, isAndroid, isElectronApp } from './flags';
import { getTokenCredentials } from './credentials';
import equals from 'fast-deep-equal';
import equalsES6 from 'fast-deep-equal/es6';
import { setLocale } from './locales';
import Sync from './Sync/Sync';
import './firebase_init'; // Initialize firestore app
import { getAuth, onAuthStateChanged, signInWithCustomToken, getIdToken, getIdTokenResult, signOut } from 'firebase/auth';
import { getApp } from 'firebase/app';
import {
  enableMultiTabIndexedDbPersistence,
  clearIndexedDbPersistence,
  getFirestore,
  onSnapshot,
  runTransaction,
  terminate,
  query,
  where,
  limit
} from 'firebase/firestore';
import { getTBFunctions, makeRef } from './firebase_utilities';
import { randomize } from './experiment/experiment';
import { sendSignoutMessages, signout } from './signout';
import { dbReducer } from './components/Database/store';
import { sitesReducer } from './components/PageBlaze/store';
import { loginAuthSession } from './session_utils';
import { sendMessageToClient, setExtensionDetected } from './desktop_utilities';
import { isBusinessTrialOffer } from './components/Auth/hashes';
import { clarityLoadedResolver } from './raw_flags';
import { addBreadcrumb } from '@sentry/browser';

export const IS_MICRO = false;

const LOCALSTORAGE_KEY_MAP = {
  'snippetSidebarSize': 'SNIPPET_SIDEBAR_SIZE',
  'snippetAppSidebarSize': 'SNIPPET_APP_SIDEBAR_SIZE'
};

/** @type {import('./Sync/Sync').default} */
let sync;
export function createSync(dispatch) {
  // we shouldn't actually sync in micro mode
  sync = new Sync({
    storage,
    log,
    entityPostProcess: (_type, entity) => entity,
    dispatch,
    getUserState: () => store.getState().userState,
    isDashboard: true
  });

  store.subscribe(() => {
    sync.userChanges();
  });

  return sync;
}

export function getShortcuts() {
  if (uid()) {
    return sync.activeShortcuts();
  }
}


firestoreEnablePersistence()
  .catch(err => {
    // It is necessary so that when trying to set persistence
    // for an already used firestore, an exception is not thrown.
    // This is necessary for a webpack's hot reload just on dev environment,
    // as long as we enable persistence in the body of this file.
    if (isDev() && String(err).includes('already been started and persistence can no longer be enabled')) {
      return;
    }
    console.error('Firestore persistence enabling on start', err);
  });

async function firestoreEnablePersistence() {
  if (isDev()) {
    await enableMultiTabIndexedDbPersistence(getFirestore());
  }
}

// Key for firestore clear persistence lock
const LOCK_KEY = 'firestore_clear_persistence_lock';

/**
 * we should clear persistence only once for all active windows and tabs
 */
function firestoreClearPersistenceLock() {
  let lockTime = Number(localStorage.getItem(LOCK_KEY)) || 0;
  let currTime = Date.now();
  if (lockTime + 20 * 1000 <= currTime) {
    localStorage.setItem(LOCK_KEY, String(currTime));
    return true;
  }
  return false;
}

async function firestoreReset() {
  await terminate(getFirestore());
  if (firestoreClearPersistenceLock()) {
    await clearIndexedDbPersistence(getFirestore());
  }
  await firestoreEnablePersistence();

  // to reset defined below firestore collections and documents references
  firestoreRefsReset();
}

setFunctions(getTBFunctions());


/** @type {import("redux").Store} */
export let store;
/** @type {import('firebase/firestore').DocumentReference} */
export let usersSettingsRef = undefined;
/** @type {import('firebase/firestore').DocumentReference} */
export let usersNotificationsRef = undefined;
/** @type {import('firebase/firestore').CollectionReference} */
export let snippetsRef = makeRef('snippets');
/** @type {import('firebase/firestore').CollectionReference} */
export let groupsRef = makeRef('groups');

function firestoreRefsReset() {
  usersSettingsRef = undefined;
  usersNotificationsRef = undefined;
  snippetsRef = makeRef('snippets');
  groupsRef = makeRef('groups');
}


let usersSettingsSubscription = undefined;
let usersReadonlySubscription = undefined;
let usersNotificationsSubscription = undefined;
let configSubscription = undefined;
let templatesSubscription = undefined;


let loginResolve;
let loginPromise = new Promise((resolve, reject) => {
  loginResolve = resolve;
});

let authResolve;
let authPromise = new Promise((resolve, reject) => {
  authResolve = resolve;
});


const INITIAL_SETTINGS = {
  groups: {},
  quest: {
    mode: 'walkthrough'
  }
};


export async function sendCredentialsToExtension(uid) {

  return getExtensionLoggedInStatus().then(res => {
    if (!res) {
      // no extension
      return;
    }

    if (!res.loggedIn || res.uid !== uid) {
      log({
        category: 'Credential Management',
        action: 'Sending credentials to extension',
        label: 'token'
      });

      return getTokenCredentials(getApp()).then(credentials => {
        return sendToExtension({
          type: 'credentials',
          credentials: {
            provider: 'token',
            data: credentials
          }
        });
      });
    }
  });
}


export async function sendCredentialsToAndroid() {
  return getTokenCredentials(getApp()).then(credentials => {
    return sendToAndroid({
      type: 'credentials',
      credentials: {
        provider: 'token',
        data: credentials
      }
    });
  });
}


export async function sendToAndroid(data) {
  if (isAndroid() && window['ReactNativeWebView']) {
    window['ReactNativeWebView'].postMessage(JSON.stringify(data));
  }
}


function gettingAuthFailed() {
  store.dispatch({
    type: 'FAILED_GET_CREDENTIALS'
  });
}

// In Android we need document.addEventListener and in iOS we need window.addEventListener
// https://stackoverflow.com/a/58118984
if (isAndroid()) {
  document.addEventListener('message', function (event) {
    // @ts-ignore
    if (event.data) {
      // @ts-ignore
      let data = JSON.parse(event.data);
      if (data.type === 'credentials') {
        let credData = data.credentials;
        if (credData && credData.provider === 'token') {
          return signInWithCustomToken(getAuth(), credData.data.token).catch(e => {
            console.warn(e);
            gettingAuthFailed();
          });
        } else {
          gettingAuthFailed();
        }
      } else if (data.type === 'android-assistant') {
        store.dispatch({
          type: 'ANDROID_ASSISTANT',
          isRunning: data.isRunning
        });
      } else {
        gettingAuthFailed();
      }
    }
  });
}

if (isElectronApp()) {
  window['electronAPI'].removeMessageListener();
  window['electronAPI'].attachMessageListener(callback);
  async function callback(_, dataReceived) {
    if (dataReceived && dataReceived.type) {
      if (dataReceived.type === 'authenticate-dashboard') {
        const data = dataReceived.data;
        if (data.type !== 'credentials') {
          return;
        }
        if (!data.token) {
          return gettingAuthFailed();
        }
        try {
          addBreadcrumb({ message: '[TOKEN RECEIVED]' });
          await signOut(getAuth());
          await signInWithCustomToken(getAuth(), data.token);
          addBreadcrumb({ message: '[SIGN IN COMPLETED]' });
        } catch (e) {
          console.warn(e);
          gettingAuthFailed();
          // In the desktop app if dashboard sign-in fails, it will remain stuck on the logging in window
          sendSignoutMessages();
        }
      } else if (dataReceived.type === 'signout-dashboard') {
        await signout();
      } else if (dataReceived.type === 'extension-detected') {
        setExtensionDetected(true);
      }
    }
  }
  sendMessageToClient({ type: 'listeners-attached' });
}

/**
 * @param {Object<string, GroupObjectType>} groups
 * @returns {ReturnType<typeof isExtensionStateSynced>}
 */
export async function isExtensionSyncedWithDashboard(groups) {
  return new Promise(resolve => {
    // Not available in Safari 16
    // Our extension does not exist in Safari either way, so we
    // skip this check entirely in that case
    if (window.requestIdleCallback) {
      // At this stage the user's React DOM might still be loading
      // So, we run this expensive function when the browser is idle
      requestIdleCallback(() => {
        if (store.getState().userState.isReloginPersisted) {
          return;
        }
        isExtensionStateSynced(groups, sync.getGroupIds()).then(result => {
          store.dispatch({
            type: 'OUT_OF_SYNC',
            data: !result.isSynced,
          });
          if (result.isSynced === false) {
            log({
              action: 'Extension out of sync detected',
              label: result.data,
            });
          }
          resolve(result);
        });
      }, { timeout: 10 }); // milliseconds
    } else {
      resolve({ isSynced: true });
    }
  });
}

/**
 * Schedule to run some time after user login
 */
export function scheduledIsExtensionSyncedWithDashboard() {
  setTimeout(() => {
    isExtensionSyncedWithDashboard(store.getState().dataState.groups);
    // We increase the timeout because many users got this error 
    // previously at 15000ms
  }, 30000);
}

export function checkIfExtensionAuthIsStuck() {
  getExtensionMetadata().then((response) => {
    if (!response) {
      // Extension not installed
      return;
    }
    if (response.isAuthStuck) {
      store.dispatch({
        type: 'EXT_AUTH_STUCK',
        data: true,
      });
      console.error('Extension auth is stuck');
    }
  });
}

/**
 * @typedef {{list_order_by: 'asc_name'|'desc_name'|'asc_permission'|'desc_permission'|'asc_last_opened'|'desc_last_opened'|'asc_favorite'|'desc_favorite'|null, view: 'grid'|'list'|null}} OptionsViewSettings
 */

/**
 * @typedef {object} UserStateDef
 * @property {string=} uid
 * @property {string=} email
 * @property {string=} displayName
 * @property {boolean=} emailVerified
 * @property {boolean=} readonlyLoaded
 * @property {boolean=} settingsLoaded
 * @property {boolean=} is_pro
 * @property {boolean=} isLoaded
 * @property {boolean=} isRelogin - if true, the user manually logged in via the auth component screen and it's not their first login
 * @property {boolean=} isReloginPersisted - same as above but we don't clear this one once set
 * @property {boolean=} newSignUp - if true, the user manually signed up via the auth component
 * @property {boolean=} didAutoImport
 * @property {boolean=} isAiBlaze
 * @property {string=} referral_code
 * @property {number=} credit_balance
 * @property {string=} locale
 * @property {object[]=} notifications
 * @property {object[]=} messages
 * @property {object=} capabilities_adjustment
 * @property {object} [options]
 * @property {string=} options.capabilities_plan
 * @property {number=} options.last_viewed_notifications_at
 * @property {boolean=} options.snippet_editing_chips_enabled
 * @property {boolean=} options.snippet_editing_chips_enabled_2
 * @property {Object<string, {order: number, favorite: boolean}>=} options.databases
 * @property {string=} options.last_viewed_app
 * @property {boolean=} options.has_used_windows
 * @property {boolean=} options.has_used_mac
 * @property {boolean=} options.has_used_extension
 * @property {boolean=} options.is_windows_app_disabled_in_chrome_browsers
 * @property {boolean=} options.is_mac_app_disabled_in_chrome_browsers
 * @property {boolean=} options.ai_sidebar_opened
 * @property {boolean=} options.ai_sidebar_used
 * @property {Object<string, {favorite: boolean}>=} options.sites
 * @property {OptionsViewSettings=} options.spaces_settings
 * @property {OptionsViewSettings=} options.sites_settings
 * @property {boolean=} options.was_onboarded_with_ai_blaze
 * @property {boolean=} options.tb_onboarded
 * @property {{ tab_disabled?: boolean, domains?: Object<string, { no_tab?: boolean, }> }=} options.sidebar_metadata
 * @property {Object<string, {collapsed: boolean, disabled: boolean, order: number}>=} groups
 * @property {{id: string, type: string}=} org
 * @property {TeamObjectType[]=} teams
 * @property {object=} billing_alert
 * @property {object=} pro_grant_expiry
 * @property {import('firebase/auth').User=} firebaseUser
 * @property {object=} firebaseMetadata
 * @property {object=} usage
 * @property {number=} createdTimestamp
 * @property {{shown_timestamp: number, rating_timestamp: number, rating: number, chrome_webstore_rated: boolean, microsoft_store_rated: boolean}=} rating
 * @property {number=} will_cancel
 * @property {boolean=} disabled_groups_collapsed
 * @property {boolean=} team_groups_collapsed
 * @property {import('firebase/auth').UserInfo[]=} providerData
 * @property {object=} quest
 * @property {Object<string, number>=} views
 * @property {Object<string, any>=} member_fields_data
 * @property {object=} priorities
 * @property {string[]=} dismissed_notifications
 * @property {Object<string, InstalledAddonType>=} addons
 * @property {boolean=} mvp
 * @property {string=} photoUrl
 * @property {boolean=} isAndroidAssistantActive
 * @property {boolean=} userActivatedAppOrExtension
 * @property {number=} referrals
 * @property {number=} snippet_volume
 */

/**
 * The User Reducer
 * 
 * @param {UserStateDef} state
 * 
 * @return {UserStateDef}
 */
const userReducer = function (state = {
  isLoaded: false
}, action) {
  switch (action.type) {
  case 'LOGGING_BACK_IN':
    return Object.assign({}, state, {
      isRelogin: true,
      isReloginPersisted: true,
    });
  case 'DISMISS_LOGGING_BACK_IN':
    return Object.assign({}, state, {
      isRelogin: false
    });
  case 'NEW_SIGN_UP':
    return Object.assign({}, state, {
      newSignUp: true
    });
  case 'DID_AUTO_IMPORT':
    return Object.assign({}, state, {
      didAutoImport: true
    });
  case 'USER_LOGIN':
    if (usersSettingsSubscription) {
      usersSettingsSubscription();
      usersSettingsSubscription = null;
    }
    if (usersReadonlySubscription) {
      usersReadonlySubscription();
      usersReadonlySubscription = null;
    }
    if (usersNotificationsSubscription) {
      usersNotificationsSubscription();
      usersNotificationsSubscription = null;
    }
    if (configSubscription) {
      configSubscription();
      configSubscription = null;
    }

    if (templatesSubscription) {
      templatesSubscription();
      templatesSubscription = null;
    }

    if (action.user.uid) {
      window['xuid'] = action.user.uid;
      sendCredentialsToExtension(action.user.uid);
      sendCredentialsToAndroid();
      usersSettingsRef = makeRef('users_settings', action.user.uid);

      let handlingInitialLogin = false;

      configSubscription = onSnapshot(makeRef('keys', 'web_config'), (snapshot) => {
        if (snapshot.exists()) {
          store.dispatch({
            type: 'NEW_CONFIG',
            data: snapshot.data()
          });
        }
      });

      setLogUID(action.user.uid);

      usersSettingsSubscription = storage.onSnapshot(usersSettingsRef, (snapshot) => {

        if (window['deleting_user']) {
          // if we don't check for this, text blaze may recreate the initial groups while deleting the user
          return;
        }

        let metadata = snapshot.metadata;

        if (snapshot.exists()) {
          let data = snapshot.data();
          if (!data.groups && !metadata.fromCache) {
            // Groups must always exist, this is a failsafe to add them if missing
            // Only do this when we have data from the server (otherwise may trigger offline)
            data.groups = {};

            doInitialization();

            log({ category: 'Authentication', action: 'Creating groups via fallback' });
          }

          // Can have unnecessary changes due to metadata inclusion
          // So we check before pushing
          let storeState = store.getState().userState;
          for (let key in data) {
            if (!equals(data[key], storeState[key])) {
              store.dispatch({
                type: 'USER_SETTINGS_UPDATED',
                data
              });
              break;
            }
          }
        } else if (!metadata.fromCache) {
          // Only do this when we have data from the server (otherwise may trigger offline)
          if (!handlingInitialLogin) {
            handlingInitialLogin = true;

            doInitialization();
          }
        }

    
        if (snapshot.exists() && snapshot.data().options) {
          import('./data').then(module => {
            module.initializeSnippets();
          });
        }

      }, undefined, { includeMetadataChanges: true });
      usersReadonlySubscription = storage.onSnapshot(makeRef('users_readonly', action.user.uid), (snapshot) => {
        let data;
        if (snapshot.exists()) {
          data = snapshot.data();
        } else {
          data = {
            is_pro: false
          };
        }

        // Make sure the org and teams gets overwritten if removed
        if (!data.org) {
          data.org = null;
        }
        if (!data.teams) {
          data.teams = null;
        }
        if (!data.billing_alert) {
          data.billing_alert = null;
        }

        store.dispatch({
          type: 'USER_READONLY_UPDATED',
          data
        });
      });

      templatesSubscription = storage.onSnapshotQuery(
        query(makeRef('templates_gallery_templates'), where('created_by', '==', action.user.uid), limit(300)),
        (data) => {
          const templates = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

          store.dispatch({
            type: 'TEMPLATES_UPDATED',
            templates
          });
        });

      if (action.user.emailVerified) {
        usersNotificationsRef = makeRef('users_notifications', action.user.email);
        usersNotificationsSubscription = storage.onSnapshot(usersNotificationsRef, (snapshot) => {
          let data;
          if (snapshot.exists()) {
            data = snapshot.data();
          }
          store.dispatch({
            type: 'USER_NOTIFICATIONS_UPDATED',
            data
          });
        });


        getIdTokenResult(action.user.firebaseUser).then((token) => {
          let messageMatches = ['e:' + action.user.email];
          if (token.claims.org) {
            messageMatches.push('o:' + /** @type {{id: string}} */(token.claims.org).id);

            // note this can only 'array-contains-any' can only query 10 options
            // users shouldn't add more than 5 teams, but we limit it here just in case
            // as they technically could be added to more
            let teams = Object.keys(token.claims.teams).slice(0, 5);
            for (let team of teams) {
              messageMatches.push('t:' + /** @type {{id: string}} */(token.claims.org).id + '///' + team);
            }
          }
        });
      }

      checkIfExtensionAuthIsStuck();
      scheduledIsExtensionSyncedWithDashboard();
      loginResolve(action.user.firebaseUser);
      authResolve(action.user.uid);
      return Object.assign({}, state, {
        firebaseMetadata: action.user.firebaseUser.metadata
      }, action.user);
    } else {
      setLogUID(null);
      authResolve(null);
      return Object.assign({}, action.user);
    }
  case 'USER_EMAIL_UPDATED':
    return Object.assign({}, state, action.data);
  case 'USER_SETTINGS_UPDATED':
    setLocale(action.data.locale);
    return Object.assign({}, state, { settingsLoaded: true }, action.data);
  case 'USER_READONLY_UPDATED':
    let dataReadonly = action.data || {};
    if (!state || !equals(state.org, dataReadonly.org) || !equals(state.teams, dataReadonly.teams)) {
      // We need to update the id token so changed custom claims are available
      // to the Firestore rules.
      if (getAuth().currentUser) {
        getIdToken(getAuth().currentUser, true);
      }
    }
    return Object.assign({}, state, { readonlyLoaded: true }, dataReadonly);
  case 'USER_NOTIFICATIONS_UPDATED':
    let dataNotifications = action.data || {};
    return Object.assign({}, state, { readonlyLoaded: true }, { notifications: dataNotifications });
  case 'ANDROID_ASSISTANT':
    return Object.assign({}, state, { isAndroidAssistantActive: action.isRunning });
  case 'USER_ACTIVATED_APP_OR_EXTENSION':
    return Object.assign({}, state, { userActivatedAppOrExtension: true });
  default:
    return state;
  }
};

const ORG_PEEK = '_org_peek';


/**
 * @typedef {object} UIStateDef
 * @property {boolean=} sidebarOpen
 * @property {string=} selectedKind
 * @property {string=} selected
 * @property {boolean=} isSearchOpened
 * @property {Map<string, Set<string>>=} conflicts
 * @property {boolean=} creatingSnippets
 * @property {boolean=} walkthroughEarlyDismissal
 * @property {string=} peekingGroupId
 * @property {boolean=} outdatedExtension
 * @property {Set<string>=} recentlyInstalledAddons
 * @property {number=} snippetSidebarSize
 * @property {number=} snippetAppSidebarSize
 * @property {boolean=} onboardingDialogOpen
 * @property {boolean} isOutOfSync
 * @property {boolean} isExtAuthStuck
 * @property {Set<string>=} deletedSnippetsFrom - Tracks groupIds where snippets were deleted from.
 */

/**
 * The UI Reducer
 * 
 * @param {UIStateDef} state
 * 
 * @return {UIStateDef}
 */
const uiReducer = function (state = {
  selectedKind: 'page',
  selected: 'welcome',
  conflicts: null,
  sidebarOpen: false,
  isSearchOpened: false,
  isOutOfSync: false,
  isExtAuthStuck: false,
}, action) {
  switch (action.type) {
  case 'OPEN_SIDEBAR':
    return Object.assign({}, state, {
      sidebarOpen: true
    });
  case 'CLOSE_SIDEBAR':
    // Don't mutate state if no change.
    if (!state.sidebarOpen) {
      return state;
    }
    return Object.assign({}, state, {
      sidebarOpen: false
    });
  case 'CREATING_SNIPPETS':
    return Object.assign({}, state, {
      creatingSnippets: true
    });
  case 'DONE_CREATING_SNIPPETS':
    return Object.assign({}, state, {
      creatingSnippets: false
    });
  case 'OUTDATED_EXTENSION':
    return Object.assign({}, state, {
      outdatedExtension: true
    });
  case 'CLEAR_PEEKING_GROUP':
    if (sync) {
      if (state.peekingGroupId) {
        sync.unsubscribeToGroup(state.peekingGroupId, ORG_PEEK);
        sync.emitDataChange();
      }
    }
    return Object.assign({}, state, {
      peekingGroupId: null
    });
  case 'PEEK_GROUP':
    let groupId = action.data.group_id;
    if (state.peekingGroupId !== groupId) {
      if (sync) {
        // unsubscribe from existing peeking group if needed
        if (state.peekingGroupId) {
          sync.unsubscribeToGroup(state.peekingGroupId, ORG_PEEK);
        }
        sync.subscribeToGroup(groupId, ORG_PEEK);
      }

      return Object.assign({}, state, {
        peekingGroupId: groupId
      });
    }
    return state;
  case 'SELECT':
    return Object.assign({}, state, {
      selectedKind: action.kind,
      selected: action.key
    });
  case 'CONFLICTS':
    if (!equalsES6(state.conflicts, action.conflicts)) {
      return Object.assign({}, state, { conflicts: action.conflicts });
    }
    return state;
  case 'ADDON_INSTALLED':
    let recentlyInstalledAddons = new Set(state.recentlyInstalledAddons);
    recentlyInstalledAddons.add(action.data.name);

    return Object.assign({}, state, {
      recentlyInstalledAddons
    });
  case 'ADDON_UNINSTALLED':
    let origRecentlyInstalledAddons = new Set(state.recentlyInstalledAddons);
    origRecentlyInstalledAddons.delete(action.data.name);

    return Object.assign({}, state, {
      recentlyInstalledAddons: origRecentlyInstalledAddons
    });
  case 'CLEAR_RECENT_ADDONS':
    return Object.assign({}, state, {
      recentlyInstalledAddons: null
    });
  case 'LOCATION_CHANGE':
    const pathname = action.location.pathname;
    // /site/:site
    const pathParts = pathname.split('/');
    let kind = pathParts[1];

    let key = undefined;
    if (!kind) {
      kind = 'page';
      key = 'welcome';
    } else if (kind === 'folder' || kind === 'snippet') {
      key = pathParts[2];
    } else if (kind === 'site') {
      if (pathParts.length > 3 && pathParts[4]) {
        kind = 'site_page';
        key = pathParts[4];
      } else {
        key = pathParts[2];
      }
    } else {
      key = kind;
      kind = 'page';
    }

    let newState = Object.assign({}, state);
    newState.selectedKind = kind;
    newState.selected = key;

    return newState;
  case 'OPEN_SEARCH':
    return Object.assign({}, state, {
      isSearchOpened: true,
    });
  case 'CLOSE_SEARCH':
    return Object.assign({}, state, {
      isSearchOpened: false,
    });
  case 'RESIZE_SIDEBAR':
    localStorage.setItem(LOCALSTORAGE_KEY_MAP[action.data.type], action.data.size);
    return Object.assign({}, state, {
      [action.data.type]: action.data.size,
    });
  case 'OUT_OF_SYNC':
    return Object.assign({}, state, {
      isOutOfSync: action.data,
    });
  case 'EXT_AUTH_STUCK':
    return Object.assign({}, state, {
      isExtAuthStuck: action.data,
    });
  case 'ONBOARDING_DIALOG_OPEN':
    return Object.assign({}, state, {
      onboardingDialogOpen: action.onboardingDialogOpen
    });
  case 'WALKTHROUGH_EARLY_DISMISSAL':
    return Object.assign({}, state, {
      walkthroughEarlyDismissal: true
    });
  case 'WALKTHROUGH_COMPLETED':
    return Object.assign({}, state, {
      walkthroughEarlyDismissal: false
    });
  default:
    return state;
  }
};


/**
 * @typedef {object} ConfigDef
 * @property {number} MIN_WEB_VERSION
 * @property {number} MIN_MICRO_VERSION
 * @property {Object<string, {parent: string, shared: import("./components/Version/usageLimitations").LimitationsDef, skus: Object<string, import("./components/Version/usageLimitations").LimitationsDef>}>} plans
 * @property {boolean} configLoaded
 * @property {Object<string, object>} experiments map of experiment name to its keys
 */

/**
 * The config Reducer
 * 
 * @param {ConfigDef} state
 * 
 * @return {ConfigDef}
 */
const configReducer = function (state = {
  MIN_WEB_VERSION: 0,
  MIN_MICRO_VERSION: 0,
  plans: {},
  experiments: { WIDGET_PERCENT: 0, WIDGET_START_DATE_NEW: { seconds: 0, nanoSeconds: 0 } },
  configLoaded: false
}, action) {
  switch (action.type) {
  case 'NEW_CONFIG':
    return Object.assign({
      configLoaded: true
    }, action.data);
  default:
    return state;
  }
};


/**
 * @typedef {object} DataStateDef
 * @property {string[]} ignoreGroups
 * @property {Object<string, GroupObjectType>} groups
 * @property {Object<string, string>} extraGroupNames
 * @property {{groups: Object<string, GroupObjectType>, commands: Object<string, SnippetObjectType>}} addons
 * @property {TemplateData[]=} templates
 */

/**
 * The Data Reducer
 * 
 * @param {DataStateDef} state
 * 
 * @return {DataStateDef}
 */
const dataReducer = function (state = {
  groups: {},
  ignoreGroups: [],
  extraGroupNames: {},
  addons: {
    groups: {},
    commands: {}
  },
  templates: null
}, action) {
  switch (action.type) {
  case 'DATA_CHANGE':
    /** @type {Object<string, GroupObjectType>} */
    let groups = {};
    let addons = {
      groups: {},
      commands: {}
    };

    for (let id in action.groups) {
      groups[id] = Object.assign({}, action.groups[id].data);
      groups[id].id = id;
      groups[id].stub = action.groups[id].stub;
      groups[id].isAddon = action.groups[id].isAddon();
      groups[id].loading = action.groups[id].loading;
      groups[id].snippets = action.groups[id].snippets.map(snippet => Object.assign({}, snippet.data, {
        addonOptions: snippet.addonOptions
      }));
      groups[id].shared = !!((!groups[id].stub) && (
        (groups[id].permissions && Object.keys(groups[id].permissions).length > 1)
          || (groups[id].associated_team_ids && groups[id].associated_team_ids.length)
          || (sync.groupSubscriptions[id].find(s => !['user', ORG_PEEK].includes(s))))
      );
      groups[id].peekingOnly = sync.groupSubscriptions[id].length === 1 && sync.groupSubscriptions[id][0] === ORG_PEEK;

      if (action.groups[id].isAddon() && groups[id].options.addon.namespace) {
        let namespace = groups[id].options.addon.namespace.toLowerCase();
        addons.groups[namespace] = groups[id];
        for (let addon of groups[id].snippets) {
          if (addon.addonOptions) {
            addons.commands[addon.addonOptions.command] = addon;
          }
        }
      }
    }

    return Object.assign({}, state, {
      groups,
      addons,
      ignoreGroups: action.ignoreGroups,
    });
  case 'ADD_EXTRA_GROUP_NAME':
    return Object.assign({}, state, {
      extraGroupNames: Object.assign({}, state.extraGroupNames, {
        [action.groupId]: action.groupName
      })
    });
  case 'TEMPLATES_UPDATED':
    return { ...state, templates: action.templates };
  default:
    return state;
  }
};


/**
 * @typedef {object} OrgStateDef
 * @property {OrgObjectType} org
 * @property {Object<string, TeamObjectType>=} teams
 * @property {Object<string, TeamObjectType>=} allTeams
 */

/**
 * The Org Reducer
 * 
 * @param {OrgStateDef} state
 * 
 * @return {OrgStateDef}
 */
const orgReducer = function (state = {
  org: null,
  teams: null
}, action) {
  switch (action.type) {
  case 'ORG_UPDATE':
    setLogOrgID(action.data && action.data.id);
    return Object.assign({}, state, {
      org: action.data
    });
  case 'ORG_TEAMS_UPDATE':
    let teams = Object.assign({}, action.teams);
    for (let id in teams) {
      teams[id] = Object.assign({ id }, teams[id].data);
    }
    return Object.assign({}, state, {
      teams
    });
  case 'ORG_ALL_TEAMS_UPDATE':
    let allTeams = Object.assign({}, action.teams);

    for (let id in allTeams) {
      allTeams[id] = Object.assign({ id }, allTeams[id].data);
    }

    return Object.assign({}, state, {
      allTeams
    });
  default:
    return state;
  }
};


let combinedReducers = combineReducers({
  uiState: uiReducer,
  userState: userReducer,
  dataState: dataReducer,
  orgState: orgReducer,
  config: configReducer,
  dbState: dbReducer,
  sitesState: sitesReducer,
});


const rootReducer = (state, action) => {
  let newState = combinedReducers(state, action);
  if (newState.uiState.peekingGroupId) {
    // unsubscribe the peaking group as we have data for it
    if (newState.dataState.groups && newState.dataState.groups[newState.uiState.peekingGroupId] && !newState.dataState.groups[newState.uiState.peekingGroupId].peekingOnly) {
      sync.unsubscribeToGroup(newState.uiState.peekingGroupId, ORG_PEEK);
      newState = Object.assign({}, newState);
      newState.uiState = Object.assign({}, newState.uiState, { peekingGroupId: null });
    }
  }

  return newState;
};


/** @typedef {ReturnType<combinedReducers>} RootState */


/** @typedef {import("type-fest").PartialDeep<RootState>} TestRootState */
/** @typedef {import("type-fest").PartialDeep<OrgStateDef>} TestOrgState */
/** @typedef {import("type-fest").PartialDeep<UserStateDef>} TestUserState */
/** @typedef {import("type-fest").PartialDeep<DataStateDef>} TestDataState */

const initialSidebarSizes = {};
for (const key in LOCALSTORAGE_KEY_MAP) {
  const size = localStorage.getItem(LOCALSTORAGE_KEY_MAP[key]);
  if (size) {
    initialSidebarSizes[key] = parseInt(size);
  }
};
store = createStore(
  rootReducer,
  {
    uiState: initialSidebarSizes
  }
);


/**
 * The current firebase user.
 * 
 * @return {import('firebase/auth').User}
 */
export function firebaseUser() {
  let state = store.getState();
  if (!state || !state.userState) {
    return undefined;
  }
  return state.userState.firebaseUser;
}


/**
 * The current user's UID.
 * 
 * @return {string}
 */
export function uid() {
  let state = store.getState();
  if (!state || !state.userState) {
    return undefined;
  }
  return state.userState.uid;
}


/**
 * The current user's email.
 * 
 * @return {string}
 */
export function firebaseEmail() {
  let state = store.getState();
  if (!state || !state.userState) {
    return undefined;
  }
  return state.userState.email;
}


/**
 * Whether the current user is Pro.
 * 
 * @return {boolean}
 */
export function isPro() {
  return checkPro(store.getState());
}


/**
 * Whether the current user is a business user.
 * 
 * @return {boolean}
 */
export function isOrg() {
  return checkOrg(store.getState());
}


/**
 * Promise triggered when the user logs in.
 * 
 * @return {Promise<import('firebase/auth').User>}
 */
export async function waitForLogin() {
  return loginPromise;
}

/**
 * Promise triggered when auth completes (logged in or not).
 * 
 * @return {Promise}
 */
export async function waitForAuth() {
  return authPromise;
}

async function doInitialization() {
  await runTransaction(getFirestore(), function (transaction) {
    // This code may get re-run multiple times if there are conflicts.
    return transaction.get(usersSettingsRef).then((usersSettingsDoc) => {
      if (usersSettingsDoc.exists()) {
        if (usersSettingsDoc.data().groups) {
          console.warn('Initialization already done');
          return;
        }
      }


      // Snippet_2022 has a limit of 200 snippets on the free plan
      let capabilitiesPan = randomize('More snippets 2022', {
        type: 'WeightedChoice',
        choices: ['SNIPPET_2022', 'SNIPPET_2020_R1'],
        weights: [0.05, 0.95]
      });

      let settings = Object.assign({}, INITIAL_SETTINGS, {
        options: {
          capabilities_plan: capabilitiesPan,
        },
      });

      transaction.set(usersSettingsRef, settings, { merge: true });
    });
  });

  await import('./data').then(module => {
    if (isBusinessTrialOffer()) {
      module.initializeBusinessTrial();
    }
  });

  let ref = getReferralCookie();
  if (ref) {
    const { applyCode } = await import('./bapi');
    // we don't want to block on this as we want the UI to come up 
    // as soon as possible
    applyCode({
      referral_code: ref
    });
  }



  let landingPageData = {};
  let lp = getLPCookie();
  if (lp) {
    Object.assign(landingPageData, lp);
  }
  let slp = getLPCookie('s');
  if (slp) {
    for (let key in slp) {
      landingPageData['shortterm_' + key] = slp[key];
    }
  }
  if (Object.keys(landingPageData).length) {
    // if we either have the main cookie or the short term cookie, log an event
    log({
      action: 'Sign up landing page',
      label: landingPageData
    });
  }
}


if (!isAndroid()) {
  Promise.all([checkExtensionInstalled(), getVersion()]).then(res => {
    if (!res[0] || !res[1]) {
      return;
    } // not installed
    let x = res[1];
    let parts = x.split('.').map(x => parseFloat(x));
    if ((parts[0] > 1 ||
      (parts[0] === 1 && parts[1] > 4) ||
      (parts[0] === 1 && parts[1] === 4 && parts[2] >= 9))) {
      return;
    } else {
      store.dispatch({
        type: 'OUTDATED_EXTENSION'
      });
    }
  });
}


// Get credentials from the extension if
// we aren't logged in the extension has credentials
waitForAuth().then((uid) => {
  if (window['localStorage'] && !uid) {
    getExtensionCredentials('token').then(credentials => {
      if (credentials) {
        let credData = credentials.data;
        if (credentials.provider === 'token' && credData) {
          log({
            category: 'Credential Management',
            action: 'Setting credentials from extension',
            label: 'token'
          });
          return signInWithCustomToken(getAuth(), credData.token).catch(e => {
            console.warn(e);
          });
        }
      }
    });
  }
});


storage.setStore({
  uid,
  store
});


onAuthStateChanged(getAuth(), function (user) {
  if (user) {
    log({ category: 'Authentication', action: 'Logged in' });
    loginAuthSession(user);
    store.dispatch({
      type: 'USER_LOGIN',
      user: {
        isLoaded: true,
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        photoUrl: user.photoURL,
        uid: user.uid,
        createdTimestamp: (new Date(user.metadata.creationTime)).getTime(),
        firebaseUser: user,
        providerData: user.providerData
      }
    });


    // To understand non-org user activation and onboarding, activate MS Clarity for non-org, new (defined as < 1 day old) freemail users (defined as gmail users for simplicity)
    getIdTokenResult(user).then(token => {
      if (
        user.email.endsWith('@gmail.com')
        && (new Date(user.metadata.creationTime)).getTime() > Date.now() - 24 * 60 * 60 * 1000
        && !token.claims.org
      ) {
        (function (c, l, a, r, i, t, y) {
          c[a] = c[a] || function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
          t = l.createElement(r); t.async = 1; t.src = 'https://www.clarity.ms/tag/' + i;
          t.addEventListener('load', () => {
            clarityLoadedResolver();
          });
          y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, 'clarity', 'script', 'kk4l5i76kx');
      }
    });

  } else {
    store.dispatch({
      type: 'USER_LOGIN',
      user: {
        isLoaded: true
      }
    });
    firestoreReset();
  }
});


// Used for Data Blaze. Firebase doesn't appear to refresh this on load unless it's expiring.
// This creates issues, for example:
//  - if you load DB without verifying your email
//  - then verify
//  - then load DB again
// Without the `hasRefreshedTokenOnLoad` logic, the first requests to the backend will incorrectly
// include a token indicating you still aren't verified.
let hasRefreshedTokenOnLoad = false;
export const currentIdToken = () => {
  let forceRefresh = false;
  if (!hasRefreshedTokenOnLoad) {
    hasRefreshedTokenOnLoad = true;
    forceRefresh = true;
  }
  return getIdToken(getAuth().currentUser, forceRefresh);
};
