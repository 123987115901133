import React, { useState } from 'react';
import { normalizeVariableNameForReplace } from '../replace_variable_name';
import { IconButton, ListItemText, Menu, Tooltip, MenuItem, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

/**
 * @param {Object} props
 * @param {Function=} props.insert
 * @param {import("../editor_utilities").CollapsedDataType['value']['attributes']} props.values
 * @param {Function} props.setAttributes
 * @param {string[]} props.options
 * @param {boolean} props.isAdvanced
 */
export function MenuAutoConditionals(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  /** @type {string|undefined} */
  let menuName = props.values?.find(value => value.name === 'name')?.value;
  const isMultiple = props.values?.find(value => value.name === 'multiple')?.raw === 'yes';
  const isUsingValuesField = props.values?.find(value => value.name === 'values');
  const open = Boolean(anchorEl);

  async function insertIfConditionals() {
    setAnchorEl(null);

    if (!props.values || !props.insert) {
      return null;
    }

    if (!menuName) {
      menuName = `formmenu${Math.floor((Math.random() * 1000))}`;

      props.setAttributes(
        [{ name: 'name', data: { value: menuName, raw: menuName } }],
        {
          positional: false,
          fillIn: false
        },
        undefined,
        true
      );
    }

    const ifCondition = props.options.reduce((acc, option, index) => {
      const escapedName = normalizeVariableNameForReplace(menuName);
      const optionWithEscapedQuotes = option.replace(/(?<!\\)"/g, '\\"');
      const valueEqualsOption = `${escapedName} = "${optionWithEscapedQuotes}"`;
      const conditionalText = `\nWhen the menu selection is "${option}", this will show.\n`;

      const endIf = index + 1 === props.options.length ? '{endif: trim=left}' : '';

      if (!acc) {
        return `{if: ${valueEqualsOption}; trim=right} ${conditionalText}${endIf}`;
      }

      return `${acc}{elseif: ${valueEqualsOption}; trim=yes} ${conditionalText}${endIf}`;
    }, '');

    props.insert(ifCondition);
  }

  // when using the values field, the options is converted to "default"
  if (isUsingValuesField || !props.options || !props.options.length) {
    return null;
  }

  return (
    <div>
      <IconButton
        data-testid="auto-conditionals-menu"
        aria-label="Open menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="conditionals-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {(isMultiple || props.isAdvanced)
          ? <MenuItem disabled>
            <ListItemIcon>
              <ErrorOutlineIcon fontSize="small" color="warning" />
            </ListItemIcon>
            <ListItemText>
              {isMultiple
                ? 'Disable the "multiple" option to create conditions from menu options.'
                : 'Disable the advanced editor to create conditions from menu options.'}
            </ListItemText>
          </MenuItem>
          : <Tooltip title="Create conditions using if/else commands to show different content based on the selected menu option.">
            <MenuItem onClick={insertIfConditionals}>
              <ListItemIcon>
                <AddCircleOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Create conditions from menu options</ListItemText>
            </MenuItem>
          </Tooltip>}
      </Menu>
    </div>
  );
}