/** @type {React.CSSProperties} */
export const containerStyle = {
  flex: 1,
  overflow: 'auto',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column'
};

/**
 *
 * @param {TeamObjectType[]} teams
 * @param {string} uid
 * @param {string} email
 * @return {TeamObjectType[]|null}
 */
export function getTeamsAdminedByUser(teams, uid, email) {
  return teams.filter(team => {
    return (team.permissions[`u:${uid}`] && team.permissions[`u:${uid}`].type === 'owner') ||
        (team.permissions[`e:${email}`] && team.permissions[`e:${email}`].type === 'owner');
  });
}


/**
 *
 * @param {import('../PermissionList/utilities').Permission[]} members
 * @returns string
 */
export function getOriginalMemberIdByEmail(members, email) {
  return members.find(member => member.entityType === 'user' && member.entityValue === email)?.original;
}

/**
 *
 * @param {React.ClipboardEvent<HTMLDivElement>} e
 * @param {string[]} value
 * @param {string[]} emails
 * @param {Function} onChange
 * @param {Function} onUpdateValue
 */
export function handleOnPasteForAutocomplete(e, value, emails, onChange, onUpdateValue) {
  // Allows us to paste in a list of comma separated emails to
  // bulk-add users

  let contents = e.clipboardData.getData('text/plain');

  let candidates = contents.split(',').map(x => x.trim().toLowerCase()).filter(x => !!x);
  let newItems = value.slice();

  let itemsChanged = false;
  for (let i = 0; i < candidates.length; i++) {
    let candidate = candidates[i];
    if (emails.includes(candidate)) {
      candidates.splice(i, 1);
      i--;
      if (!newItems.includes(candidate)) {
        newItems.push(candidate);
        itemsChanged = true;
      }
    }
  }


  if (itemsChanged) {
    // We made a change, update value, inputValue, and
    // block the clipboard from inserting

    e.preventDefault();
    onChange(newItems);

    if (candidates.length) {
      // still have some things we need to show as they aren't in the list
      let newText = candidates.join(', ');
      onUpdateValue(newText);
    }

  }
}

/**
 * @param {string} permissionKey 
 * @param {import('../PermissionList/utilities').Permission[]} members
 */
export const getEmailFromPermissionKey = (permissionKey, members) => {
  if (permissionKey.startsWith('e:')) {
    return permissionKey.slice(2);
  } else {
    return members.find(member => member.original === permissionKey).entityLabel;
  }
};