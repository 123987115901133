import React, { useEffect, useState } from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCustomToken
} from 'firebase/auth';
import {
  Box,
  Chip,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography as T
} from '@mui/material';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/MailOutlined';
import PasswordIcon from '@mui/icons-material/LockOutlined';
import LockIcon from '@mui/icons-material/Lock';
import AsyncButton from '../AsyncButton/AsyncButton';
import { sendToAndroid, store } from '@store';
import { toast } from '../../message';
import { log } from '../../logging/logging';
// @ts-ignore
import googleIcon from './google_icon.png';
import { APP_TARGET, getCookie, isAndroid } from '../../flags';
import { isSamlUser, loginSamlUser } from './saml_auth';
import { isValidEmail, validateNewPassword } from './auth_utilities';
import useSession from '../../hooks/useSession';
import { useHistory, useLocation } from 'react-router-dom';
import useOnMount from '../../hooks/useOnMount';
import { signUpHashes } from './hashes';
import { onClarityLoaded } from '../../raw_flags';
import { deleteSessionCookie } from '../../session_utils';

let googleProvider = new GoogleAuthProvider();
const googleTheme = createTheme({
  palette: {
    primary: {
      main: '#4285F4',
    },
  }
});


function getError(error) {
  let found = {
    'auth/user-not-found': 'User could not be found.',
    'auth/popup-closed-by-user': 'Closed sign in pop-up without approving request.'
  }[error.code];
  return found || error.message;
}

function logLandingPageExperiments() {
  // landing page experiments
  const blogExperimentCookies = getCookie('lpexp');

  // makes it easy to know the full name of the log action, and allows us to keep the keys short
  const experimentNames = {
    n_menu: 'Registered with new menu'
  };

  if (blogExperimentCookies) {
    try {
      const cookies = JSON.parse(decodeURIComponent(blogExperimentCookies));
      Object.entries(cookies).forEach(([key, value]) => {
        if (experimentNames[key]) {
          log({
            action: `Landing page experiment ${experimentNames[key]}`,
            label: value
          });
        } else {
          console.error(`Invalid landing page cookie key: ${experimentNames[key]}`);
        }
      });
    } catch (e) {
      console.error('Error parsing experiment cookies from landing page', e);
    }
  }
}


/** @type {React.CSSProperties} */
const fieldStyle = {
  textAlign: 'center'
};


/** @type {React.CSSProperties} */
const buttonStyle = {
  marginTop: 10,
  textAlign: 'right'
};

let samlCheckTimer = null;

/**
 * @param {Object} props
 * @param {boolean=} props.signUpStart
 */
export default function AuthComponent({ signUpStart }) {
  const { replace } = useHistory();
  const { hash } = useLocation();

  let t = signUpStart ? 1 : 0; // log in

  // For users accessing the dashboard from the initial extension install
  // we want to default to the sign up tab.
  if (signUpHashes.includes(hash)) {
    t = 1; // sign up
  }

  let [tabMode, setTabMode] = useState(t);
  let [email, setEmail] = useState('');
  let [emailError, setEmailError] = useState('');
  let [password, setPassword] = useState('');
  let [passwordError, setPasswordError] = useState('');
  let [password2, setPassword2] = useState('');
  let [password2Error, setPassword2Error] = useState('');
  let [loginLoading, setLoginLoading] = useState(false);
  let [createLoading, setCreateLoading] = useState(false);
  let [isSamlEmail, setIsSamlEmail] = useState(false);
  // the login button will be enabled in 5 seconds if verify didn't respond
  const sessionLoading = useSession();
  const isLoading = sessionLoading || loginLoading;

  useOnMount(() => {
    if (hash === '#start') {
      log({
        action: 'Post install signup prompt'
      });
      // remove the hash so bookmarks don't include it
      setTimeout(() => {
        if (hash === '#start') { // if it's still #start
          replace({ hash: '' });
        }
      }, 500);
    }
  });

  const handleTabChange = (_event, value) => {
    setTabMode(value);
    // validation changes based on tab
    setPasswordError(password && validatePassword(password, value));
  };

  const validateEmail = (input) => {
    if (!isValidEmail(input)) {
      return 'Not a valid email.';
    }
  };

  /**
   * Validates the password while creating an account.
   * @param {string} input
   * @param {number} t
   * @returns
   */
  const validatePassword = (input, t = tabMode) => {
    //Not signup mode
    if (t !== 1) {
      return;
    }
    return validateNewPassword(input);
  };

  const validatePassword2 = (input, password1) => {
    if (input && input !== (password1 || password)) {
      return 'Your passwords do not match.';
    }
  };

  function handleNewSignUp() {
    log({
      category: 'Authentication',
      action: 'New sign up',
      label: {
        initial_app_type: APP_TARGET
      }
    });

    onClarityLoaded(() => {
      window['clarity']('set', 'INITIAL_APP_TYPE', APP_TARGET);
    });

    logLandingPageExperiments();

    store.dispatch({ type: 'NEW_SIGN_UP' });
  }

  const createAccount = (done) => {
    log({
      category: 'Authentication',
      action: 'Attempt create account'
    });
    setCreateLoading(true);

    createUserWithEmailAndPassword(getAuth(), email, password).then(() => {
      sendEmailVerification(getAuth().currentUser).then(() => {
        log({ category: 'Authentication', action: 'Sent verification email' });
      }).catch((error) => {
        log({ category: 'Authentication', action: 'Failed verification email', label: error });
      });

      handleNewSignUp();

      setCreateLoading(false);
    }).catch((error) => {
      log({ category: 'Authentication', action: 'Failed create account', label: error });
      setCreateLoading(false);
      toast('Failed to create new account. ' + getError(error), { intent: 'danger' });
    });
  };

  const signup = async (done) => {
    try {
      // clear the __session cookie so that a new one can be created
      deleteSessionCookie();

      const isSAML = await isSamlUser(email);
      if (isSAML) {
        await samlLogin();
      } else {
        await createAccount();
      }
    } finally {
      done();
    }
  };

  const emailLogin = async () => {
    log({ category: 'Authentication', action: 'Attempt login', label: 'password' });
    setLoginLoading(true);
    try {

      const cred = await signInWithEmailAndPassword(getAuth(), email, password);
      if (!getAdditionalUserInfo(cred).isNewUser) {
        store.dispatch({
          type: 'LOGGING_BACK_IN'
        });

      }
    } catch (error) {
      log({ category: 'Authentication', action: 'Failed login' });

      toast('Failed to sign-in. ' + (error.code ? getError(error) : JSON.stringify(error)), { intent: 'danger' });
    } finally {
      setLoginLoading(false);
    };
  };

  const samlLogin = async () => {
    try {
      setLoginLoading(true);
      const token = await loginSamlUser(email);
      await signInWithCustomToken(getAuth(), token);
    } catch (err) {
      log({ category: 'Authentication', action: 'Failed SAML login' });
      toast('Failed to saml-login. ' + (err.code ? getError(err) : JSON.stringify(err)), { intent: 'danger' });
    } finally {
      setLoginLoading(false);
    }
  };

  const login = async (done) => {
    try {
      // clear the __session cookie so that a new one can be created
      deleteSessionCookie();

      const isSAML = await isSamlUser(email);
      if (isSAML) {
        await samlLogin();
      } else {
        await emailLogin();
      }
    } finally {
      done();
    }
  };
  const checkEmailForSaml = async (emailToCheck) => {
    const doCheck = async () => {
      if (validateEmail(emailToCheck)) {
        return setIsSamlEmail(false);
      }
      const isSAML = await isSamlUser(emailToCheck);
      if (isSAML === null) {
        return;
      }
      setIsSamlEmail(isSAML);
    };
    clearTimeout(samlCheckTimer);
    samlCheckTimer = setTimeout(doCheck, 300);
  };
  useEffect(() => {
    checkEmailForSaml(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPassword = (done) => {
    log({ category: 'Authentication', action: 'Attempt reset password' });

    sendPasswordResetEmail(getAuth(), email).then(() => {
      log({ category: 'Authentication', action: 'Reset password sent' });
      done();
      toast('Sent password recovery email.');
    }, (error) => {
      log({ category: 'Authentication', action: 'Failed reset password' });
      done();
      toast('Failed to send password recovery email. ' + getError(error), { intent: 'danger' });
    });
  };

  const loginComponents = () => {
    let msg = tabMode === 0 ? 'Log in' : 'Sign up';
    return <>
      <div style={{
        textAlign: 'center',
        marginTop: 0,
        marginBottom: 12,
        width: '100%'
      }}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={googleTheme}>
            <AsyncButton
              color="primary"
              variant="contained"
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                justifyContent: 'flex-start',
                padding: '1px',
                '.MuiButton-startIcon': {
                  m: 0
                }
              }}
              onClick={(done) => {
                // clear the __session cookie so that a new one can be created
                deleteSessionCookie();

                log({ category: 'Authentication', action: 'Attempt login', label: 'google' });
                if (isAndroid()) {
                  sendToAndroid({
                    type: 'google-login'
                  });
                } else {
                  signInWithPopup(getAuth(), googleProvider).then((cred) => {
                    // Note: a user can "sign up" by using the Google's Auth even if they don't have an account yet
                    if (getAdditionalUserInfo(cred).isNewUser) {
                      handleNewSignUp();
                    } else {
                      store.dispatch({
                        type: 'LOGGING_BACK_IN'
                      });
                    }
                    done();
                  }).catch((err) => {
                    log({ category: 'Authentication', action: 'Failed login', label: 'google' });
                    toast('Failed to ' + msg.toLowerCase() + '. ' + (err.code ? getError(err) : JSON.stringify(err)), { intent: 'danger' });
                    done();
                  });
                }
              }}
              fullWidth
              startIcon={
                <img
                  src={googleIcon}
                  alt="Google Logo"
                  width={48}
                  height={48}
                  style={{
                    backgroundColor: 'white',
                    padding: 12,
                    borderRadius: '4px 0 0 4px'
                  }}
                />
              }
            >
              <span
                style={{
                  display: 'inline-block',
                  flex: 1
                }}
              >
                {`${msg} with Google`}
              </span>
            </AsyncButton>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>

      <T color="textSecondary" style={{ textAlign: 'center', marginBottom: 15, marginTop: 20 }}>or</T>

      <div style={fieldStyle}>
        <TextField
          name="email"  //Without name, browser shows random suggestions based on id
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{ opacity: 0.4 }}><MailIcon fontSize="small" /></InputAdornment>,
          }}
          style={{ width: '100%' }}
          placeholder="jane@example.com"
          variant="outlined"
          label="Your email"
          helperText={emailError || ' '}
          value={email}
          onChange={async (e) => {
            let v = e.target.value;
            setEmail(v);
            setEmailError(v && validateEmail(v));
            checkEmailForSaml(v);
          }}
          size="small"
          error={!!emailError}
          onKeyPress={attemptEnter}
          sx={{
            my: .8
          }}
        />
      </div>

      {!isSamlEmail && (
        <div style={fieldStyle}>
          <TextField
            style={{
              width: '100%'
            }}
            label="Your password"
            helperText={passwordError || ' '}
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start" style={{ opacity: 0.4 }}><PasswordIcon fontSize="small" /></InputAdornment>,
            }}
            type="password"
            size="small"
            value={password}
            onChange={(e) => {
              let v = e.target.value;
              setPassword(v);
              setPasswordError(v && validatePassword(v));
              setPassword2Error(v && validatePassword2(password2, v));
            }}
            error={!!passwordError}
            onKeyPress={attemptEnter}
            sx={{
              my: .8
            }}
          />
        </div>
      )}
      {isSamlEmail && (
        <Box
          height={(tabMode + 1) * 76.69}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Chip
            avatar={<LockIcon fontSize="small" />}
            label="Single sign-on enabled"
          />
        </Box>
      )}

      {tabMode === 0 ? <div style={buttonStyle}>
        {!isSamlEmail && <Tooltip title={!!(!email || emailError || isLoading) ? 'Enter your email above' : ''} >
          <span>
            <AsyncButton
              onClick={(done) => resetPassword(done)}
              disabled={!!(!email || emailError || isLoading)}
            >Forgot password</AsyncButton>
          </span>
        </Tooltip>}
        <AsyncButton
          variant="contained"
          color="primary"
          onClick={(done) => login(done)}
          style={{ marginLeft: 20 }}
          disabled={!canLogin()}
        >
          {isSamlEmail ? 'Continue' : 'Log in'}
        </AsyncButton>

      </div> : <>
        {!isSamlEmail && (
          <div style={fieldStyle}>
            <TextField
              style={{ width: '100%' }}
              label="Repeat password"
              helperText={password2Error || ' '}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{ opacity: 0.4 }}><PasswordIcon fontSize="small" /></InputAdornment>,
              }}
              variant="outlined"
              type="password"
              size="small"
              value={password2}
              onChange={(e) => {
                let v = e.target.value;
                setPassword2(v);
                setPassword2Error(v && validatePassword2(v));
              }}
              error={!!password2Error}
              onKeyPress={attemptEnter}
              sx={{
                my: .8
              }}
            />
          </div>
        )}
        <div style={buttonStyle}>
          <AsyncButton
            variant="contained"
            color="primary"
            disabled={!canCreate()}
            onClick={(done) => signup(done)}
          >{isSamlEmail ? 'Continue' : 'Sign up'}</AsyncButton>
        </div>
      </>}
    </>;
  };

  const attemptEnter = (e) => {
    if (e.key === 'Enter') {
      if (tabMode === 1 && canCreate()) {
        signup(() => { });
      } else if (tabMode === 0 && canLogin()) {
        login(() => { });
      }
    }
  };

  const canCreate = () => {
    if (isLoading || createLoading) {
      return false;
    }
    if (!email || emailError) {
      return false;
    }
    if (isSamlEmail) {
      return true;
    }
    return !(!password || !password2 || passwordError || password2Error);
  };

  const canLogin = () => {
    if (isLoading || createLoading) {
      return false;
    }
    if (!email || emailError) {
      return false;
    }
    if (isSamlEmail) {
      return true;
    }

    return !(!password || passwordError);
  };

  return (
    <>
      <Tabs
        value={tabMode}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        centered
      >
        <Tab label="Log in" />
        <Tab label="Sign up" />
      </Tabs>

      <div className="auth-component" style={{ padding: 25, width: '100%' }}>
        {loginComponents()}
      </div>
    </>
  );
};