import React, { useEffect, useState } from 'react';

import { getApp } from 'firebase/app';
import { getTokenCredentials } from '../../credentials';
// @ts-ignore
import blazeIcon from '../../../images/icon_128.png';
// @ts-ignore
import aiBlazeIcon from '../../../images/ai_blaze.webp';
import { Alert, Button, CircularProgress, Paper, Typography as T } from '@mui/material';
import { signout } from '../../signout';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { APP_PLATFORM_NAME, DESKTOP_PROTOCOL } from '../../flags';
import { DialogBackgroundStyles } from './auth_utilities';
import AsyncButton from '../AsyncButton/AsyncButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory, useLocation } from 'react-router-dom';
import useOnMount from '../../hooks/useOnMount';
import { isAiBlaze } from '../../aiBlaze';
import { fullAppName } from '../../raw_flags';

/**
 * @param {object} credentials 
 */
function openDeepLink(credentials) {
  if (credentials !== null) {
    window.location.replace(DESKTOP_PROTOCOL + '://login?data=' + encodeURIComponent(JSON.stringify(credentials)));
  }
}

const oneTimeToken = new URLSearchParams(window.location.search).get('token');

export default function DesktopAuth() {
  const { replace } = useHistory();
  const { hash } = useLocation();
  let [userEmail, setUserEmail] = useState('');
  let [tokenCredential, setTokenCredential] = useState(null);
  const [showRelaunch, setShowRelaunch] = useState(!oneTimeToken);
  useOnMount(() => {
    setTimeout(() => {
      if (oneTimeToken) {
        replace({ hash });
      }
    }, 500);
  });
  
  useEffect(() => {
    getTokenCredentials(getApp()).then(credentials => {
      credentials.oneTimeToken = oneTimeToken;
      setTokenCredential(credentials);
      setUserEmail(credentials.email);
      if (oneTimeToken) {
        openDeepLink(credentials);
      }
      // We don't auto-launch the desktop app in case user had opened the link accidentally
      // We might do that down the road once 1. we have generated tokens to ensure each link
      // is only used once
      // 2. no auto-logout in the desktop app when you open such a link
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setShowRelaunch(true), 5000);
    return () => clearTimeout(timeout);
  }, []);

  const hasLoaded = !!tokenCredential;

  function renderRelaunch() {
    if (showRelaunch) {
      return <Button size="small" color="primary" 
        style={{ marginTop: 10 }} onClick={() => openDeepLink(tokenCredential)} startIcon={<OpenInNewIcon/>}>
    Launch again 
      </Button>;
    }
    return null;
  }

  function renderInner() {
    if (oneTimeToken) {
      if (hasLoaded) {
        return <>
          <T align="left" variant="h6" >Sign in successful</T>
          <T align="left" variant="body1">Redirecting you back to the application</T>
          {renderRelaunch()}</>;
      } else {
        return  <CircularProgress size={40} />;
      }
    } else {
      // TODO: Remove it when all the users have binaries which support token-based login
      return <AsyncButton variant="contained"
        size="large"
        isLoading={!hasLoaded}
        onClick={() => {
          openDeepLink(tokenCredential);
        }}>Login to {fullAppName} for {APP_PLATFORM_NAME}
      </AsyncButton>;
    }
  }
  
  return <div style={{
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...DialogBackgroundStyles
  }}>
    <div style={{
      display: 'flex',
      alignItems:'center',
      flex: 1
    }}>
      <Paper
        elevation={10}
        style={{
          justifyContent: 'space-evenly',
          display: 'flex',
          alignItems:'center',
          flexDirection: 'row',
          padding: '50px'
        }}>
        <div style={{ marginRight: '32px' }}>
          <img
            alt="Blaze Icon"
            src={isAiBlaze ? aiBlazeIcon : blazeIcon}
            style={{
              width: 90,
              height: 90,
            }}
          />
        </div>
        <div style={{ display: 'block' }}>
          {renderInner()}
        </div>
      </Paper>
    </div>
    <div style={{ opacity: hasLoaded ? 'initial' : 0 }}>
      <Alert severity="info" sx={{ mb: 4, mx: 2 }}>You will be signed in as <b>{userEmail}</b>. <> <a href="#desktopauth" onClick={() => {
        signout();
      }}><ExitToAppIcon sx={{
          verticalAlign: 'middle',
          opacity: .8,
          ml: 1
        }} />  Sign out</a> to use a different account.</></Alert>
    </div>
  </div>;
}