import React, { useEffect, useState } from 'react';
import {
  Alert,
  Avatar,
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography as T,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import { organizationMember } from '../../bapi';
import TeamsIcon from '@mui/icons-material/GroupWork';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from '../../messageToast';

const UserDialog = () => {
  let isOrgMember = useTypedSelector((store) => store.orgState?.org?.id && store.orgState?.allTeams);

  if (!isOrgMember) {
    return null;
  }

  return <UserDialogInner />;
};

export default UserDialog;


const UserDialogInner = () => {
  let teams = useTypedSelectorShallowEquals((store) => store.orgState?.allTeams);
  const [isOrgLoading, setOrgLoading] = useState(false);
  const [user, setUser] = useState(null);

  const {
    hash
  } = useLocation();
  const { push: navigate } = useHistory();
  let email = '';
  if (hash?.startsWith('#user/')) {
    email = hash.substring('#user/'.length);
  }

  useEffect(() => {
    if (!email) {
      return;
    }
    let cancelled = false;
    setOrgLoading(true);
    setUser(null);
    organizationMember(email)
      .then(user => {
        if (cancelled) {
          return;
        }

        setUser(user);
      })
      .catch((err) => {
        toast('Unable to view the user details - ' + err.message, {
          intent: 'danger'
        });
        navigate({
          hash: ''
        }, {
          replace: true
        });
      })
      .finally(() => setOrgLoading(false));

    return () => {
      cancelled = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  if (!email || !teams) {
    return;
  }


  const handleClose = () => {
    navigate({
      hash: ''
    });
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      hideBackdrop
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1
        }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
        >
          <CloseIcon
            fontSize="small"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          width: 350,
          mb: 2,
          pb: 0
        }}
      >
        {isOrgLoading ?
          <UserDialogContentProgress
            email={email}
          />
          : <UserDialogContent
            email={email}
            user={user}
            teams={teams}
          />
        }

      </DialogContent>
    </Dialog>
  );
};


/**
 * 
 * @param {object} props
 * @param {string} props.email
 * @param {object=} props.user
 * @param {{[x: string]: TeamObjectType}} props.teams
 * @returns 
 */
const UserDialogContent = ({
  email,
  user,
  teams
}) => {
  const teamsList = teams ? Object.values(teams) : [];
  if (!user) {
    user = {
      email
    };
  }
  const userTeams = teamsList.filter(({ permissions }) => {
    return permissions[`u:${user.uid}`] || permissions[`e:${user.email}`];
  });
  const [hideTeams, setHideTeams] = useState(false);
  return (
    <>
      <Avatar
        src={user.imageUrl}
        sx={{
          width: 150,
          height: 150,
          mx: 'auto'
        }}
      />
      {!user.uid && (
        <Alert
          color="warning"
          sx={{
            mt: 2,
            justifyContent: 'center'
          }}
          icon={false}
        >
          This user is not part of your organization.
        </Alert>
      )}
      <Box
        sx={{
          mt: 2,
          textAlign: 'center'
        }}
      >
        {user.name && <div>
          {user.name}
        </div>}
        <div>{user.email}</div>
      </Box>
      {!!userTeams?.length && (<>
        <Box
          display="flex"
          mt={4}
        >
          <IconButton
            size="small"
            sx={{
              pr: 2
            }}
            onClick={() => setHideTeams(v => !v)}
          >
            <ExpandMoreIcon
              fontSize="small"
              sx={[
                {
                  transition: 'transform .5s'
                },
                hideTeams && {
                  transform: 'rotate(-90deg)'
                }
              ]}
            />
          </IconButton>
          <T
            variant="h6"
            sx={{
              flex: 1
            }}
          >Teams: </T>
        </Box>
        <Collapse
          in={!hideTeams}
        >
          <List>
            {userTeams.map(team => (
              <ListItemButton
                key={team.id}
                component={Link}
                to={`/business/teams/${team.id}`}
                sx={{
                  p: 0
                }}
              >
                <ListItem disablePadding>
                  <ListItemIcon
                    sx={{
                      pl: '5px',
                      pr: 2,
                      minWidth: 0
                    }}
                  >
                    <TeamsIcon />
                  </ListItemIcon>
                  <Tooltip
                    title={team.name}
                    placement="bottom-start"
                    disableInteractive

                  >
                    <ListItemText
                      primaryTypographyProps={{
                        sx: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          mr: 5
                        }
                      }}
                    >
                      {team.name}
                    </ListItemText>
                  </Tooltip>
                </ListItem>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </>)}
    </>
  );
};

/**
 * 
 * @param {object} props
 * @param {string} props.email
 * @returns 
 */
const UserDialogContentProgress = ({
  email
}) => {
  return (
    <>
      <Avatar
        sx={{
          width: 150,
          height: 150,
          mx: 'auto'
        }}
      />
      <Box
        sx={{
          mt: 2,
          textAlign: 'center'
        }}
      >
        <Skeleton
          variant="rectangular"
          height={20}
          width={150}
          sx={{
            mx: 'auto'
          }}
        />
        <div>{email}</div>
      </Box>
      <Box
        display="flex"
        mt={4}
      >
        <IconButton
          size="small"
          sx={{
            pr: 2
          }}
          disabled
        >
          <ExpandMoreIcon
            fontSize="small"
            sx={[
              {
                transition: 'transform .5s'
              }
            ]}
          />
        </IconButton>
        <T
          variant="h6"
          sx={{
            flex: 1
          }}
        >Teams: </T>
      </Box>
      <List>
        {Array.from({ length: 3 }).map((_, index) => (
          <ListItem disablePadding key={index}>
            <ListItemIcon
              sx={{
                pl: '5px',
                pr: 2,
                minWidth: 0
              }}
            >
              <TeamsIcon />
            </ListItemIcon>
            <Skeleton
              variant="rectangular"
              height={24}
              width={[120, 220, 160][index]}
              sx={{
                my: '4px'
              }}
            />
          </ListItem>
        ))}
        
      </List>
    </>
  );
};