import React, { useState } from 'react';
import T from '@mui/material/Typography';
import { getKeyboardShortcutFromExtension, sendToExtension } from '../../extension';
import { CURRENT_PLATFORM, DESKTOP_PROTOCOL, isElectronApp } from '../../flags';
import { isAiBlaze } from '../../aiBlaze';
import useOnMount from '../../hooks/useOnMount';

const EXT_MATCHER_TEXT = isAiBlaze ? 'AI%20Blaze' : 'Text%20Blaze';
// chrome://extensions link works on Chrome/Edge/Opera/Vivaldi/Brave
const LINK_TO_CHANGE_SHORTCUT = isElectronApp() ? `${DESKTOP_PROTOCOL}://tbsite//configure/options/#:~:text=assistant%20shortcut` : 'chrome://extensions/shortcuts#:~:text=' + EXT_MATCHER_TEXT;

/**
 * @param {{ children: JSX.Element | string, greyed?: boolean, }} props
 */
export function ExtensionShortcutChangeLink(props) {

  /** @type {React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>} */
  const extraProps = {};
  
  if (CURRENT_PLATFORM === 'browser') {
    extraProps.onClick = () => {
      sendToExtension({ type: 'openLink', url: LINK_TO_CHANGE_SHORTCUT });
    };
  }
  if (props.greyed) {
    extraProps.style = { color: 'grey' };
  }
  return <a href={LINK_TO_CHANGE_SHORTCUT} title="Click to change shortcut" {...extraProps}>{props.children}</a>;
}

export function ExtensionShortcut() {
  const [shortcut, setShortcut] = useState(null);

  useOnMount(() => {
    async function updateShortcut() {
      const shortcut = await getKeyboardShortcutFromExtension();
      setShortcut(shortcut);
    }
    function focusListener() {
      updateShortcut();
    }
    // Set once on mount
    updateShortcut();
    // Update each time user switches to chrome://extensions page
    // and updates the shortcut, and then comes back
    window.addEventListener('focus', focusListener); 
    return () => {
      window.removeEventListener('focus', focusListener);
    };
  });

  if (shortcut === null) {
    return null;
  }

  return <>
    <T
      gutterBottom
      variant="h6"
      sx={{
        mt: 4
      }}
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
    Chrome extension sidebar shortcut
    </T>
    <T paragraph style={{ marginBottom: 20 }}>
      {shortcut ? 'Your shortcut is ' + shortcut.replace('.', '+Period') + '. ' : 'No shortcut is set. '}
      <ExtensionShortcutChangeLink>Click here</ExtensionShortcutChangeLink> to {shortcut ? 'change' : 'set'} it.
    </T>
  </>;
}