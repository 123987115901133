import { getIdToken } from 'firebase/auth';
import { login } from './cpapi';
import { deleteCookie, getAuthCookie } from './flags';

const dayInMs = 24 * 60 * 60 * 1000;

/**
 * @param {import('firebase/auth').User} user
 */
export async function loginAuthSession (user) {
  const cookieData = getSessionCookie();
  // Cookie not exists or not created within last 24 hrs.
  if (
    cookieData?.created
    && (Date.now() - cookieData.created) < dayInMs
  ) {
    return;
  }

  const idToken = await getIdToken(user);
  try {
    await login(idToken);
  } catch {
    // failed for some reason. Ignore.
  }
}

export function getSessionCookie() {
  const sessionCookie = getAuthCookie();
  if (!sessionCookie) {
    return null;
  }

  return JSON.parse(decodeURIComponent(sessionCookie));
}

export function deleteSessionCookie() {
  deleteCookie('__session');
}