import { APP_TARGET } from './raw_flags';

export const isAiBlaze = APP_TARGET === 'AI';
export const snippetOrPrompt = isAiBlaze ? 'prompt' : 'snippet';

/**
 *
 * @param {import('@store').UserStateDef} userState
 * @return {boolean}
 */
export function wasOnboardedWithAiBlaze(userState) {
  return !!userState?.options?.was_onboarded_with_ai_blaze;
}

// TopBar background comes from the MUI Theme, but we override it for AI Blaze for fanciness points
export const aiBlazeBackgroundGradient = 'linear-gradient(to right, #ad64d7 0%, #c471f5 30%, #ed71fa 100%)';