import React, { useEffect, useRef, useState } from 'react';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import List from '@mui/material/List';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { VariableSizeList } from 'react-window';
import { Box } from '@mui/system';
import { highlightResults } from './search_utils';

const NUM_OF_VISIBLE_LIST_ITEMS = 6;
const ITEM_HEIGHT = 36;
const LIST_BOX_PADDING = 5;

/** @type {React.CSSProperties} */
const STYLE_INITIALS = {
  flex: '0 0 24px',
  fontWeight: 'bold',
  color: '#fff',
  backgroundColor: '#198dd6',
  borderRadius: '100%',
  marginLeft: 0,
  marginRight: -14,
  height: 24,
  lineHeight: '24px',
  fontSize: 12,
  width: 24,
  textAlign: 'center',
  zIndex: 0,
};



/** @type {React.CSSProperties} */
const STYLE_EMOJI = {
  flex: '0 0 24px',
  color: '#000',
  marginLeft: 0,
  marginRight: -14,
  height: 24,
  lineHeight: '24px',
  fontSize: '120%',
  width: 24,
  textAlign: 'center',
  zIndex: 0,
};

const STYLE_PHOTOS = {
  ...STYLE_INITIALS,
  backgroundColor: 'white',
};

function sendEntrySelectedToFrame(frame, selection) {
  frame.contentWindow.postMessage({
    type: 'ac_selected',
    data: { selection },
  }, TABLES_FRONT_END_DOMAIN);
}

const InnerElementType = React.forwardRef((props, ref) => {
  return <List dense ref={ref} {...props} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LIST_BOX_PADDING,
    },
  });
}

function getListHeight(itemCount) {
  return Math.min(itemCount, NUM_OF_VISIBLE_LIST_ITEMS) * ITEM_HEIGHT + 2 * LIST_BOX_PADDING;
}

/**
 * @param {object} props
 * @param {array} props.children array of items to render
 * @param {object} ref
 */
function ListBox(props, ref) {
  const { children } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;

  const gridRef = useResetCache(itemCount);

  return (
    <VariableSizeList
      itemData={itemData}
      height={getListHeight(itemCount)}
      width="100%"
      ref={gridRef}
      innerElementType={InnerElementType}
      outerElementType="div"
      outerRef={ref}
      itemSize={() => ITEM_HEIGHT}
      overscanCount={NUM_OF_VISIBLE_LIST_ITEMS}
      itemCount={itemCount}
    >
      {renderRow}
    </VariableSizeList>
  );
}

const ListBoxComponent = React.forwardRef(ListBox);

/**
 *
 * @typedef {Object} AcEntry
 * @property {string} label
 * @property {string?} photo
 * @property {'emoji'|'img'|'initials'} photoType
 */

/**
 *
 * @typedef {Object} AcRequest
 * @property {boolean?} loading
 * @property {AcEntry[]?} data
 * @property {string?} type
 * @property {string?} inputId
 * @property {string?} value
 */

/**
 * @param {Object} props
 * @param {React.RefObject<HTMLIFrameElement>} props.frame
 */
export default function AutoCompleteList(props) {
  let [acRequest, setAcRequest] = useState(/** @type {AcRequest} */ null);
  let [selectedIndex, setSelectedIndex] = useState(-1);
  let [rect, setRect] = useState({ left: 0, top: 0, width: 0, height: 0 });
  let [visible, setVisible] = useState(false);
  let scrollableListRef = useRef(null);

  /**
   * @param {AcRequest} acRequest
   */
  function setAutoCompleteData(acRequest) {
    setSelectedIndex(0);
    setAcRequest({
      ...acRequest,
      value: acRequest.value || '',
    });
  }

  useEffect(() => {
    const listener = async (event) => {
      if (event.origin !== TABLES_FRONT_END_DOMAIN) {
        return;
      }

      const type = event.data.type;
      const data = event.data.data;
      if (type === 'ac_show') {
        data.rect.top += document.getElementsByClassName('iframe-db')[0].getBoundingClientRect().y;
        setRect(data.rect);
        setVisible(true);
        setAutoCompleteData(data);
      } else if (type === 'ac_hide') {
        setVisible(false);
      } else if (type === 'ac_set') {
        setAutoCompleteData(data);
      } else if (type === 'ac_key') {
        if (visible && acRequest && acRequest.data?.length) {
          if (data.keyCode === 38) {
            // key: up
            setSelectedIndex(selectedIndex === -1 ? 0 : selectedIndex > 0 ? selectedIndex - 1 : acRequest.data.length - 1);
          } else if (data.keyCode === 40) {
            // key: down
            setSelectedIndex(selectedIndex === -1 ? 0 : selectedIndex < acRequest.data.length - 1 ? selectedIndex + 1 : 0);
          } else if (data.keyCode === 13 || data.keyCode === 9) {
            // keys: enter and tab
            selectEntry(acRequest.data[selectedIndex]);
          }
        }
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);

    // eslint-disable-next-line
  }, [props.frame, visible, selectedIndex, JSON.stringify(acRequest)]);

  useEffect(() => {
    if (selectedIndex !== -1) {
      placeSelectedItemInTheMiddle(selectedIndex);
    }
  }, [selectedIndex]);

  function placeSelectedItemInTheMiddle(index) {
    const amountToScroll = ITEM_HEIGHT * (index - NUM_OF_VISIBLE_LIST_ITEMS / 2 + 1);
    scrollableListRef.current.scrollTo(0, amountToScroll);
  }

  function selectEntry(entry) {
    setVisible(false);
    sendEntrySelectedToFrame(props.frame.current, {
      type: acRequest.type,
      label: entry.label,
      inputId: acRequest.inputId,
    });
  }

  function getListTop() {
    if (!acRequest) {
      // keep it out of view port to avoid flickering
      return -100000;
    }

    const listHeight = getListHeight(acRequest.loading ?
      // loading consumes one-entry height
      1 :
      acRequest.data.length
    );
    let top = rect.top - listHeight - 1;
    if (top < 0) {
      // flip the list
      top += listHeight + rect.height + 2;
    }

    return top;
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: rect.left,
        top: getListTop(),
        width: `${rect.width}px`,
        display: visible ? 'block' : 'none',
        backgroundColor: 'white',
        borderRadius: 2,
        border: '1px solid darkgray',
      }}
      translate="no"
    >
      <ListBoxComponent ref={scrollableListRef} children={!!acRequest && acRequest.loading ?
        <ListItem disablePadding sx={{ pointerEvents: 'none' }}>
          <ListItemButton>
            <ListItemText primary="Loading..." />
          </ListItemButton>
        </ListItem> : !!acRequest && !!acRequest.data && acRequest.data.map((entry, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton selected={index === selectedIndex} onMouseDown={(evt) => {
              if (!evt.button) {
                selectEntry(entry);
              }

              // don't trigger closing modals in tables
              evt.stopPropagation();
            }}>
              {!!entry.photo && <ListItemIcon sx={{
                minWidth: 32,
              }}>
                {entry.photoType === 'emoji' ?
                  <Box sx={STYLE_EMOJI}>
                    {entry.photo}
                  </Box> :
                  entry.photoType === 'img' ?
                    <img
                      alt={entry.label}
                      src={entry.photo}
                      style={STYLE_PHOTOS}
                    /> : entry.photoType === 'initials' ?
                      <Box sx={STYLE_INITIALS}>
                        {entry.photo}
                      </Box> : null}
              </ListItemIcon>}
              <ListItemText
                primary={highlightResults(entry.label, acRequest.value)}
                primaryTypographyProps={{
                  style: {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}/>
    </div>
  );
}
