import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialog } from '../DataDialog/DataDialog';
import Feedback from '../Feedback/Feedback';
import InnerMain from './InnerMain';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { Helmet } from 'react-helmet-async';
import { useIsPublicUrl } from '../../hooks/useIsPublicUrl';
import { DesktopOpenedDialog } from '../Desktop/DesktopOpenedDialog';
import InnerPublicSnippet from './InnerPublicSnippet';
import InnerPublicIntegration from './InnerPublicIntegration';
import DesktopNavigation from './DesktopNavigation';
import { NewUserUpgrade } from '../BillingDetails/NewUserUpgrade';
import { eagerlyLoadImport } from '../../utilities';
import LoadingProgressIndicator from '../Utilities/LoadingProgressIndicator.jsx';
import CommonDialogs from './CommonDialogs';
import useTokenCookie from '../../hooks/useTokenCookie';

const InnerPublicTemplateList = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateList')));
const InnerPublicTemplateView = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateView')));
const InnerPublicTemplateCategory = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateCategory')));
const InnerPublicTemplateUser = React.lazy(eagerlyLoadImport(() => import('../Templates/InnerPublicTemplateUser')));


function AppInner() {
  const isPublicUrl = useIsPublicUrl();
  useTokenCookie();
  return (
    <>
      <Helmet defer={false}>
        <title>Text Blaze Dashboard</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route exact path="/gallery/:id" render={() => <InnerPublicSnippet />} />
        <Route exact path="/integration/:id" render={() => <InnerPublicIntegration />} />
        <Route exact path="/:upgrade(upgrade_business|upgrade_pro)" component={NewUserUpgrade}/>

        <Route exact path="/templates" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateList />
        </Suspense>}/>
        <Route exact path="/templates/:slug" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateView />
        </Suspense>}/>
        <Route exact path="/templates/get/:id" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateView copyingPaidTemplate/>
        </Suspense>}/>
        <Route exact path="/templates/category/:category" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateCategory />
        </Suspense>}/>
        <Route exact path="/templates/user/@:handle" component={() => <Suspense fallback={<LoadingProgressIndicator />}>
          <InnerPublicTemplateUser />
        </Suspense>}/>

        <Route component={InnerMain} />
      </Switch>

      {!isPublicUrl && <DesktopNavigation />}
      {!isPublicUrl && <AuthDialog />}
      {!isPublicUrl && <DesktopOpenedDialog />}
      <Feedback />
      <CommonDialogs />
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default AppInner;
