import { Alert, IconButton, TextField, Tooltip, debounce } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from '../../message';
import { APP_PLATFORM_NAME, CURRENT_PLATFORM, isMacPlatform } from '../../flags';
import { useTypedSelector } from '../../hooks';
import useFirestore from '../../FirestoreLink/FirestoreHook';
import { makeRef } from '../../firebase_utilities';
import EditIcon from '@mui/icons-material/Edit';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { getDataFromClient } from '../../desktop_shared_utils';
import { isAiBlaze } from '../../aiBlaze';

const MAX_SHORTCUT_LEN = 5;
const ASSISTANT_SHORTCUT = `${APP_PLATFORM_NAME} ${isAiBlaze ? 'Sidebar' : 'Assistant'} shortcut`;

// Source: https://github.com/microsoft/vscode/blob/main/src/vs/base/common/keyCodes.ts
// Commit: 537676c15f47481296f15113f31d7adfde3102e4
let KEYCODE_TO_STR = {
  8: 'Backspace',
  9: 'Tab',
  13: 'Enter',
  16: 'Shift',
  17: 'Ctrl',
  18: 'Alt',
  20: 'Capslock',
  27: 'Escape',
  32: 'Space',
  33: 'PageUp',
  34: 'PageDown',
  35: 'End',
  36: 'Home',
  37: 'Left',
  38: 'Up',
  39: 'Right',
  40: 'Down',
  45: 'Insert',
  46: 'Delete',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  65: 'A',
  66: 'B',
  67: 'C',
  68: 'D',
  69: 'E',
  70: 'F',
  71: 'G',
  72: 'H',
  73: 'I',
  74: 'J',
  75: 'K',
  76: 'L',
  77: 'M',
  78: 'N',
  79: 'O',
  80: 'P',
  81: 'Q',
  82: 'R',
  83: 'S',
  84: 'T',
  85: 'U',
  86: 'V',
  87: 'W',
  88: 'X',
  89: 'Y',
  90: 'Z',
  96: 'num0',
  97: 'num1',
  98: 'num2',
  99: 'num3',
  100: 'num4',
  101: 'num5',
  102: 'num6',
  103: 'num7',
  104: 'num8',
  105: 'num9',
  106: 'nummult',
  107: 'numadd',
  109: 'numsub',
  110: 'numdec',
  111: 'numdiv',
  112: 'F1',
  113: 'F2',
  114: 'F3',
  115: 'F4',
  116: 'F5',
  117: 'F6',
  118: 'F7',
  119: 'F8',
  120: 'F9',
  121: 'F10',
  122: 'F11',
  123: 'F12',
  186: ';',
  187: '=',
  188: ',',
  189: '-',
  190: '.',
  191: '/',
  192: '`',
  219: '[',
  220: '\\',
  221: ']',
  222: '\''
};

/**
 * 
 * @param {{label: string}} param0 
 * @returns 
 */
const KeyCap = ({ label }) => {
  return <kbd style={{
    boxSizing: 'content-box',
    fontFamily: 'sans-serif',
    fontSize: '0.9em',
    whiteSpace: 'nowrap',
    backgroundColor: '#EEE',
    padding: '0.4em',
    border: 'solid 0.2em',
    borderTopColor: '#DDD',
    borderRightColor: '#BBB',
    borderBottomColor: '#BBB',
    borderLeftColor: '#DDD'
  }}>{label}</kbd>;
};

const MODIFIER_KEYS = {
  Shift: '⇧',
  Control: 'Ctrl',
  Alt: (isMacPlatform() ? '⌥' : 'Alt'),
  Meta: (isMacPlatform() ? '⌘' : 'Win')
};

function getDefaultShortcut() {
  switch (CURRENT_PLATFORM) {
  case 'mac':
    return isAiBlaze ? ['Meta', '.'] : ['Meta', 'Shift', '/'];
  case 'windows':
    return isAiBlaze ? ['Control', '.'] : ['Control', 'Shift', '/'];
  default:
    return [];
  }
}

/**
 * 
 * @param {string} message 
 * @param {"warning" | "info" | "success" | "danger"} type 
 */
function showToast(message, type = 'info') {
  toast(message, {
    duration: 4000,
    intent: type
  });
};

const propertyKey = `${CURRENT_PLATFORM}_${isAiBlaze ? 'sidebar' : 'assistant'}_shortcut`;

export function AssistantShortcut() {
  let uid = useTypedSelector(store => store.userState.uid);
  
  let { data, updateFn } = useFirestore(makeRef('users_settings', uid));
  let shortcut = data?.options?.[propertyKey] ?? getDefaultShortcut();

  const [parts, setParts] = useState([]);
  const partsRef = useRef([]);
  const [open, setOpen] = useState(false);
  const [assistantShortcutFailure, setAssistantShortcutFailure] = useState('');

  const saveShortcut = () => {
    const newShortcut = [...partsRef.current];
    if (JSON.stringify(newShortcut) !== JSON.stringify(shortcut)) {
      if (newShortcut.length > MAX_SHORTCUT_LEN) {
        showToast(`You cannot use ${ASSISTANT_SHORTCUT} longer than ` + MAX_SHORTCUT_LEN);
      } else if (newShortcut.length === 0) {
        showToast(`Please provide a valid ${ASSISTANT_SHORTCUT}`);
      } else if ((new Set(newShortcut)).size !== newShortcut.length) {
        showToast(`You cannot use the same key twice in the ${ASSISTANT_SHORTCUT}`);
      } else if (!MODIFIER_KEYS[newShortcut[0]]) {
        showToast('First key should be a modifier. Please use one of the following modifier keys: ' + Object.values(MODIFIER_KEYS).join(', '));
      } else if (newShortcut.filter(k => !MODIFIER_KEYS[k]).length > 1) {
        showToast(`Only one non-modifier key is allowed in the ${ASSISTANT_SHORTCUT}`);
      } else if (!newShortcut.some(k => !MODIFIER_KEYS[k])) {
        showToast(`Please include a non-modifier key along with the modifier keys in ${ASSISTANT_SHORTCUT}`);
      } else {
        showToast(`Your ${ASSISTANT_SHORTCUT} '${newShortcut.map((key) => (MODIFIER_KEYS[key] ?? key)).join(' + ')}' has been saved successfully`, 'success');
        const updKey = `options.${propertyKey}`;
        updateFn({
          [updKey]: newShortcut
        });
        setOpen(false);
      }
    } else {
      showToast(`Inputted shortcut is same as existing ${ASSISTANT_SHORTCUT}`);
    }
    setParts([]);
    partsRef.current = [];
  };

  useEffect(() => {
    if (!open) {
      let ignore = false;
      const fetchData = () => {
        getDataFromClient({ type: 'assistant-shortcut' }).then((res) => {
          if (ignore) {
            return;
          }
          setAssistantShortcutFailure(res?.failure || '');
          setTimeout(fetchData, 1000);
        });
      };
      fetchData();
      return () => {
        ignore = true;
      };
    }
  }, [open]);

  /**
   * @type {React.KeyboardEventHandler<HTMLDivElement>}
   */
  const handleKeyDown = (event) => {
    // prevent default actions like bookmark
    // or address bar
    event.preventDefault();
    // prevent our own app from handling cmd+/
    event.stopPropagation();
    if (event.repeat || !event.key || !event.keyCode) {
      return;
    }
    let { keyCode, key } = event;
    key = MODIFIER_KEYS[key] ? key : KEYCODE_TO_STR[keyCode];
    if (!key) {
      showToast(`Pressed key is not allowed in the ${ASSISTANT_SHORTCUT}`);
      return;
    }
    setParts([...parts, key]);
    partsRef.current = [...partsRef.current,  key];
  };

  const handleBlur = () => {
    setParts([]);
    partsRef.current = [];
  };

  return <>
    {assistantShortcutFailure && <Alert severity="warning" title="Text" sx={{ mb: 2, display: 'inline-flex' }}>You can't use "{assistantShortcutFailure}" in a shortcut. Please change the shortcut or contact <a href="mailto:support@blaze.today">support@blaze.today</a> for assistance.</Alert>}
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!open ? (<><div style={{ marginRight: 10 }}>
        {shortcut.map((item, key) => (
          <Fragment key={key}>
            <KeyCap
              label={(MODIFIER_KEYS[item] ?? item)}
            />{ (key < shortcut.length - 1) ? ' + ' : ''}
          </Fragment>
        ))}
      </div>
      <Tooltip title="Edit shortcut">
        <IconButton aria-label="edit" size="medium" onClick={() => setOpen(true)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      </>) : 
        <>
          <TextField
            label="Type here"
            autoFocus
            variant="outlined"
            onBlur={handleBlur}
            value={parts.map((k) => (MODIFIER_KEYS[k] ?? k)).join(' + ')}
            onKeyDown={handleKeyDown}
            onKeyUp={debounce(saveShortcut, 300)}
            disabled={!data}
          />
          <Tooltip title="Cancel editing shortcut">
            <IconButton aria-label="cancel" size="medium" onClick={() => setOpen(false)}>
              <CloseOutlined />
            </IconButton>
          </Tooltip>
        </>
      }
    </div>
  </>;
}