import { AI_BACKEND_ENDPOINT, getIsWebpageEmbedded } from '../../flags';
import { captureException } from '@sentry/browser';
import { getCredentials } from './credentials';
import { EXTENSION_TYPE, sendToExtension } from '../../extension';
import { readFromStorage, writeToStorage } from '../AppMicro/load_data';
import { sendMessageToClient } from '../../desktop_utilities';
import { chatproto } from '../../chat_proto/ChatProto.js';
import { isElectronFlag } from '../../raw_flags';
import { getDataFromClient } from '../../desktop_shared_utils';
import { log } from '../../logging/logging';

/**
 * @typedef {{ content: string, role: 'user'|'assistant', modelOverride?: import('../../exported_types').ModelOverride }} ConversationMessageType
 */

export const IS_TEST_MODE = false;
export const isWebpageEmbedded = getIsWebpageEmbedded();
export const isLSEnabled = !isWebpageEmbedded;

export const WHITE_BG_COLOR = 'rgba(255,255,255, .8)';

/**
 * With localstorage, it persists across multiple days and 
 * gives incorrect counts (even when daily limit was reset)
 * So we just use a global variable which satisfies the original
 * intention (persist usage count when user opens/closes AI Write)
 */
let storedUsageCount = null;
/**
 * @returns {{ maxUsage: number, usage: number, actionType: 'upgrade'|'upgrade-paying'|''|'verify-email' }}
 */
export function getStoredUsageCount() {
  return storedUsageCount ? JSON.parse(storedUsageCount) : null;
}

/**
 * @param {ReturnType<typeof getStoredUsageCount>} data
 */
export function setStoredUsageCount(data) {
  storedUsageCount = JSON.stringify(data);
}

export function closePopup() {
  window.parent.postMessage({
    type: 'close'
  }, '*');
}

/**
 * @param {{ path: '/write', body: Omit<import('../../exported_types').WritePostDataType, 'idToken'>, controller: AbortController, }|{ path: '/chat', body: Omit<import('../../exported_types').ChatPostDataType, 'idToken'>, controller: AbortController, }|{ path: '/chatp', body: Omit<import('../../exported_types').ChatPostDataType, 'idToken'>, controller: AbortController, }|{ path: '/classify', body: Omit<import('../../exported_types').ClassifyPostDataType, 'idToken'>, }} requestObject
 * @returns {Promise<Response|{ error: any, }>}
 */
export async function makeBackendRequest(requestObject) {
  if (requestObject.path.endsWith('p')) {
    // @ts-ignore
    return makeBackendRequestP(requestObject);
  }
  const { path, body, } = requestObject;
  const signal = 'controller' in requestObject ? requestObject.controller.signal : undefined;
  const idToken = await getCredentials();

  if (body.domainData) {
    // None of these are needed for non-chat endpoints, ensure they aren't sent
    body.domainData = Object.assign({}, body.domainData);

    // @ts-ignore
    delete body.domainData.rawCapturedImage;
    // @ts-ignore
    delete body.domainData.rawImageType;
    // @ts-ignore
    delete body.domainData.pageContent;
  }

  return fetch(AI_BACKEND_ENDPOINT + path, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain'
    },
    body: JSON.stringify({
      idToken,
      ...body,
    })
  }).catch(error => {
    captureException(error);
    return { error, };
  });
}

/**
 * @param {{ path: '/chatp', body: Omit<import('../../exported_types').ChatPostDataType, 'idToken'>, controller: AbortController, }} requestObject
 * @returns {Promise<Response|{ error: any, }>}
 */
export async function makeBackendRequestP(requestObject) {
  const { path, body, } = requestObject;
  const signal = 'controller' in requestObject ? requestObject.controller.signal : undefined;
  const idToken = await getCredentials();



  let data = {
    idToken,
    ...body,
  };

  if (data.domainData) {
    data.domainData = Object.assign({}, data.domainData);
    // @ts-ignore
    if (data.domainData.rawCapturedImage) {
      // @ts-ignore
      data.domainData.rawCapturedImage = new Uint8Array(data.domainData.rawCapturedImage);
    }
  }

  let message = chatproto.Request.create(data);
  let array = chatproto.Request.encode(message).finish();

  if (array.length > 300 * 1024) {
    // 400Kb is the max response on the server but we occasionally
    // see longer data.
    // Log if we ever exceed 300kb.
    // (this should never happen, when it does figure out why and fix the issue)

    log({
      action: 'AI Chat Length Exceeded',
      label: {
        total_length: array.length,
        domain_data: Object.fromEntries(Object.keys(data.domainData).map(key => [key, (typeof data.domainData[key]?.length === 'number') ? data.domainData[key].length : 'UNKNOWN'])),
        messages: JSON.stringify(data.messages || '').length,
        messages_array_length: data.messages?.length,
        id_token: data.idToken?.length
      }
    });

  }

  // we use a form upload so we don't have a CORS pre-flight request.
  const blob = new Blob([array], { type: 'application/octet-stream' });
  const formData = new FormData();
  formData.append('chat_request', blob, 'tmp.txt');
  
  return fetch(AI_BACKEND_ENDPOINT + path, {
    signal,
    method: 'POST',
    body: formData
  }).catch(error => {
    captureException(error);
    return { error, };
  });
}

/**
 * 
 * @param {{ domainData: import('../../exported_types').AIHostDataType, messages: object[], platform?: SupportedPlatformNames, }} param0 
 * @returns 
 */
export async function makeClassificationRequest({ domainData, messages, platform, }) {
  // Classify the user input as the action is not already available
  return makeBackendRequest({
    path: '/classify', body: {
      domainData,
      messages,
      platform,
    }
  }).then(async response => {
    if ('error' in response) {
      return response;
    }
    /** @type {import('../../exported_types').ClassifyReturnDataType} */
    const result = await response.json();
    return result;
  });
}

/**
 * @param {import('../AppEmbed/App').AIModelType} newModel 
 * @returns 
 */
export function saveAIModelToClient(newModel) {
  if (isElectronFlag) {
    // @ts-ignore
    // TODO: Remove this when all AI Blaze desktop users are on v0.0.3 or later
    sendMessageToClient({ type: 'autowrite', subType: 'saveModel', model: newModel, });
    sendMessageToClient({ type: 'route-to-checker', message: { type: 'autowrite', subType: 'saveModel', model: newModel, } });
  } else {
    sendToExtension({ type: 'autowrite', subType: 'saveModel', model: newModel, });
  }
}

/**
 * @returns {Promise<{ model: import('../AppEmbed/App').AIModelType }>}
 */
export function getAIModelFromClient() {
  return isElectronFlag ? getDataFromClient({ type: 'autowrite', subType: 'getModel' }) : sendToExtension({ type: 'autowrite', subType: 'getModel' });
}

/**
 * 
 * @param {import('../AppEmbed/App').AIModelType} model
 * @returns {import('../AppEmbed/App').AIModelType} 
 */
export function getAIModelOrDefault(model) {
  if (model !== undefined) {
    return model;
  } else {
    // For first time user, the model will be undefined, so we set the appropriate default here
    const defaultModel = EXTENSION_TYPE === 'ai' ? 'sonnet' : 'gpt3.5';
    const newModel = /** @type {import('../AppEmbed/App').AIModelType} */ ({ name: defaultModel, supportsImage: defaultModel === 'sonnet', });
    // Write to client so it can be used next time
    saveAIModelToClient(newModel);
    return newModel;
  }
}

export const SIDEBAR_CONFIG_DATA_STORAGE_KEY = 'sidebarConfigData';

/**
 * @typedef {{ triggers: { prompt: string, action: SnippetActionType }[] }} SidebarStoredConfigData
 * 
 * @returns {Promise<SidebarStoredConfigData>}
 */
export async function getSidebarStoredData() {
  /** @type {SidebarStoredConfigData} */
  const config = (await readFromStorage(SIDEBAR_CONFIG_DATA_STORAGE_KEY)) || {};
  if (!config.triggers) {
    config.triggers = [];
  }
  return config;
}

/**
 * @param {SidebarStoredConfigData['triggers']} triggers 
 */
export async function storeUpdatedSidebarTriggers(triggers) {
  const config = await getSidebarStoredData();
  config.triggers = triggers;
  await writeToStorage(SIDEBAR_CONFIG_DATA_STORAGE_KEY, config);
}

/**
 * @param {SidebarStoredConfigData['triggers']} triggers 
 * @param {string} prompt 
 */
export function addNewTriggerIntoList(triggers, prompt) {
  if (triggers.length > 1) {
    triggers.pop();
  }
  triggers.unshift({ prompt: prompt, action: undefined, });
  return triggers;
}