import React, { useEffect, useRef, useState } from 'react';
import { useIsMounted, useTypedSelector } from '../../hooks';
import CircularProgress from '@mui/material/CircularProgress'; 
import { log } from '../../logging/logging';
import { showConfirm, toast } from '../../message';
import { listPermissions } from './utilities';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import { EmptyState } from '../EmptyState/EmptyState';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { orgLoadedIfNeeded } from '../../flags';

import AppLink from '../Utilities/AppLink';
import Button from '@mui/material/Button';
import UserLink from '../Utilities/UserLink';

// Default count of items for the permission list.
// If the number of items is less than this the pager/search
// won't be shown.
const DEFAULT_COUNT = 25;

/**
 * @type {import('@mui/material').ButtonProps['sx']}
 */
const linkSx = {
  p: 0,
  verticalAlign: 'inherit',
  display: 'contents',
  textDecoration: 'underline'
};


function PermLoader() {
  return <div style={{ marginTop: 30, marginBottom: 30, justifyContent: 'center', display: 'flex' }}>
    <CircularProgress size={120} thickness={1.9} />
  </div>;
}

/**
 * @typedef PermissionListProps
 * @type {object}
 * @property {'group'|'org'|'database'|'site'} props.type
 * @property {PermissionsType} props.permissions
 * @property {string} props.entityId
 * @property {string} props.userHeader
 * @property {string} props.permissionHeader
 * @property {string} props.access
 * @property {string[]} props.accesses
 * @property {{shouldShow: boolean, headerRenderer: () => React.ReactNode, cellRenderer: (grant: import("./utilities").Permission) => JSX.Element, isLast?: boolean}[]=} props.extraColumns
 * @property {function(string, string): Promise} props.onRemove
 * @property {function(string, { type: string }): Promise} props.onChange
 * @property {boolean=} props.filterActivated
 * @property {React.ReactNode[]=} props.filterMenuItems
 */

/**
 * @param {PermissionListProps} props 
 */
function PermissionListBase(props) {
  let orgDataHasLoaded = useTypedSelector(store => orgLoadedIfNeeded(store));

  // we wait for org data to load if we are in an org as we want to be able
  // to display the org name in the permissions list
  if (!orgDataHasLoaded) {
    return <PermLoader />;
  }

  return <PermissionListInner {...props} />;
}


/**
 * @param {PermissionListProps} props 
 */
function PermissionListInner(props) {
  let [loading, setLoading] = useState(true);
  let [grants, setGrants] = useState(/** @type {import('./utilities').Permission[]} */ (null));
  let [page, setPage] = useState(0);
  let [pageSize, setPageSize] = useState(DEFAULT_COUNT);
  let [searchString, setSearchString] = useState(null);
  let [pendingChanges, setPendingChanges] = useState(/** @type {Object<string, 'owner'|'editor'|'viewer'>} */{});
  let [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);

  let userEmail = useTypedSelector(store => store.userState.email);
  let orgOwnerGrants = useRef(/** @type {Awaited<ReturnType<listPermissions>>} */ (null));
  let [notOrgEmails, setNotOrgEmails] = useState(new Set());
  const orgPermissions = useTypedSelector(store => store.orgState?.org?.permissions);

  let isMounted = useIsMounted();


  useDeepCompareEffect(() => {
    listPermissions(props.permissions).then((grants) => {
      if (isMounted.current) {
        setGrants(grants);
        setLoading(false);
      }
    });
    // We only want to update when they are actually different.
  }, [props.permissions]);

  useEffect(() => {
    let canceled = false;
    if (!orgPermissions || !grants) {
      setNotOrgEmails(new Set());
      return;
    }
    (async () => {

      const newNotOrgEmails = new Set();
      for (const grant of grants) {
        if (grant.entityType !== 'user') {
          continue;
        }

        // Check if orginal u:uid/e:email is part of permission
        if (orgPermissions[grant.original]) {
          continue;
        }

        // Check if email exists on the org permission directly (folder creator)
        // This is mostly if this folder creator (u:uid) and not org creator
        if (orgPermissions[`e:${grant.entityLabel}`]) {
          continue;
        }

        // If orgOwners are already evaluated. Ignore.
        // If orgowner has been changed, then the component or page needs to be refreshed manually.
        if (!orgOwnerGrants.current) {
          /**
           * @type {typeof orgPermissions}
           */
          let userIdPerms = {};
          let needFetching = false;

          // Filter all org owners
          for (const perm in orgPermissions) {
            if (!perm.startsWith('u:')) {
              continue;
            }
            userIdPerms[perm] = orgPermissions[perm];
            needFetching = true;
          }
          if (needFetching) {
            // Fetch email of the org owner
            orgOwnerGrants.current = await listPermissions(userIdPerms);
          }
          if (canceled) {
            return;
          }
        }

        
        let ownerGrant = orgOwnerGrants.current ? orgOwnerGrants.current.find(g => g.entityLabel === grant.entityLabel) : null;
        if (
          ownerGrant
          // check if owner is still part of org
          && orgPermissions[ownerGrant.original]
        ) {
          continue;
        }

        // Email does not exist anywhere. Lets mark it non org
        newNotOrgEmails.add(grant.entityLabel);
      }

      setNotOrgEmails(newNotOrgEmails);
    })();
    return () => {
      canceled = true;
    };
  }, [grants, orgPermissions]);


  function logEvent(data) {
    let customDimensions = {};

    if (props.type === 'group') {
      customDimensions.group_id = props.entityId;
    }

    log(data, customDimensions);
  }


  if (loading) {
    return <PermLoader />;
  } else {
    let filteredGrants = grants.map(grant => {
      let name, label;
      if (grant.entityType === 'user') {
        name = grant.entityLabel;
        label = name;
      } else if (grant.entityType === 'team') {
        name = 'Team: ' + grant.entityLabel;
        label = <span><span style={{ opacity: 0.6 }}>Team:</span> {grant.entityLabel}</span>;
      } else if (grant.entityType === 'group') {
        name = 'Folder: ' + grant.entityLabel;
        label = <span><span style={{ opacity: 0.6 }}>Folder:</span> <Button sx={linkSx} component={AppLink} color="inherit" appType="TEXT" to={`/folder/${grant.entityValue}/connected`} target="_blank">{grant.entityLabel}</Button></span>;
      } else if (grant.entityType === 'site') {
        name = 'Site: ' + grant.entityLabel;
        label = <span><span style={{ opacity: 0.6 }}>Site:</span> <Button sx={linkSx} component={AppLink} color="inherit" appType="PAGE" to={`/site/${grant.entityValue}/connected`} target="_blank">{grant.entityLabel}</Button></span>;
      } else if (grant.entityType === 'org') {
        name = 'Organization: ' + grant.entityLabel;
        label = <span><span style={{ opacity: 0.6 }}>Organization:</span> {grant.entityLabel}</span>;
      } else if (grant.entityType === 'public') {
        name = 'Everyone';
        label = <span><span style={{ opacity: 0.6 }}>Everyone</span></span>;
      }
      
      return {
        name,
        label,
        grant,
        showOrgWarning: (
          props.type !== 'org'
          && userEmail !== label
          && !!notOrgEmails.size
          && notOrgEmails.has(label)
        )
      };
    });

    if (searchString) {
      let search = searchString.toLowerCase();
      filteredGrants = filteredGrants.filter(x => x.name.toLowerCase().includes(search));
    }

    /**
     * 
     * @param {import("./utilities").Permission} grant 
     * @param {string} prefix 
     */
    const removeTooltip = (grant, prefix) => {
      switch (grant.entityType) {
      case 'user':
        return <>{prefix}<b>{grant.entityLabel}</b>'s access</>;
      case 'group':
        return `${prefix}this group's access`;
      case 'org':
        return `${prefix}this organization's access`;
      case 'team':
        return `${prefix}this team's access`;
      default:
        return `${prefix}this access`;
      }
    };

    return <>
      <Table
        style={{ width: '100%' }}
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                pl: 2,
                pr: 0
              }}
            ></TableCell>
            <TableCell
              sx={{
                pl: 0
              }}
            >{props.userHeader}</TableCell>
            {props.extraColumns ? props.extraColumns.filter(x => x.shouldShow && !x.isLast).map((c, i) => <TableCell key={i}>
              {c.headerRenderer()}
            </TableCell>) : null}
            <TableCell>{props.permissionHeader}</TableCell>
            {props.extraColumns ? props.extraColumns.filter(x => x.shouldShow && x.isLast).map((c, i) => <TableCell key={i}>
              {c.headerRenderer()}
            </TableCell>) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredGrants.slice(page * pageSize, (page + 1) * pageSize).map(({ label, grant, showOrgWarning }) => {
            return (
              <TableRow key={grant.original}>
                <TableCell
                  sx={{
                    pl: 1,
                    pr: 0
                  }}
                >
                </TableCell>
                <TableCell sx={{ verticalAlign: 'middle', pl: 0 }}>
                  <Tooltip
                    title={<div>
                      <span>{label}</span>
                      {showOrgWarning && (
                        <span style={{ marginLeft: 6 }}>
                          is external to your organization.
                        </span>
                      )}
                    </div>}
                    enterDelay={showOrgWarning ? 700 : 2000}
                    enterNextDelay={showOrgWarning ? 700 : 2000}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={[{
                          maxWidth: 250,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'inline-block'
                        }, showOrgWarning && {
                          textDecorationLine: 'underline',
                          textDecorationStyle: 'wavy',
                          textDecorationColor: (theme) => theme.palette.warning.light,
                          textDecorationThickness: 0.8
                        }]}
                      >
                        <UserLink
                          email={label}
                          style={{
                            color: 'inherit'
                          }}
                          disabled={grant.entityType !== 'user'}
                        >{label}</UserLink>
                      </Box>
                    </div>
                  </Tooltip>
                </TableCell>
                {props.extraColumns ? props.extraColumns.filter(x => x.shouldShow && !x.isLast).map((c, i) => <TableCell key={i} style={{ verticalAlign: 'middle' }}>
                  {c.cellRenderer(grant)}
                </TableCell>) : null}
                <TableCell style={{ verticalAlign: 'middle', minWidth: 120 }}>
                  {(
                    (!props.access) || props.access === 'member' || props.access === 'viewer' || props.access === 'editor' || // Editors and Viewers can't edit
                    (grant.entityType === 'user' && grant.entityLabel === userEmail) || // Can't edit your own
                    (grant.entityType === 'public') // Can't edit public permission
                  ) ? <span style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      display: 'inline-block'
                    }}>{(props.type === 'org' && grant.type === 'owner') ? 'Administrator' : grant.type.replace(/\b\S/g, c => c.toUpperCase())}</span> :
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {(grant.entityType === 'group' || grant.entityType === 'site') ? <div style={{ flexGrow: 1 }} /> : <Select
                        variant="standard"
                        fullWidth
                        aria-label="select permission"
                        sx={{
                          '&:before': {
                            content: 'none'
                          }
                        }}
                        value={pendingChanges[grant.original] || grant.type}
                        onChange={e => {
                          // For group and org, they are part of AUDIT LOGS
                          let type = props.type;
                          if (type !== 'group' && type !== 'org') {
                            logEvent({ category: 'Sharing', action: 'Change ' + type + ' permissions' });
                          }

                          let newType = e.target.value;
                          setPendingChanges({ ...pendingChanges, [grant.original]: newType });

                          props.onChange(grant.original, {
                            type: newType
                          }).catch(function(error) {
                            toast(`Could not change access: ${error}.`, {
                              duration: 4000,
                              intent: 'danger'
                            });
                          }).finally(() => {
                            delete pendingChanges[grant.original];
                            setPendingChanges(Object.assign({}, pendingChanges));
                          });
                        }}
                      >
                        {props.accesses.map(access => {
                          if (access === 'Owner') {
                            if (props.access === 'owner') {
                              return <MenuItem value="owner" key="owner">{props.type === 'org' ? 'Administrator' : 'Owner'}</MenuItem>;
                            }
                          } else {
                            return <MenuItem value={access.toLowerCase()} key={access.toLowerCase()}>{access}</MenuItem>;
                          }
                          return null;
                        })}
                        
                      </Select>}
                      <Tooltip
                        title={removeTooltip(grant, props.type === 'org' ? 'Delete ' : 'Remove ')}
                      >
                        <IconButton
                          size="small"
                          sx={{ mt: 0, ml: 8 }}
                          onClick={() => {
                            showConfirm({
                              contents: <>
                                <span>Are you sure you want to {removeTooltip(grant, props.type === 'org' ? 'delete ' : 'remove ')}?</span>
                                {props.type === 'org' && <span>&nbsp;Deletion cannot be undone.</span>}
                              </>,
                              onConfirm: () => {
                                // For group and org, they are part of AUDIT LOGS
                                let type = props.type;
                                if (type !== 'group' && type !== 'org') {
                                  logEvent({ category: 'Sharing', action: 'Remove ' + type + ' permission' });
                                }

                                return props.onRemove(grant.original, grant.entityLabel).catch(function(error) {
                                  toast(`Could not remove sharing permission: ${error}.`, {
                                    duration: 4000,
                                    intent: 'danger'
                                  });
                                });
                              },
                              confirmButtonIcon: <DeleteIcon style={{ opacity: 0.8 }}/>,
                              confirmButtonText: props.type === 'org' ? 'Delete' : 'Remove',
                              intent: 'danger'
                            });
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                </TableCell>
                {props.extraColumns ? props.extraColumns.filter(x => x.shouldShow && x.isLast).map((c, i) => <TableCell key={i} style={{ verticalAlign: 'middle' }}>
                  {c.cellRenderer(grant)}
                </TableCell>) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {!grants.length && !!props.filterMenuItems && <div style={{
        marginBottom: 18
      }}>
        <EmptyState
          icon="MISSING"
          title="No matches"
          description="No matches for the filter. Please change the filter."
        />
      </div>}
      {grants.length > 0 && filteredGrants.length === 0 && <div style={{
        marginBottom: 18
      }}>
        <EmptyState
          icon={ZoomOutIcon}
          title="No matches"
          description={`No matches for "${searchString}"`}
        />
      </div>}
      {(
        grants.length > DEFAULT_COUNT
        /** we keep it open if any of the pagination settings changed */
        || searchString
        || page
        || pageSize !== DEFAULT_COUNT
        || props.filterMenuItems?.length
      ) && <div style={{
        display: 'flex', 
        alignItems: 'center'
      }}>
        <div style={{
          flex: 1,
          paddingLeft: 10,
          display: 'flex',
          alignItems: 'center'
        }}>
          <TextField
            placeholder="Search"
            size="small"
            value={searchString || ''}
            onChange={(e) => {
              setSearchString(e.target.value);
              setPage(0);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{
                    opacity: .8
                  }} />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          {!!props.filterMenuItems?.length && (<>
            <Tooltip title="Filter">
              <IconButton
                color={props.filterActivated ? 'primary' : 'default'}
                aria-haspopup="true"
                aria-expanded={!!filterMenuAnchorEl ? 'true' : undefined}
                onClick={(evt) => {
                  setFilterMenuAnchorEl(evt.target);
                  setPage(0);
                }}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={filterMenuAnchorEl}
              open={!!filterMenuAnchorEl}
              onClose={() => {
                setFilterMenuAnchorEl(null);
                setPage(0);
              }}
              onClick={() => {
                setFilterMenuAnchorEl(null);
                setPage(0);
              }}
            >
              {props.filterMenuItems}
            </Menu>
          </>)}
        </div>
        <TablePagination
          labelRowsPerPage="Items Shown"
          rowsPerPageOptions={[DEFAULT_COUNT, 50, 100, 200]}
          component="div"
          style={{
            overflow: 'initial'
          }}
          count={filteredGrants.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} of ${count}${searchString ? ` (total of ${grants.length})` : ''}`}
          onRowsPerPageChange={(event) => {
            setPageSize(parseInt(event.target.value, 10));
            setPage(0);
          }}
          sx={{
            '& p': {
              'margin': 0
            }
          }}
        />
      </div>}
    </>;
  }
}


const PermissionList = React.memo(PermissionListBase);
export default PermissionList;