import React, { useState, useRef, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import T from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import { toStr } from '../../snippet_processor/Equation';
import { parseTimeShift } from '../../snippet_processor/time_shift';
import { toast, showDialog } from '../../message';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import {
  attributeListStringToObject,
  normalToEscaped,
  escapedToIntermediate,
  intermediateToNormal,
  getCommandsFromAttribute
} from '../../snippet_processor/ParserUtils';
import Chip from '@mui/material/Chip';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import FormulaEditor from '../FormulaEditor/FormulaEditor';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SnippetSelector from '../Messaging/SnippetSelector';
import Shortcut from '../Shortcut/Shortcut';
import SnippetAttributeEditor from '../FormulaEditor/SnippetAttributeEditor';
import Radio from '@mui/material/Radio';
import { sendToExtension } from '../../extension';
import { Box } from '@mui/system';
import './attribute_editors.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { functionKeys, standardKeys } from '../SnippetEditor/standardKeys';
import { WindowsChip, MacChip } from '../Version/VersionChip';
import { CURRENT_PLATFORM, isCommunityDocsOrBundle, isMacPlatform } from '../../flags';
import { DatabaseField } from './DatabaseField';
import { isValidDateOrTimeFormat } from '../../snippet_processor/Commands';
import SnippetLink from './SnippetLink';
import { useDesktopClientSupportsFeature } from '../../desktop_hooks';
import Checkbox from '@mui/material/Checkbox';
import { extractKeys } from './attributeeditor_util';
import { snippetOrPrompt } from '../../aiBlaze';


function cleanValue(val) {
  if (typeof val === 'string') {
    return intermediateToNormal(escapedToIntermediate(val));
  }
  return val;
}

function getDateTimeType(datetime) {
  if (datetime) {
    if (datetime.time) {
      if (datetime.date) {
        return 'datetime-local';
      }
      return 'time';
    }
  }
  return 'date';
}


/**
 * @param {object} props
 * @param {UserDefinedPropertyType & {isLegacyMenu?: boolean}} props.attribute
 * @param {boolean} props.suggestionBar - false if in dialog
 * @param {boolean=} props.noDescription
 * @param {boolean=} props.autoFocus
 * @param {boolean=} props.rawText
 * @param {object=} props.context
 * @param {object=} props.config
 * @param {any} props.value
 * @param {Function} props.onChange
 * @param {Function=} props.onError
 * @param {boolean=} props.isAdvanced - for lists
 * @param {boolean=} props.readonly
 */
export function AttributeEditor(props) {
  let attribute = props.attribute;

  if (!attribute.list) {
    if (props.suggestionBar) {
      return <BaseEditor {...props}/>;
    } else {
      return <div style={{ marginTop: 6 }}>
        <BaseEditor {...props}/>
      </div>;
    }
  } else {
    let isAdvanced = props.isAdvanced;
    let isLegacyMenu = attribute.isLegacyMenu;

    let data;
    let def = [];
    let selectedIndexes = new Set();
    if (isLegacyMenu) {
      data = props.value.map(x => x.value);
      for (let index = 0; index < props.value.length; index++) {
        const value = props.value[index];
        if (value.selected) {
          selectedIndexes.add(index);
        }
      }
    } else {
      let commands = getCommandsFromAttribute(props.value || '');

      /** @type {object[]} */
      data = attributeListStringToObject(props.value || '', attribute.list, commands);

      if (attribute.placeholder) {
        def = attributeListStringToObject(attribute.placeholder, attribute.list, commands);
      } else if (attribute.default) {
        def = attributeListStringToObject(attribute.default, attribute.list, commands);
      }
    }


    if (attribute.list === 'positional') {
      data = data.map(x => isAdvanced ? toStr(x) : intermediateToNormal(escapedToIntermediate(toStr(x)).trim()));
      let newData = data.slice();
      let isMultiple = props.attribute.config.isMultiple;
      let unselectable = attribute.list_type === 'unselectable';
      let saveData = () => {
        let pieces = newData.map((x, i) => {
          let res = (isAdvanced ? x : normalToEscaped(x, {
            escapeCommas: !isLegacyMenu,
            escapeCommands: true
          }));

          if (res[0] !== ' ') {
            if (i > 0) {
              // change in delimeter or space should be changed in all other list places
              res = ' ' + res;
            }
          }
          return res;
        });
        if (isLegacyMenu) {
          pieces = pieces.map((x,i) => ({
            value: x,
            selected: unselectable || selectedIndexes.has(i)
          }));
          props.onChange(pieces);
        } else {
          // change in delimeter or space should be changed in all other list places
          props.onChange(pieces.join(','));
        }
      };

      /**
       * Moves all the indexs up from the index
       * @param {number} removedIndex 
       */
      const removeIndex = (removedIndex) => {
        let newSelectedIndexes = new Set();
        for (const selectedIndex of selectedIndexes) {
          // Skip
          if (selectedIndex === removedIndex) {
            continue;
          }
          if (selectedIndex > removedIndex) {
            newSelectedIndexes.add(selectedIndex - 1);
          } else {
            newSelectedIndexes.add(selectedIndex);
          }
          
        }
        selectedIndexes = newSelectedIndexes;
      };
      
      return <div
        style={{
          marginTop: ((props.suggestionBar || props.noDescription) && !isAdvanced) ? 0 : 8,
        }}
        data-testid="positional-list-items"
      >
        {(props.suggestionBar || props.noDescription) ? null : <T color="textSecondary">{attribute.name}</T>}
        <div style={(props.suggestionBar || props.noDescription) ? undefined : { border: 'solid 1px #ccc', padding: 10, borderRadius: 8, marginTop: 8 }}>
          <DragDropContext onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }   

            let si = result.source.index;
            let di = result.destination.index;
            let moved = newData[si];
            const newSelectedIndexes = new Set();
            for (const selectedIndex of selectedIndexes) {
              if (selectedIndex === si) {
                newSelectedIndexes.add(di);
              } else if (selectedIndex > si && selectedIndex <= di) {
                newSelectedIndexes.add(selectedIndex - 1);
              } else if (selectedIndex < si && selectedIndex >= di) {
                newSelectedIndexes.add(selectedIndex + 1);
              } else {
                newSelectedIndexes.add(selectedIndex);
              }
            }
            selectedIndexes = newSelectedIndexes;
            newData.splice(si, 1);
            newData.splice(di, 0, moved);
            saveData();
          }}>
            <Droppable droppableId={'attribute_droppable_list_' + props.attribute.name} direction="vertical">
              {(provided,) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {data.map((entry, i) => (
                    <Draggable
                      isDragDisabled={props.readonly}
                      key={i}
                      draggableId={props.attribute.name + '_' + i}
                      index={i}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={Object.assign({
                            marginBottom: 6,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }, provided.draggableProps.style)}
                          key={i}
                        >
                          {isLegacyMenu && !unselectable && (
                            isMultiple ? <Checkbox
                              title="Default"
                              aria-label={`Make ${entry} default`}
                              size="small"
                              checked={selectedIndexes.has(i)}
                              onChange={e => {
                                if (e.currentTarget.checked) {
                                  selectedIndexes.add(i);
                                } else {
                                  selectedIndexes.delete(i);
                                }
                                saveData();
                              }}
                              disabled={props.readonly}
                            /> : <Radio
                              title="Default"
                              name="docs-radio-regular"
                              size="small"
                              checked={selectedIndexes.has(i)}         
                              onClick={selectedIndexes.size > 1
                                // Reset the selection if user chaged from multiple and has more than one default
                                ? _e => {
                                  selectedIndexes = new Set([i]);
                                  saveData();
                                } : null
                              }
                              onChange={_e => {
                                selectedIndexes = new Set([i]);
                                saveData();
                              }}
                              disabled={props.readonly}
                            />
                          )}

                          {isAdvanced ?
                            <div style={{
                              width: '100%'
                            }}>
                              <SnippetAttributeEditor
                                readonly={props.readonly}
                                value={entry}
                                onChange={(v) => {
                                  newData[i] = v;
                                  saveData();
                                }}
                                placeholder={def.length > i ? toStr(def[i]).trimStart() : ''}
                                context={props.context}
                                isList={!isLegacyMenu}
                                isLegacyMenu={isLegacyMenu}
                                attributeName={attribute.name}
                                // change in delimeter or space should be changed in all other list places
                                startIndex={i > 0 ? data.slice(0, i).map(d => d.trimStart()).join(', ').length + 2 : 0}
                              />
                            </div>
                            : <BaseEditor {...Object.assign({}, props, {
                              readonly: props.readonly,
                              suggestionBar: true,
                              value: entry,
                              skipCleaning: true,
                              default: def.length > i ? toStr(def[i]).trimStart() : '',
                              rawText: false,
                              onChange: (v) => {
                                newData[i] = intermediateToNormal(escapedToIntermediate(v));
                                saveData();
                              }
                            })} />}

                          {/* Add isMultiple after the Parser changes deployed in extension.
                            * Currently it does not allow user to add multiple default items.
                            */}
                          {!props.readonly && (!unselectable /*|| isMultiple*/ || data.length > 1) && <IconButton
                            size="small"
                            title="Remove"
                            style={{
                              opacity: 0.7,
                              marginLeft: 6
                            }}
                            onClick={() => {
                              removeIndex(i);
                              newData.splice(i, 1);
                              saveData();
                            }}
                          ><RemoveIcon fontSize="small" /></IconButton>}
                        </div>
                      )}
                    </Draggable>))}

                  {provided.placeholder}
                </div>)}
            </Droppable>

          </DragDropContext>

          {/* Add isMultiple after the Parser changes deployed in extension.
            * Currently it does not allow user to add multiple default items.
            */}
          {!props.readonly && (!unselectable /*|| isMultiple*/ || !data.length) && <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button
              size={props.suggestionBar ? 'small' : undefined}
              variant="outlined"
              onClick={() => {
                newData.push('');
                saveData();
              }}
              startIcon={<AddIcon style={{ opacity: 0.5 }} />}
            >Add</Button>
          </div>}
        </div>
        {(props.suggestionBar || props.noDescription) ? undefined : <T variant="caption" color="textSecondary" paragraph style={{ marginTop: 5, marginBottom: 0, paddingLeft: 12 }}>{attribute.description}</T>}
      </div>;
    } else if (attribute.list === 'keys') {
      data = data.map(x => isAdvanced ? [intermediateToNormal(escapedToIntermediate(toStr(x[0])).trim()), toStr(x[1])] : [intermediateToNormal(escapedToIntermediate(toStr(x[0])).trim()), intermediateToNormal(escapedToIntermediate(toStr(x[1])).trim())]);
      let newData = data.map(x => x.slice());
      let saveData = () => {
        props.onChange(newData.map((x, i) => {
          let res = normalToEscaped(x[0], {
            escapeCommas: true,
            escapeCommands: true
          }) + ':' + (isAdvanced ? x[1] : normalToEscaped(x[1], {
            escapeCommas: true,
            escapeCommands: true
          }));
          if (res[0] !== ' ') {
            if (i > 0) {
              res = ' ' + res;
            }
          }
          return res;
        }).join(','));
      };
      return <div style={{ marginTop: ((props.suggestionBar || props.noDescription) && !isAdvanced) ? 0 : 8 }} data-testid="keyed-list-items">
        {(props.suggestionBar || props.noDescription) ? null : <T color="textSecondary">{attribute.name}</T>}
        <div style={(props.suggestionBar || props.noDescription) ? undefined : { border: 'solid 1px #ccc', padding: 10, borderRadius: 8, marginTop: 8 }}>
          {data.map((entry, i) => (<div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }} key={i}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
              <span>
                <TextField
                  disabled={props.readonly}
                  size="small"
                  variant={(props.suggestionBar || props.noDescription) ? undefined : 'outlined'}
                  placeholder={def.length > i ? toStr(def[i][0]).trimStart() : ''}
                  value={entry[0]}
                  onChange={(e) => {
                    newData[i][0] = e.target.value;
                    saveData();
                  }}
                  fullWidth
                  sx={{
                    my: 1
                  }}
                />
              </span>
              <T style={{ marginLeft: 4, marginRight: 4 }}>:</T>
              <span style={{ flex: isAdvanced ? 1 : undefined }}>
                {isAdvanced ?
                  <div style={{
                    width: '100%',
                    minWidth: '120px'
                  }}>
                    <SnippetAttributeEditor
                      readonly={props.readonly}
                      value={entry[1]}
                      onChange={(v) => {
                        newData[i][1] = v;
                        saveData();
                      }}
                      placeholder={def.length > i ? toStr(def[i][1]).trimStart() : ''}
                      context={props.context}
                      isList
                      attributeName={attribute.name}
                      startIndex={props.value.indexOf(entry[1].trimStart(),  data.slice(0, i).reduce((acc, [key, value]) => {
                        let keyIndex = props.value.indexOf(key, acc) + key.length;
                        return props.value.indexOf(value, keyIndex) + value.length;
                      }, 0))}
                    />
                  </div> : <BaseEditor {...Object.assign({}, props, {
                    readonly: props.readonly,
                    suggestionBar: true,
                    value: entry[1],
                    skipCleaning: true,
                    default: def.length > i ? toStr(def[i][1]).trimStart() : '',
                    rawText: false,
                    onChange: (v) => {
                      newData[i][1] = intermediateToNormal(escapedToIntermediate(v));
                      saveData();
                    }
                  })} />
                }
              </span>
            </div>

            {!props.readonly && <IconButton
              size="small"
              title="Remove"
              style={{
                opacity: 0.7,
                marginLeft: 6
              }}
              onClick={() => {
                newData.splice(i, 1);
                saveData();
              }}
            ><RemoveIcon fontSize="small" /></IconButton>}
          </div>))}

          {!props.readonly && <div style={{ textAlign: 'right', marginBottom: props.suggestionBar ? 0 : 15, marginTop: 10 }}>
            <Button
              size={props.suggestionBar ? 'small' : undefined}
              variant="outlined"
              onClick={() => {
                newData.push(['', '']);
                saveData();
              }}
              startIcon={<AddIcon style={{ opacity: 0.5 }} />}
            >Add</Button>
          </div>}
        </div>
        {(props.suggestionBar || props.noDescription) ? undefined : <T variant="caption" color="textSecondary" paragraph style={{ marginTop: 5, marginBottom: 0, paddingLeft: 12 }}>{attribute.description}</T>}
      </div>;
    } else {
      throw new Error('Unknown list type: ' + attribute.list);
    }
  }
} 

/**
 * @typedef {object} EditorProps
 * @property {UserDefinedPropertyType} attribute
 * @property {any} value
 * @property {any=} default
 * @property {any=} placeholder
 * @property {boolean=} skipCleaning
 * @property {boolean=} rawText
 * @property {boolean=} suggestionBar
 * @property {boolean=} noDescription
 * @property {boolean=} autoFocus
 * @property {function} onChange
 * @property {function=} onError
 * @property {boolean=} readonly
 * @property {any=} context
 */


/**
 * @param {EditorProps} props
 */
function BaseEditor(props) {
  const [error, setError] = useState(null);
  let attribute = props.attribute;
  let config = attribute.config || {};
  let type = attribute.type;
  let value = props.value;

  useEffect(() => {
    if (type === 'datetime' && value) {
      let format = '';
      const updatedType = getDateTimeType(config.datetime);
      if (updatedType === 'datetime-local') {
        format = 'YYYY-MM-DD HH:mm';
      } else if (updatedType === 'date') {
        format = 'YYYY-MM-DD';
      } else {
        format = 'HH:mm';
      }
      if (moment(value, format, true).isValid()) {
        setError(null);
      } else {
        setError(`Invalid format for ${value}`);
      }
    }
  }, [value, type, config.datetime]);

  if (!props.skipCleaning && !props.rawText) {
    value = cleanValue(value);
  }

  let placeholder = props.default; // used in case of lists where we split up the default
  if (placeholder === undefined || placeholder === null) {
    if ('placeholder' in attribute) {
      placeholder = attribute.placeholder;
    } else if ('default' in attribute) {
      placeholder = attribute.default;
    }
  }

  const inputLabel = useRef(null);

  if (type === 'string') {
    return <StringField {...props} placeholder={placeholder} />;
  } else if (type === 'key') {
    return <KeyField {...props} placeholder={placeholder} />;
  } else if (type === 'selector') {
    attachListener();
    return <SelectorField {...props} onChange={({ pageMatched, selector }) => {
      props.onChange({ pageMatched, selector });
    }} placeholder={placeholder} />;
  } else if (type === 'shortcut') {
    return <ShortcutField {...props} placeholder={placeholder} />;
  } else if (type === 'numeric_format') {
    return <NumericFormatField {...props} placeholder={placeholder} />;
  } else if (type === 'time') {
    return <TimeLengthField {...props} placeholder={placeholder} />;
  } else if (type === 'database') {
    if (isCommunityDocsOrBundle()) {
      return <T variant="subtitle2" color="inherit" style={{ fontWeight: 'inherit' }}>
        The space used inside this bundle, this cannot be changed until you copy the snippet.
      </T>;
    } else {
      return <DatabaseField {...props} />;
    }
  } else if (type === 'time_format') {
    return <TimeFormatField {...props} placeholder={placeholder} />;
  } else if (type === 'number') {
    return <NumberField {...props} placeholder={placeholder} />;
  } else if (type === 'boolean') {
    if (value && ![false, true, 'yes', 'no'].includes(value)) {
      // fallback when the item is not a boolean
      return <FallBackField {...props} />;
    }

    let isYes = value === 'yes' || value === true;
    if (value === undefined) {
      if (attribute.default !== undefined) {
        isYes = attribute.default === 'yes' || attribute.default === true;
      }
    } 
    if (props.suggestionBar || props.noDescription) {
      return <div style={{ textAlign: 'center' }}>
        <T color="textSecondary" style={{ display: 'inline-block', paddingRight: 4 }}>No</T>
        <Switch
          disabled={props.readonly}
          checked={isYes}
          onChange={() => props.onChange(isYes ? 'no' : 'yes')}
          color="primary"
          autoFocus={props.autoFocus}
        />
        <T color="textSecondary" sx={{ mb: 1, display: 'inline-block', paddingLeft: 4 }}>Yes</T>
      </div>;
    }

    return <>
      <FormGroup sx={{ mb: 1.75 }}>
        <FormControlLabel
          control={
            <Switch
              checked={isYes}
              onChange={() => props.onChange(isYes ? 'no' : 'yes')}
              color="primary"
              autoFocus={props.autoFocus}
              disabled={props.readonly}
            />
          }
          label={attribute.name}
        />
        <T variant="caption" color="textSecondary">{attribute.description}</T>
      </FormGroup>
    </>;
  } else if (type === 'lambda' || type === 'equation' || type === 'bsql') {
    let isIterator = false;
    // the {repeat} times attribute
    if (attribute.name === 'times') {
      isIterator = true;
    }
    let isBlock = false;
    // 'code' -> {run}/{button} code block
    // ...others -> handlers in remote commands
    if (['code', 'finish', 'error', 'begin'].includes(attribute.name)) {
      isBlock = true;
    }
    return <div style={{ alignItems: 'top', maxWidth: '100%' }}>
      <div style={{ flex: 1 }}>
        {(props.suggestionBar || props.noDescription) ? undefined : <T variant="caption" color="textSecondary">{attribute.name}</T>}
        <FormulaEditor
          readonly={props.readonly}
          value={value || ''}
          onChange={(value) => props.onChange(value)}
          placeholder={placeholder}
          context={props.context}
          isIterator={isIterator}
          isBlock={isBlock}
          type={type === 'bsql' ? 'bsql' : 'formula'}
          attributeName={props.attribute.name}
        /> 
        {(props.suggestionBar || props.noDescription) ? undefined : <T variant="caption" color="textSecondary">{attribute.description}</T>}
      </div>
    </div>;
  } else if (type === 'select' || Array.isArray(type)) {
    let options;
    if (type === 'select') {
      options = attribute.config.options || [];
    } else {
      options = type;
    }

    let effectiveOptions = options;
    let effectiveValue = value || '';
    
    if (attribute.config && attribute.config.insensitive) {
      effectiveOptions = options.map(x => x.toLowerCase());
      effectiveValue = effectiveValue.toLowerCase();
    }

    if (effectiveValue && !effectiveOptions.includes(effectiveValue)) {
      // fallback when the item doesn't match something in the dropdown
      return <FallBackField {...props} />;
    }

    // Note that "native" is needed in suggestionBar mode otherwise the ClickAwayListener
    // in the attribute editor will trigger when the popup menu is clicked
    return (
      <FormControl size="small" variant="outlined" style={{ width: '100%' }}>
        {props.suggestionBar ? null : <InputLabel ref={inputLabel}>{attribute.name}</InputLabel>}
        <Select
          disabled={props.readonly}
          native={props.suggestionBar}
          value={effectiveValue}
          fullWidth
          onChange={(e) => {
            let value = e.target.value;
            let actualValue = options[effectiveOptions.indexOf(value)];
            if (!props.suggestionBar) {
              // this delay is for the docs site where
              // the scroll jumps wildy without it.
              setTimeout(() => props.onChange(actualValue), 5);
            } else {
              props.onChange(actualValue);
            }
          }}
          label={attribute.name}
        >
          {effectiveOptions.map((t, i) => props.suggestionBar ? <option key={t} value={t}>{options[i]}</option> : <MenuItem key={t} dense value={t}>{options[i]}</MenuItem>)}
        </Select>
        {(props.suggestionBar || props.noDescription) ? null : <FormHelperText>{attribute.description}</FormHelperText>}
      </FormControl>
    );
  } else if (type === 'identifier') {
    return <TextField
      disabled={props.readonly}
      label={(props.suggestionBar || props.noDescription) ? undefined : attribute.name}
      size="small"
      helperText={(props.suggestionBar || props.noDescription) ? undefined : attribute.description}
      variant={props.suggestionBar ? undefined : 'outlined'}
      placeholder={placeholder}
      value={value || ''}
      onChange={(e) => props.onChange(e.target.value)}
      fullWidth
      autoFocus={props.autoFocus}
      sx={{
        my: 1
      }}
    />;
  } else if (type === 'date') {
    return <TextField
      disabled={props.readonly}
      type="date"
      label={(props.suggestionBar || props.noDescription) ? undefined : attribute.name}
      size="small"
      helperText={(props.suggestionBar || props.noDescription) ? undefined : attribute.description}
      variant={props.suggestionBar ? undefined : 'outlined'}
      placeholder={placeholder}
      value={value || ''}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      fullWidth
      autoFocus={props.autoFocus}
      InputLabelProps={{ shrink: true }} 
      sx={{
        my: 1
      }}
    />;
  } else if (type === 'datetime') {
    return <TextField
      disabled={props.readonly}
      type={getDateTimeType(config.datetime)}
      error={!!error}
      label={error ? error : (props.suggestionBar || props.noDescription) ? undefined : attribute.name}
      size="small"
      helperText={(props.suggestionBar || props.noDescription) ? undefined : attribute.description}
      variant={props.suggestionBar ? undefined : 'outlined'}
      placeholder={placeholder}
      value={value || ''}
      onChange={(e) => {
        props.onChange(e.target.value.replace('T', ' '));
      }}
      fullWidth
      autoFocus={props.autoFocus}
      InputLabelProps={{ shrink: true }} 
      sx={{
        my: 1
      }}
    />;
  } else {
    console.error('Unknown type: ' + type);
    return null;
  }
}

function attachListener() {
  const KEY = 'tb-attached-beforeunload';
  if (window[KEY]) {
    return;
  }

  window[KEY] = true;
  window.addEventListener('beforeunload', function() {
    sendToExtension({ type: 'abortPicking' });
  });
}

/**
 * @param {EditorProps} props
 */
function SelectorField(props) {
  let [editing, setEditing] = useState(false);
  let [showError, setShowError] = useState(CURRENT_PLATFORM !== 'browser');

  /** @type {''|JSX.Element} */
  let tooltipTitle = '';
  if (showError) {
    tooltipTitle = <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 2,
      gap: '10px',
    }}>
      <T variant="body2">Install our browser extension to use this feature</T>
      <Button variant="contained" sx={{ flexShrink: 0 }} onClick={() => {
        window.open('https://chrome.google.com/webstore/detail/idgadaccgipmpannjkmfddolnnhmeklj');
      }}>Install now</Button>
    </Box>;
  }

  // Fix for this issue
  // https://spark.blaze.today/space/5p0NkY2CAYbgvDkpYsHh8o/table/3vAAsNAqEvgx4NYlskgIaD/row/17d4cqeMtYRuzCp1RNm6c2/
  const valueOfSelector = props.value;
  useEffect(() => {
    if (valueOfSelector === undefined) {
      setEditing(false);
    }
  }, [valueOfSelector]);

  const config = {
    isMultiple: !!props.attribute.config?.isMultiple,
    supportsPage: !!props.attribute.config?.supportsPage,
    needsClick: !!props.attribute.config?.needsClick,
    supportsCrossIframe: !props.attribute.config?.disableCrossIframe,
  };

  /**
   * @param {{ selector: string, pageMatched?: string }} newSelector 
   */
  function onChange(newSelector) {
    // saves the value across popup opens/closes
    props.onChange(newSelector);
  }

  function stopSelection() {
    sendToExtension({ type: 'stopPicking' });
  }

  function onClickHandler() {
    showDialog({
      title: <div style={{
        display: 'flex',
        alignItems: 'center'
      }}><FindInPageOutlinedIcon style={{ opacity: .6, marginRight: 12 }}/> Select text from a website</div>,
      contents: function(closeCallback) {
        sendToExtension({ type: 'startPicking', ...config }).then(function(response) {
          if (!response) {
            // User is on browser, but the extension is disabled/uninstalled
            closeCallback();
            setShowError(true);
            return;
          }
          closeCallback();
          const selector = typeof response === 'object' ? response.selector : response;
          // Older extension does not respect supportsPage flag, so we still need to check on this side
          const pageMatched = typeof response === 'object' && config.supportsPage ? response.pageMatched : undefined;
          if (!selector) {
            toast('Cancelled selection of website text', { intent: 'info', duration: 6000 });
          } else {
            toast('Website text selected and selector has been updated', { intent: 'success', duration: 6000 });
            onChange({ selector, pageMatched });
          }
        });
        return <>
          <T paragraph>
            In a new tab, open the website where you will use your snippet.
          </T>
          <T paragraph>
            And then click the part of the website you wish to {props.attribute.config.commandType} in the snippet!
          </T>
          <Box sx={{
            textAlign: 'right'
          }}>
            <Button onClick={() => {
              stopSelection();
              closeCallback();
            }}>Cancel</Button>
          </Box>
        </>;
      }, onClose: () => {
        stopSelection();
      }
    });
  }

  if (!editing) {
    return <><Box sx={{
      textAlign: 'center'
    }}>
      <Tooltip title={tooltipTitle}>
        <span>
          <Button
            variant="contained"
            sx={{
              margin: 1
            }}
            disabled={props.readonly || showError}
            startIcon={<FindInPageOutlinedIcon />}
            onClick={onClickHandler}
          > Select from website</Button>
        </span>
      </Tooltip>
    </Box><Box sx={{
      display: 'flex',
      alignItems: 'center',
      mt: 1
    }}>
      <T variant="body2" color="textSecondary" sx={{ flex: 1 }}>Selector: {props.value || '(none)'}</T>
      <Button
        onClick={() => {
          setEditing(!editing);
        }}
        sx={{
          ml: 1,
          flex: 1
        }}
        disabled={props.readonly}
      >Manual&nbsp;edit</Button>
    </Box></>;
  }
    
  
  return <TextField
    size="small"
    sx={{
      flex: 2
    }}
    fullWidth
    placeholder={props.placeholder}
    disabled={!editing || props.readonly}
    onChange={(event) => onChange({ selector: event.target.value })}
    // Make sure to pass defined value (empty string)
    // so that the component is
    // controlled throughout its lifetime
    value={props.value || ''}/>;
}

/**
 * @param {EditorProps} props
 */
function StringField(props) {
  let attribute = props.attribute;
  let config = attribute.config || {};
  let value = props.value;
  let allowEnters = config.multiline || props.attribute.isLegacyMenu;

  if (!props.skipCleaning && !props.rawText) {
    value = cleanValue(value);
  }

  let [error, setErrorInner] = useState(null);
  let [errorOverride, setErrorOverride] = useState(null);

  function setError(e) {
    setErrorInner(e);
    if (props.onError) {
      props.onError(e);
    }
  }

  function validate(val) {
    if (config.validate) {
      if (!(new RegExp(config.validationRegex || '').test(val))) {
        setError(config.validationMessage || '');
        return true;
      }
    }
  }

  useEffect(() => {
    // make sure initial value is validated for errors
    if (props.suggestionBar) {
      validate(value);
    }
    // eslint-disable-next-line
  }, []);

  let updateFn = (e) => {
    let val = e.target.value;
    let hasError = validate(val);

    if (hasError) {
      setErrorOverride(val);
    } else {
      setError(null);
      setErrorOverride(null);
      val = clearOnChange(val);
      props.onChange(!props.rawText ? normalToEscaped(val, {
        escapeCommands: false
      }) : val);
    }
  };

  /**
   * Cleans a string during on change.
   * @param {string} val - Value to be cleaned
   */
  const clearOnChange = (val) => {
    if (allowEnters) {
      return val;
    }
    val = val.replace(/\n/g, ' ');
    return val;
  };

  /**
   * @type {import("@mui/material").TextFieldProps['onKeyDown']}
   */
  const onFieldKeyDown = (evt) => {
    if (config.multiline) {
      return;
    }
    
    if (evt.key === 'Enter') {
      evt.preventDefault();
    }
  };


  if (config.multiline) {
    return <TextField
      disabled={props.readonly}
      error={!!error}
      label={(props.suggestionBar || props.noDescription) ? undefined : attribute.name}
      size="small"
      helperText={error ? error : ((props.suggestionBar || props.noDescription) ? undefined : attribute.description)}
      variant={props.suggestionBar ? undefined : 'outlined'}
      multiline
      rows={props.suggestionBar ? 2 : 3}
      placeholder={props.placeholder}
      value={errorOverride === null ? (value || '') : errorOverride}
      fullWidth
      autoFocus={props.autoFocus}
      onChange={updateFn}
      sx={{
        my: 1
      }}
    />;
  } else {
    return <TextField
      disabled={props.readonly}
      error={!!error}
      label={(props.suggestionBar || props.noDescription) ? undefined : attribute.name}
      size="small"
      multiline
      maxRows={3}
      helperText={error ? error : ((props.suggestionBar || props.noDescription) ? undefined : attribute.description)}
      variant={props.suggestionBar ? undefined : 'outlined'}
      placeholder={props.placeholder}
      value={errorOverride === null ? (value || '') : errorOverride}
      onKeyDown={allowEnters ? undefined : onFieldKeyDown}
      onChange={updateFn}
      fullWidth
      autoFocus={props.autoFocus}
      onFocus={(e) => {
        if (e.target.value === 'value') {
          e.target.select();
        }
      }}
      sx={{
        my: 1
      }}
    />;
  }
}


/**
 * @param {EditorProps} props
 */
function NumericFormatField(props) {
  let attribute = props.attribute;
  let value = props.value;
  if (!props.skipCleaning && !props.rawText) {
    value = cleanValue(value);
  }

  let [error, setErrorInner] = useState(null);
  let [customSelected, setCustomSelected] = useState(false);

  function setError(e) {
    setErrorInner(e);
    if (props.onError) {
      props.onError(e);
    }
  }

  function validate(_val) {
    // pass for now, in the future maybe can validate the key or modifiers
    return false;
  }

  useEffect(() => {
    // make sure initial value is validated for errors
    if (props.suggestionBar) {
      validate(value);
    }
    // eslint-disable-next-line
  }, []);

  let updateFn = (val) => {
    let hasError = validate(val);

    if (!hasError) {
      setError(null);
      props.onChange(!props.rawText ? normalToEscaped(val, {
        escapeCommands: false
      }) : val);
    }
  };
  value = value || '';
  if (typeof value === 'undefined') {
    value = null;
  }

  let isStandard = [',', ',%', ',.2f', '$,.2f'].includes(value);
  let isCustom = customSelected || (!isStandard && value);

  return <>
    <FormControl size="small" variant="outlined" style={{ width: '100%' }}>
      {props.suggestionBar ? null : <InputLabel>{attribute.name}</InputLabel>}
      <Select
        fullWidth
        variant="outlined"
        size="small"
        displayEmpty
        disabled={props.readonly}
        value={isCustom ? 'custom' : value}
        label={props.suggestionBar ? null : attribute.name}
        onChange={(e) => setTimeout(() => {
          let valueToSelect = e.target.value;
          let isCustom = false;
          if (valueToSelect === 'custom') {
            isCustom = true;
            valueToSelect = '';
          }
          setCustomSelected(isCustom);
          updateFn(valueToSelect);
        }, 5)}
      >
        <MenuItem value=",">Thousands separator (,)</MenuItem>
        <MenuItem value=",%">Percentage (,%)</MenuItem>
        <MenuItem value=",.2f">Fixed precision (,.2f)</MenuItem>
        <MenuItem value="$,.2f">Money ($,.2f)</MenuItem>
        <Divider/>
        <MenuItem value="custom">Custom...</MenuItem>
      </Select>
    </FormControl>
    {isCustom && <TextField
      error={!!error}
      label={props.suggestionBar ? undefined : 'Custom format'}
      size="small"
      disabled={props.readonly}
      helperText={error ? error : ((props.suggestionBar || props.noDescription) ? undefined : attribute.description)}
      variant={props.suggestionBar ? undefined : 'outlined'}
      placeholder={props.placeholder}
      value={value}
      onChange={(e) => {
        updateFn(e.target.value);
      }}
      fullWidth
      style={{
        marginTop: 18
      }}
    />
    }
  </>;
  
}

/**
 * @param {EditorProps} props
 */
function ShortcutField(props) {
  let buttonRef = useRef();
  let value = props.value;
  if (!props.skipCleaning && !props.rawText) {
    value = cleanValue(value);
  }

  let [isSelecting, setIsSelecting] = useState(false);

  let updateFn = (val) => {
    props.onChange(!props.rawText ? normalToEscaped(val, {
      escapeCommands: false
    }) : val);
  };
  value = value || '';

  // set here instead of default value because the dialog needs the button ref
  // which won't be set until after the first render
  useEffect(() => {
    if (!value) {
      setIsSelecting(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return <>
    <div style={{
      display: 'flex',
      marginTop: 12,
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 10
    }}>
      
      <div style={{ flex: 1, overflowX: 'auto', display: 'flex', alignItems: 'center', minWidth: 130 }}>
        {value ? (
          <>
            <Shortcut shortcut={value} notActive />
            <SnippetLink shortcut={value} sx={{ mx: 1 }} />
          </>
        ) : <T variant="body2" color="textSecondary">No {snippetOrPrompt} selected</T>}
      </div> 
      <Button
        size="small"
        ref={buttonRef}
        onClick={() => setIsSelecting(true)}
        variant="outlined"
        disabled={props.readonly}
      >
        Select {snippetOrPrompt}
      </Button>
    </div>
    {isSelecting && <SnippetSelector
      target={buttonRef.current}
      onClose={() => {
        setIsSelecting(false);
      }}
      onSelect={(snippet) => {
        setIsSelecting(false);
        updateFn(snippet.data.shortcut);
      }}
    />}
  </>;
}

/**
 * 
 * @param {{ platforms: object, name: string }} param0 
 * @returns 
 */
export function MenuItemTextWithChip({ platforms, name }) {
  let platformSuffix = null;
  if (platforms) {
    if (platforms[CURRENT_PLATFORM]) {
      if (CURRENT_PLATFORM === 'windows') {
        platformSuffix = <WindowsChip />;
      } else if (CURRENT_PLATFORM === 'mac') {
        platformSuffix = <MacChip />;
      }
    }
  }
  return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
    {name}{platformSuffix}
  </div>;
}

/**
 * @param {EditorProps} props
 */
function KeyField(props) {
  let attribute = props.attribute;
  let value = props.value;
  
  const [rawValue, setRawValue] = useState(value);
  const supportsFunction = useDesktopClientSupportsFeature('function-keys');

  useEffect(() => {
    if (!props.skipCleaning && !props.rawText) {
      setRawValue(cleanValue(value));
    }
  }, [value, props.skipCleaning, props.rawText]);

  let [error, setErrorInner] = useState(null);

  function setError(e) {
    setErrorInner(e);
    if (props.onError) {
      props.onError(e);
    }
  }

  /**
   * @param {string} _val 
   * @returns 
   */
  function validate(_val) {
    let { key } = extractKeys(_val);
    if (key === '') {
      setError('Key is required');
      return true;
    }

    setError(null);
    return false;
  }

  useEffect(() => {
    // make sure initial value is validated for errors
    if (props.suggestionBar) {
      validate(value);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * 
   * @param {string[]} modifiers 
   * @param {string} key 
   */
  let updateFn = (modifiers, key) => {
    if (key === 'custom') {
      key = 'a';
    }
    if (key === 'function') {
      key = 'F1';
    }
    let val = modifiers.join('-') + (modifiers.length ? '-' : '') + key;
    let hasError = validate(val);
    setRawValue(val);
    if (!hasError) {
      props.onChange(!props.rawText ? normalToEscaped(val, {
        escapeCommands: false
      }) : val);
    }
  };

  const { key, modifiers } = extractKeys(rawValue);

  const isFuncKey = supportsFunction && functionKeys.includes(key);
  let isCustomKey = (!key || !standardKeys.find(x => x.code === key)) && !isFuncKey;

  return <>
    <Select
      fullWidth
      variant="outlined"
      size="small"
      disabled={props.readonly}
      value={isCustomKey ? 'custom' : (isFuncKey ? 'function' : key)}
      onChange={(e) => setTimeout(() => updateFn(modifiers, e.target.value), 5)}
    >
      {standardKeys.map(({ code, name, platforms }, index) => {
        return <MenuItem key={index} value={code}><MenuItemTextWithChip platforms={platforms} name={name} /></MenuItem>;
      })}
      <Divider/>
      {supportsFunction && <MenuItem value="function"><MenuItemTextWithChip platforms={{ [CURRENT_PLATFORM]: true }} name="Function key..." /></MenuItem>}
      <MenuItem value="custom">Custom...</MenuItem>
    </Select>
    <div style={{
      flexDirection: 'row',
      marginTop: 18
    }}>
      {isCustomKey && <TextField
        error={!!error}
        disabled={props.readonly}
        label={props.suggestionBar ? undefined : 'Custom key'}
        size="small"
        helperText={error ? error : ((props.suggestionBar || props.noDescription) ? undefined : attribute.description)}
        variant={props.suggestionBar ? undefined : 'outlined'}
        placeholder={props.placeholder}
        value={key}
        onFocus={(e) => {
          e.target.select();
        }}
        onChange={(e) => {
          let key = e.target.value;
          if (key.length <= 1) {
            updateFn(modifiers, key);
          }
        }}
        fullWidth
        autoFocus={props.autoFocus}
      />
      }
      {isFuncKey && <Select
        labelId="function-key-label"
        id="function-key-select"
        fullWidth
        variant={props.suggestionBar ? undefined : 'outlined'}
        size="small"
        disabled={props.readonly}
        value={key}
        onChange={(e) => {
          setTimeout(() => updateFn(modifiers, e.target.value), 5);
        }}
        autoFocus={props.autoFocus}
      >
        {functionKeys.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
      </Select>}
    </div>
    <div style={{
      textAlign: 'center',
      marginTop: 12
    }}>
      <ToggleButtonGroup
        disabled={props.readonly}
        size="small"
        value={modifiers}
        onChange={(_e, modifiers) => {
          updateFn(modifiers || [], key);
        }}
      >
        <ToggleButton value="shift">Shift</ToggleButton>
        <ToggleButton value="alt">{isMacPlatform() ? 'Option (Alt)' : 'Alt (Option)'}</ToggleButton>
        <ToggleButton value="ctrl">Ctrl</ToggleButton>
        {/* We support pressing the Win key on selecting the Cmd key on Windows app */}
        <ToggleButton value="cmd">{isMacPlatform() ? 'Cmd (Win)' : 'Win (Cmd)'}</ToggleButton>
      </ToggleButtonGroup>
    </div>
  </>;
}


/**
 * @param {EditorProps} props
 */
function NumberField(props) {
  let attribute = props.attribute;
  let config = attribute.config || {};
  let value = props.value;
  if (!props.rawText) {
    value = cleanValue(value);
  }

  let [error, setErrorInner] = useState(null);
  let [errorOverride, setErrorOverride] = useState(null);

  function setError(e) {
    setErrorInner(e);
    if (props.onError) {
      props.onError(e);
    }
  }


  function validate(raw) {
    if (!props.suggestionBar && raw === '') {
      return; // allow blank in data dialog
    }

    let val = Number(raw);
    if (isNaN(val)) {
      setError('Invalid value');
      return true;
    } else if (raw.trim && raw.trim() === '') {
      setError('Cannot be blank');
      return true;
    } else if (config.minimum !== undefined && val < config.minimum) {
      setError('Cannot be less than ' + config.minimum);
      return true;
    } else if (config.maximum !== undefined && val > config.maximum) {
      setError('Cannot be larger than ' + config.maximum);
      return true;
    } else if (config.integer && Math.round(val) !== val) {
      setError('Cannot be a decimal number');
      return true;
    }
  }

  useEffect(() => {
    // make sure initial value is validated for errors
    if (props.suggestionBar) {
      validate(value);
    }
    // eslint-disable-next-line
  }, []);



  if (value && isNaN(value)) {
    // fallback when the item isn't a number
    return <FallBackField {...props} />;
  }


  return <TextField
    disabled={props.readonly}
    label={(props.suggestionBar || props.noDescription) ? undefined : attribute.name}
    size="small"
    helperText={error ? error : ((props.suggestionBar || props.noDescription) ? undefined : attribute.description)}
    type="number"
    variant={props.suggestionBar ? undefined : 'outlined'}
    error={!!error}
    placeholder={props.placeholder}
    value={errorOverride === null ? (value || '') : errorOverride}
    onChange={(e) => {
      let hasError = validate(e.target.value);
      
      if (hasError) {
        setErrorOverride(e.target.value);
      } else {
        setError(null);
        setErrorOverride(null);
        props.onChange(e.target.value);
      }
    }}
    fullWidth
    inputProps={{
      min: config.minimum,
      max: config.maximum,
      step: config.integer ? 1 : undefined
    }}
    autoFocus={props.autoFocus}
    sx={{
      my: 1
    }}
  />;
}


const longToShort = {
  'years': 'Y',
  'quarters': 'Q',
  'months': 'M',
  'weeks': 'W',
  'days': 'D',
  'hours': 'h',
  'minutes': 'm',
  'seconds': 's'
};

const numToDay = {
  0: 'SUN',
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT'
};

let shiftsToString = (shifts) => {
  return shifts.map(shiftToString).join(' ');
};

let shiftToString = (shift) => {
  let txt;
  if (!('direction' in shift)) {
    txt = shift.shift + longToShort[shift.units];
    if (shift.shift > 0) {
      txt = '+' + txt;
    }
  } else {
    txt = shift.direction + (shift.units ? longToShort[shift.units] : numToDay[shift.day]);
  }
  if (shift.skips && shift.skips.length) {
    txt += '(skip=' + shift.skips.join(', ') + ')';
  }
  return txt;
};


function TimeLengthField(props) {
  let attribute = props.attribute;
  let config = attribute.config || {};
  let value = props.value;
  if (!props.rawText) {
    value = cleanValue(value);
  }

  let isDuration = !!config.duration;
  /**
   * @type {string[]}
   */
  let showUnits = config.showUnits;

  let [error, setErrorInner] = useState(null);
  let [errorOverride, setErrorOverride] = useState(null);

  let hadErr = false;
  let shifts;
  try {
    shifts = errorOverride || parseTimeShift(value, isDuration, '');
  } catch (err) {
    hadErr = true;
    shifts = [];
  }

  let [selectedIndex, setSelectedIndex] = useState(shifts.length ? 0 : -1);

  useEffect(() => {
    if (selectedIndex > shifts.length) {
      setSelectedIndex(-1);
    }
  }, [selectedIndex, shifts]);

  let selected, selectedType;
  if (selectedIndex > -1) {
    selected = shifts[selectedIndex];
    if (selected) {
      if (!('direction' in selected)) {
        selectedType = 'fixed';
      } else if (selected.units) {
        selectedType = 'period';
      } else {
        selectedType = 'dow';
      }
    }
  }

  function setError(e) {
    setErrorInner(e);
    if (props.onError) {
      props.onError(e);
    }
  }

  let saveShifts = () => {
    if (shifts.length === 0) {
      setErrorOverride(shifts);
      setError('Cannot be blank');
      return;
    }
    for (let shift of shifts) {
      if ('shift' in shift) {
        let s = shift.shift;
        if (s.trim && s.trim() === '') {
          setErrorOverride(shifts);
          setError('Shift cannot be blank');
          return;
        } else if (isDuration && Number(s) < 0) {
          setErrorOverride(shifts);
          setError('Cannot have a negative duration');
          return;
        }
      }
    }
    setError(null);
    setErrorOverride(null);
    props.onChange(shiftsToString(shifts));
  };



  if (value && hadErr) {
    // fallback when not valid
    return <FallBackField {...props} />;
  }

  function renderUnitOption({
    type,
    label
  }) {
    if (showUnits && !showUnits.includes(type)) {
      return null;
    }
    return <option value={type}>{label}</option>;
  }


  return <>
    {props.suggestionBar ? null : <T color="textSecondary">{attribute.name}</T>}
    <div style={{
      padding: 4,
      border: 'solid 1px #eee',
      borderRadius: 8
    }}>{shifts.map((shift, i) => {
        return <Chip
          color={i === selectedIndex ? 'primary' : undefined}
          key={i}
          style={{
            marginLeft: i > 0 ? 4 : 0,
            marginTop: 2
          }}
          disabled={props.readonly}
          size="small"
          onClick={() => {
            setSelectedIndex(i);
          }}
          onDelete={() => {
            shifts.splice(i, 1);
            saveShifts();
            setSelectedIndex(-1);
          }}
          label={shiftToString(shift)}
        />;
      })}
      <IconButton
        disabled={props.readonly}
        size="small"
        title="Add"
        style={{
          opacity: 0.7,
          marginLeft: 6
        }}
        onClick={() => {
          shifts.push({
            shift: 1,
            units: (!showUnits || showUnits.includes('days')) ? 'days' : 'seconds'
          });
          saveShifts();
          setSelectedIndex(shifts.length - 1);
        }}
      ><AddIcon fontSize="small" /></IconButton>
    </div>
    {selected && <div style={{ marginTop: 6 }}>
      {(!isDuration) && <Select
        disabled={props.readonly}
        native
        value={selectedType}
        fullWidth
        onChange={(e) => {
          let type = e.target.value;
          let newShift;
          if (type === 'fixed') {
            newShift = {
              shift: 1,
              units: 'days'
            };
          } else if (type === 'period') {
            newShift = {
              direction: '>',
              units: 'days'
            };
          } else {
            newShift = {
              direction: '>',
              day: 1
            };
          }
          shifts.splice(selectedIndex, 1, newShift);
          saveShifts();
        }}
        variant="standard"
      >
        <option value="fixed">
        Shift a fixed amount
        </option>
        <option value="period">
        Shift to boundary
        </option>
        <option value="dow">
        Shift to day
        </option>
      </Select>}
      <div style={{ marginTop: 16 }}>
        {selectedType === 'fixed' && <>
          <div style={{ display: 'flex' }}>
            <TextField
              disabled={props.readonly}
              type="number"
              value={selected.shift}
              onChange={(e) => {
                selected.shift = e.target.value;
                saveShifts();
              }}
              style={{
                flex: 1,
                marginRight: 10
              }}
              variant="standard"
            />
            <Select
              disabled={props.readonly}
              style={{
                flex: 2
              }}
              native
              onChange={(e) => {
                selected.units = e.target.value;
                if (e.target.value === 'weekdays') {
                  selected.units = 'days';
                  selected.skips = ['SAT', 'SUN'];
                } else {
                  if (selected.skips && selected.skips.length) {
                    selected.skips = [];
                  }
                }
                saveShifts();
              }}
              value={selected.units === 'days' ? (selected.skips && selected.skips.length ? 'weekdays' : 'days') : selected.units}
              variant="standard"
            >
              {renderUnitOption({ type: 'seconds', label: 'Seconds' })}
              {renderUnitOption({ type: 'minutes', label: 'Minutes' })}
              {renderUnitOption({ type: 'days', label: 'Days' })}
              {renderUnitOption({ type: 'weekdays', label: 'Weekdays' })}
              {renderUnitOption({ type: 'weeks', label: 'Weeks' })}
              {renderUnitOption({ type: 'months', label: 'Months' })}
              {renderUnitOption({ type: 'quarters', label: 'Quarters' })}
              {renderUnitOption({ type: 'years', label: 'Years' })}
            </Select>
          </div>
        </>}
        {selectedType === 'period' && <>
          <Select
            disabled={props.readonly}
            native
            onChange={(e) => {
              selected.direction = e.target.value;
              saveShifts();
            }}
            value={selected.direction}
            variant="standard"
          >
            <option value="<">Start</option><option value=">">End</option>
          </Select> <T style={{ display: 'inline', marginLeft: 3, marginRight: 4 }}>of</T> <Select
            disabled={props.readonly}
            native
            onChange={(e) => {
              selected.units = e.target.value;
              saveShifts();
            }}
            value={selected.units}
            variant="standard"
          >
            <option value="seconds">Second</option>
            <option value="minutes">Minute</option>
            <option value="days">Day</option>
            <option value="weeks">Week</option>
            <option value="months">Month</option>
            <option value="quarters">Quarter</option>
            <option value="years">Year</option>
          </Select>
        </>}
        {selectedType === 'dow' && <>
          <Select
            disabled={props.readonly}
            native
            onChange={(e) => {
              selected.direction = e.target.value;
              saveShifts();
            }}
            value={selected.direction}
            variant="standard"
          >
            <option value="<">Backwards</option><option value=">">Forwards</option>
          </Select> <T style={{ display: 'inline' }}>to nearest</T> <Select
            disabled={props.readonly}
            native
            onChange={(e) => {
              selected.day = Number(e.target.value);
              saveShifts();
            }}
            value={'' + selected.day}
            variant="standard"
          >
            <option value="0">Sunday</option><option value="1">Monday</option><option value="2">Tuesday</option><option value="3">Wednesday</option><option value="4">Thursday</option><option value="5">Friday</option><option value="6">Saturday</option>
          </Select>
        </>}
      </div>
    </div>}
    {error && <T variant="caption" color="error">{error}</T>}

    {(props.suggestionBar || props.noDescription) ? undefined : <T variant="caption" color="textSecondary" paragraph style={{ marginTop: 5, marginBottom: 0, paddingLeft: 12 }}>{attribute.description}</T>}
  </>;
}


let timeFormats = [
  { header: 'Common formats' },
  {
    insert: 'YYYY-MM-DD'
  },
  {
    insert: 'MM/DD/YYYY'
  },
  {
    insert: 'DD/MM/YYYY'
  },
  {
    insert: 'MMM Do, YYYY'
  },
  {
    insert: 'HH:mm',
    time: true
  },
  {
    insert: 'hh:mm a',
    time: true
  },
  { header: 'Year' },
  {
    insert: 'YYYY'
  },
  {
    insert: 'YY'
  },
  { header: 'Month' },
  {
    insert: 'MMMM',
  },
  {
    insert: 'MMM',
  },
  {
    insert: 'M',
  },
  {
    insert: 'MM',
    note: 'two digits'
  },
  {
    insert: 'Mo',
  },
  { header: 'Day' },
  {
    insert: 'dddd',
  },
  {
    insert: 'ddd',
  },
  {
    insert: 'D',
  },
  {
    insert: 'DD',
    note: 'two digits'
  },
  {
    insert: 'Do',
  },
  {
    header: 'Time',
    time: true
  },
  {
    insert: 'h',
    note: '12 hour clock',
    time: true
  },
  {
    insert: 'hh',
    note: '12 hour clock, two digits',
    time: true
  },
  {
    insert: 'H',
    note: '24 hour, clock',
    time: true
  },
  {
    insert: 'HH',
    note: '24 hour clock, two digits',
    time: true
  },
  {
    insert: 'm',
    time: true
  },
  {
    insert: 'mm',
    note: 'two digits',
    time: true
  },
  {
    insert: 's',
    time: true
  },
  {
    insert: 'ss',
    note: 'two digits',
    time: true
  },
  { header: 'Localized formats' },
  {
    insert: 'l'
  },
  {
    insert: 'L',
    note: 'two digits'
  },
  {
    insert: 'll',
    time: true
  },
  {
    insert: 'LL',
    time: true
  },
  {
    insert: 'LT',
    time: true
  },
  {
    insert: 'LLL',
    time: true
  },
  {
    insert: 'lll',
    time: true
  },
];


/**
 * @param {EditorProps} props
 */
function TimeFormatField(props) {
  let ref = useRef();

  let attribute = props.attribute;
  let config = attribute.config || {};
  let value = props.value;
  if (!props.rawText) {
    value = cleanValue(value);
  }

  let [error, setErrorInner] = useState(null);
  let [errorOverride, setErrorOverride] = useState(null);


  function setError(e) {
    setErrorInner(e);
    if (props.onError) {
      props.onError(e);
    }
  }

  let dateOnly = config.date_only;
  let dateTimeOnly = config.datetime_only;

  let preview = config.preview;
  let previewed;
  if (preview && errorOverride === null) {
    previewed = moment().format(value);
  }
  function validateDateOnly(val) {
    // We want to make sure the format can successfully format and 
    // re-parse a date otherwise it won't work in the date picker.
    const format = val;
    let baseDate = moment('2015-07-19');
    let formattedDate = baseDate.format(format);
    let newDate = moment(formattedDate, format);
    if ('2015-07-19' !== newDate.locale('en').format('YYYY-MM-DD')) {
      setError('not a valid date format – try YYYY-MM-DD');
      return true;
    }
    return false;
  }
  function validate(val) {
    if (val.trim() === '') {
      setError('The format cannot be blank');
      return true;
    }
    if (dateOnly) {
      return validateDateOnly(val);
    }
    if (dateTimeOnly) {
      if (!isValidDateOrTimeFormat(val)) {
        setError('not a valid date or time format – try YYYY-MM-DD or HH:mm');
        return true;
      }
    }
  }

  useEffect(() => {
    // make sure initial value is validated for errors
    if (props.suggestionBar) {
      validate(value);
    }
    // eslint-disable-next-line
  }, []);

  let updateFn = (val) => {
    let hasError = validate(val);
   
    if (hasError) {
      setErrorOverride(val);
    } else {
      setError(null);
      setErrorOverride(null);
      props.onChange(!props.rawText ? normalToEscaped(val, {
        escapeCommands: false
      }) : val);
    }
  };

  let formats = timeFormats;
  if (dateOnly) {
    formats = formats.filter(x => !x.time);
  }

  return <>
    <TextField
      disabled={props.readonly}
      label={(props.suggestionBar || props.noDescription) ? undefined : attribute.name}
      helperText={error ? error : ((props.suggestionBar || props.noDescription) ? undefined : attribute.description)}
      error={!!error}
      size="small"
      fullWidth
      value={errorOverride === null ? (value || '') : errorOverride}
      variant={props.suggestionBar ? undefined : 'outlined'}
      onChange={e => updateFn(e.target.value)}
      inputRef={ref}
      sx={{
        my: 1
      }}
    />
    {preview && (!error) && <T variant="caption" color="textSecondary" style={{ marginBottom: 8 }}>Preview: <b>{previewed}</b></T>}
    <div style={(props.suggestionBar || props.noDescription) ? { height: 120 } : { height: 135, position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          height: 135,
          overflow: 'auto',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          paddingBottom: 0,
          paddingRight: 8,
          paddingLeft: 8,
          paddingTop: 0,
        }}>
        {formats.map((x, i) => {
          if (x.header) {
            return <T key={i} variant="body2" color="textSecondary" style={{ paddingRight: 4, paddingLeft: 2, display: 'block', width: '100%', paddingBottom: 6, paddingTop: 8 }}>{x.header}<br/></T>;
          } else {
            if (!('example' in x)) {
              x.example = moment().format(x.insert);
            }
            return (
              <Tooltip 
                key={i}
                title={x.example + (x.note ? (' (' + x.note + ')') : '')}
              >
                <Chip
                  disabled={props.readonly}
                  onClick={() => {
                    /** @type {HTMLInputElement} */
                    let input = ref.current;
                    let startContent, endContent;
                    if (input.selectionStart || input.selectionStart === 0) {
                      startContent = input.value.substring(0, input.selectionStart);
                      endContent = input.value.substring(input.selectionEnd, input.value.length);
                    } else {
                      startContent = input.value;
                      endContent = '';
                    }
                    if ((/[a-z]$/i).test(startContent)) {
                      startContent = startContent + ' ';
                    }
                    if ((/^[a-z]/i).test(endContent)) {
                      endContent = ' ' + endContent;
                    }
                    input.value = startContent + x.insert + endContent;
                    input.selectionStart = startContent.length + x.insert.length;
                    input.selectionEnd = input.selectionStart;
                    input.focus();
                    updateFn(input.value);
                  }}
                  size="small"
                  label={<span style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: props.suggestionBar ? 62 : undefined,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}>{x.insert}</span>}
                  variant="outlined"
                  style={{
                    marginBottom: 3,
                    marginRight: 4
                  }}
                />
              </Tooltip>
            );
          }
        })} <T variant="body2" color="textSecondary" style={{ paddingLeft: 2, paddingRight: 10, paddingTop: 6, paddingBottom: 8, width: '100%', textAlign: 'right' }}><a href="https://blaze.today/commands/time#timedate-formatting" rel="noopener noreferrer" target="_blank">More</a></T>
      </div>
    </div>
  </>;
}


/**
 * @param {EditorProps} props
 */
function FallBackField(props) {
  return <>
    <StringField {...props} />
    <T variant="caption" color="error" style={{
      display: 'block',
      marginTop: 8
    }}>Invalid value</T>
  </>;
}


