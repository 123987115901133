import ParseNode from './ParseNode';
import { fonts } from '../components/SnippetEditor/fontConfig';
import { decompressDelta } from '../delta_proto/DeltaProto';
import { NodeAttribute, ParseError } from './ParserUtils';
import { lex, lexSQLWithCommands, pushBlockTokens } from './Lexer';
import { COMMANDS, fillErrorTemplate } from './Commands';
import { Environment } from './DataContainer'; // eslint-disable-line no-unused-vars
import { quickValidateAddonSpec } from '../components/Snippet/snippet_utilities';
import { grantsSatisfied } from '../components/Version/limitations';
import { isElectronApp } from '../flags';
import { hasSameCellNextInOps } from 'quill-better-table/src/utils';

const BREAK_TAG = '\u2028';
const DEFAULT_CELL_STYLES = {
  boxSizing: 'border-box',
  padding: '2px 5px',
  textAlign: 'left',
  verticalAlign: 'middle'
};
const DEFAULT_BORDER_STYLES = {
  Color: '#000',
  Width: '1px',
  Style: 'solid'
};
/**
 * Joins the contents of a delta into a string.
 * 
 * @param {DeltaType | import('quill/core').Delta} delta
 * @return {string}
 */
export function joinDelta(delta) {
  let ops = delta.ops;
  let str = '';
  for (let i = 0; i < ops.length; i++) {
    let op = ops[i];
    const tableCellLine = op.attributes?.tableCellLine;
    if (tableCellLine) {
      if (hasSameCellNextInOps(tableCellLine, ops, i)) {
        str += op.insert;
      } else {
        str += BREAK_TAG.repeat(op.insert.length);
      }
    } else if (op.attributes?.tableCol) {
      str += BREAK_TAG.repeat(op.insert.length);
    } else  if (typeof op.insert === 'string') {
      str += op.insert;
    } else {
      str += BREAK_TAG;
    }
  }
  return str;
}


/**
 * Splits replacement commands in a delta into their own ops.
 * 
 * @param {DeltaType | import('quill/core').Delta} delta
 * @param {string} mode the parsing mode
 * @param {Environment} env
 * 
 * @return {Promise<ParseNode[]>}
 */
export async function tokenize(delta, mode, env) {
  let str = joinDelta(delta);

  return parseText(str, mode, env);
}


/**
 * Returns the number of characters in an op. If op is an image returns a placeholder length.
 * 
 * @param {DeltaOpType} op
 * 
 * @return {number}
 */
export function opLength(op) {
  if (op.insert.length !== undefined) {
    return op.insert.length;
  }
  return BREAK_TAG.length;
}


/**
 * @param {DeltaType | import('quill/core').Delta} delta
 * @param {Environment} env
 * 
 * @return {Promise<[(DeltaOpType | ParseNode)[], boolean]>}
 */
export async function parseWithoutTree(delta, env) {
  let nodes = await tokenize(delta, env.config.mode, env);
  let ops = interleaveNodes(delta, nodes);
  let hadImport = false;
  if (env.config.stage !== 'tokenization') {
    ([ops, hadImport] = await pullInOtherSnippets(ops, env));
  }
  return [ops, hadImport];
}


/**
 * @param {DeltaType | import('quill/core').Delta} delta
 * @param {Environment} env
 * 
 * @return {Promise<ParseNode>}
 */
export async function parse(delta, env) {
  let isStyled;
  let [ops, hadImport] = await parseWithoutTree(delta, env);
  
  let tree = createTree(ops, env);
  if (!tree.isStyled && tree.find(x => x.tag === 'image' || x.tag === 'link')) {
    isStyled = true;
  }
  if (!tree.isStyled && isStyled) {
    tree.isStyled = true;
  }
  if (hadImport) {
    tree.hadImport = true;
  }
  return tree;
}


/**
 * Handles {import} commands.
 * 
 * @param {(ParseNode|DeltaOpType)[]} ops
 * @param {Environment} env
 * @param {string[]=} stack - stack of called snippets to prevent recursion
 * @param {string=} importPrefix - import prefixes from {import} commands
 * 
 * @return {Promise<[(ParseNode|DeltaOpType)[], boolean]>} true if there was an import
 */
async function pullInOtherSnippets(ops, env, stack = [], importPrefix = '') {
  let finalResult = [];
  let hadImport = false;

  for (let i = 0; i < ops.length; i++) {
    const op = ops[i];
    if (op instanceof ParseNode && (op.tag === 'import' || op.tag === 'addon')) {
      if (op.tag === 'import') {
        hadImport = true;
      }

      /** @type {(ParseNode|DeltaOpType)[]} */
      let res = null;
      let shortcut;

      let processDelta = async (delta, env, addonNamespace = null, importPrefix = null) => {
        const deltas = decompressDelta(delta);

        let myEnv = env;
        if (addonNamespace && addonNamespace !== myEnv.config.addonNamespace) {
          myEnv = env.derivedConfig({
            addonNamespace,
            // whitelists don't apply in addons
            commandWhitelist: null
          });
        }
        const nodes = await tokenize(deltas, env.config.mode, myEnv);
        if (importPrefix) {
          nodes.forEach(n => n.importPrefix = importPrefix + '-' + n.importPrefix);
        }
        res = interleaveNodes(deltas, nodes);
        const lastRes = res[res.length - 1];
        if (!(lastRes instanceof ParseNode)) {
          if (lastRes.insert === '\n' && (!lastRes.attributes || !(lastRes.attributes.blockquote || lastRes.attributes.list || lastRes.attributes.align || lastRes.attributes.tableCellLine))) {
            res.pop(); // remove last enter
          }
        }
        ([res] = await pullInOtherSnippets(res, myEnv, stack.concat(shortcut), importPrefix));

        // Avoid setting the extra properties when this property is undefined
        if (op.quillAttributes) {
          for (const o of res) {
            if (o instanceof ParseNode) {
              o.quillAttributes = Object.assign({}, op.quillAttributes, o.quillAttributes);
            } else {
              o.attributes = Object.assign({}, op.quillAttributes, o.attributes);
            }
          }
        }
      };

      if (op.tag === 'addon') {
        shortcut = op.info.command;
        const addonNamespace = shortcut.split('-')[0];

        const locations = env.locations.length === 0 ? ['local_data - root'] : env.locations;
        const addon = op.info.addon;
        const storeId = locations.join('; ') + ' -- addon[' + shortcut + ']_' + op.position();

        if (!env.config.doNotPullInAddons) {
          await processDelta(addon.data.content.delta.toUint8Array(), env.derivedLocation('local_data - ' + storeId), addonNamespace);
        } else {
          res = [];
        }
        
        const options = addon.data.options || {};
        const compOptions = options.addon || /** @type {AddonConfigType} */ ({});
        const display = compOptions.display || {};
        const preview = display.preview || 'contents';
        const insertion = display.insertion || 'contents';
        const startComp = new ParseNode('expand', 'addon', {
          attributes: op.info.attributes,
          addonConfigData: op.info.addonConfigData,
          spec: op.info.spec,
          storeId,
          visibility: {
            preview,
            insertion,
            name: op.info.name,
            description: compOptions.description
          }
        });
        startComp.startPosition = op.startPosition;
        startComp.importPrefix += storeId + '_start_addon';

        const endComp = new ParseNode('expand', 'addon', {
          start: startComp,
          storeId
        });
        endComp.startPosition = op.startPosition;
        endComp.importPrefix += storeId + '_end_addon';
        startComp.info.end = endComp;

        let grantViolation = false;
        for (const item of res) {
          if (item instanceof ParseNode) {
            // We need to collect the dependencies in order to ensure the
            // start addon chicklet updates to reflect addon state (e.g. errors)
            // when in chicklet mode in the form preview.
            startComp.addDependencies(item.dependencies);
            endComp.addDependencies(item.dependencies);
            if (!(await grantsSatisfied(addonNamespace, op.info.approvedGrants, item, env))) {
              grantViolation = true;
            }
          }
        }
        if (grantViolation) {
          // IMPORTANT: error message should be kept in sync with shared.js
          res = [new ParseNode('error', undefined, { message: '{' + addon.addonOptions.command + '} requires new permissions which you will need to approve to use it again.' }, op)];
        } else {
          /** @type {(ParseNode|DeltaOpType)[]} */
          const startArr = [startComp];
          res = startArr.concat(res).concat(endComp);
        }
        
      } else {
        if (env.config.findSnippet) {
          try {
            shortcut = (await op.info.process(op.info.attributes, env)).toLowerCase();
          } catch (error) {
            if (error instanceof ParseError) {
              res = [new ParseNode('error', undefined, { message: error.message }, op)];
            } else {
              throw error;
            }
          }
          if (!res) {
            if (stack.includes(shortcut)) {
              res = [new ParseNode('error', undefined, { message: `Cannot recursively include ${env.config.appType === 'AI' ? 'prompt' : 'snippet'}: ${shortcut}` }, op)];
            } else {
              let snippet = await env.config.findSnippet(shortcut);
              if (snippet) {
                await processDelta(snippet.delta, env, null, 'im' + op.startPosition + importPrefix);
              }
            }
          }
        }
      }

      if (!res) {
        if (!env.config.findSnippet) {
          finalResult.push(new ParseNode('error', undefined, { message: 'Cannot import in this context' }, op));
        } else {
          finalResult.push(new ParseNode('error', undefined, { message: `Could not find ${env.config.appType === 'AI' ? 'prompt' : 'snippet'}: ${shortcut}` }, op));
        }
      } else {
        // @ts-ignore
        const importsTable = !!res.find(x => x?.attributes?.tableCellLine);
        let currentTableCell = null;
        for (let j = i; j < ops.length; j++) {
          const op = ops[j];
          if (!(op instanceof ParseNode)) {
            if (op.attributes?.tableCellLine) {
              currentTableCell = op.attributes.tableCellLine;
              break;
            } else if (op.attributes?.tableCol) {
              break;
            }
          }
        }


        const lastOp = res[res.length - 1];
        // @ts-ignore
        if (lastOp?.attributes?.tableCellLine) {
          // for <p>{import:table}</p> trim extra newline after table
          const nextOp = ops[i + 1];
          const nextOp2 = ops[i + 2];
          // @ts-ignore
          if (nextOp?.insert === '' && nextOp2?.insert === '\n') {
            i += 2;
            continue;
          }
        }

        if (importsTable && !!currentTableCell) {
          // Prevent nesting tables
          // TODO: consider allowing this
          finalResult.push(new ParseNode('error', undefined, { message: 'Cannot {import} a table into a table' }, op));
        } else {
          if (!!currentTableCell) {
            // Convert new lines as cellLine
            const newRes = [];
            for (let importIndex = 0; importIndex < res.length; importIndex++) {
              const op = res[importIndex];
              if (op instanceof ParseNode) {
                newRes.push(op);
                continue;
              }
              if (op.insert !== '\n') {
                newRes.push(op);
                continue;
              }
              const newAttrs = {
                tableCellLine: currentTableCell,
                ...op.attributes,
              };

              newRes.push({
                attributes: newAttrs,
                insert: '\n'
              });
            }
            res = newRes;
          }

          // Spread operator .push(...res)
          // triggers call stack size exceeded error when res is large
          finalResult = finalResult.concat(res);
        }
      }
    } else {
      finalResult.push(op);
    }
  }

  return [finalResult, hadImport];
}


/**
 * Place the parsed nodes into the delta at the correct places.
 * 
 * @param {DeltaType | import('quill/core').Delta} delta
 * @param {ParseNode[]} nodes
 * 
 * @return {(ParseNode|DeltaOpType)[]} the new ops and nodes
 */
export function interleaveNodes(delta, nodes) {
  /** @type {any[]} */
  let ops = delta.ops;

  let counter = 0;
  let currentOp = 0;
  for (let node of nodes) {
    while (counter + opLength(ops[currentOp]) < node.startPosition) {
      counter += opLength(ops[currentOp]);
      currentOp++;
    }

    let startIndex = node.startPosition - counter;

    // Store styling on the tag
    if (startIndex === opLength(ops[currentOp])) {
      node.quillAttributes = ops[currentOp + 1].attributes;
    } else {
      node.quillAttributes = ops[currentOp].attributes;
    }

    let nextCounter = counter;
    let nextOp = currentOp;
    while (nextCounter + opLength(ops[nextOp]) < node.endPosition) {
      nextCounter += opLength(ops[nextOp]);
      nextOp++;
    }
    let endIndex = node.endPosition - nextCounter;

    let newStartOp = Object.assign({}, ops[currentOp]);
    if (newStartOp.insert.slice) { // images won't support slice
      newStartOp.insert = newStartOp.insert.slice(0, startIndex);
    }

    let newEndOp = Object.assign({}, ops[nextOp]);
    if (newEndOp.insert.slice) { // images won't support slice
      newEndOp.insert = newEndOp.insert.slice(endIndex);
    }

    counter += opLength(newStartOp);
    counter += node.endPosition - node.startPosition;
    if (newStartOp.insert === '' && newEndOp.insert === '') {
      ops.splice(currentOp, nextOp - currentOp + 1, node);
      currentOp += 1;
    } else if (newStartOp.insert === '') {
      ops.splice(currentOp, nextOp - currentOp + 1, node, newEndOp);
      currentOp += 1;
    } else if (newEndOp.insert === '') {
      ops.splice(currentOp, nextOp - currentOp + 1, newStartOp, node);
      currentOp += 2;
    } else {
      ops.splice(currentOp, nextOp - currentOp + 1, newStartOp, node, newEndOp);
      currentOp += 2;
    }
  }
  

  for (let i = ops.length - 1; i >= 0; i--) {
    if (ops[i].insert && ops[i].insert.includes && ops[i].insert !== '\n' && ops[i].insert.includes('\n')) {
      let parts = ops[i].insert.split('\n');
      for (let p = parts.length - 1; p >= 0; p--) {
        if (p !== parts.length - 1 || parts[p] !== '') {
          parts[p] = {
            attributes: ops[i].attributes,
            insert: parts[p]
          };
          if (p !== parts.length - 1) {
            parts.splice(p + 1, 0, {
              attributes: ops[i].attributes,
              insert: '\n'
            });
          }
          if (parts[p].insert === '') {
            parts.splice(p, 1);
          }
        }
      }
      if (parts[parts.length - 1] === '') {
        parts.splice(parts.length - 1, 1);
      }
      ops.splice(i, 1, ...parts);
    }
  }

  return ops;
}


/**
 * Create styling from op attributes.
 * 
 * @param {object} attr
 * 
 * @return {{styles: Partial<CSSStyleDeclaration>, wrappers: ParseNode[]}}
 */
function getStyling(attr) {
  /** @type {ParseNode[]} */
  let wrappers = [];
  /** @type {Partial<CSSStyleDeclaration>} */
  let styles = {};
  if (attr) {
    if (attr['bold']) {
      wrappers.push(new ParseNode('el', 'b'));
    }
    if (attr['underline']) {
      wrappers.push(new ParseNode('el', 'u'));
    }
    if (attr['italic']) {
      wrappers.push(new ParseNode('el', 'em'));
    }
    if (attr['strike']) {
      wrappers.push(new ParseNode('el', 's'));
    }
    if (attr['link']) {
      let attrs = { href: attr['link'] };
      wrappers.push(new ParseNode('el', 'a', attrs));
    }
    if (attr['font']) {
      let font = fonts.find(x => x.class === attr['font']);
      styles['fontFamily'] = font ? font.family : attr['font'];
    }
    if (attr['size']) {
      let size = attr['size'];
      if (size === 'small') {
        if (isElectronApp()) {
          styles['fontSize'] = 'smaller';
        } else {
          styles['fontSize'] = '70%';
        }
      } else if (size === 'large') {
        if (isElectronApp()) {
          styles['fontSize'] = 'larger';
        } else {
          styles['fontSize'] = '130%';
        }
      } else if (size === 'huge') {
        if (isElectronApp()) {
          styles['fontSize'] = 'x-large';
        } else {
          styles['fontSize'] = '180%';
        }
      } else {
        styles['fontSize'] = size;
      }
    }
    if (attr['color']) {
      styles.color = attr['color'];
    }
    if (attr['background']) {
      styles['backgroundColor'] = attr['background'];
    }
    if (attr['script'] === 'sub') {
      wrappers.push(new ParseNode('el', 'sub'));
    }
    if (attr['script'] === 'super') {
      wrappers.push(new ParseNode('el', 'sup'));
    }
    if (attr['align']) {
      styles['textAlign'] = attr['align'];
    }
    if (attr['direction']) {
      styles['direction'] = attr['direction'];
    }

    for (let w = 1; w < wrappers.length; w++) {
      wrappers[w - 1].addChild(wrappers[w]);
    }
  }
  return {
    styles,
    wrappers
  };
}



/**
 * Finds a command at the start of a string if a command exists.
 * 
 * @param {string} str - the string to look for a command at the start of
 * @param {Environment} env
 * 
 * @return {string} - the command
 */
export function processCommandInAttribute(str, env) {
  let startStr = str;

  if (!env) {
    throw new ParseError('Cannot use commands in this context.');
  }
  
  if (!env.config.commandCache) {
    generateCommandCache(env);
  }


  if (str[0] !== '{') {
    return null;
  }
  str = str.slice(1);
        
  for (let key of env.config.commandCache.validInAttributes) {
    let command = env.config.commandCache.commands[key];
    if (str.startsWith(key.toLowerCase())) {
      str = str.slice(key.length);
      if (str[0] !== ':' && str[0] !== '}' && key !== '=') {
        return null;
      } else {
        if (command.attributes) {
          if (!('bare' in command && command.bare) && str.startsWith(':')) {
            str = str.slice(1);
          }

          let spec = command.attributes;
          try {
            let blocks = getAttributeBlocks(str, spec, env, 0);
            str = str.slice(blocks.consumed.length);
          } catch (err) {
            return null;
          }
        } else {
          if (str[0] !== '}') {
            throw new ParseError();
          } else {
            str = str.slice(1);
          }
        }
      }
      break;
    }
  }

  let command = startStr.slice(0, startStr.length - str.length);
  if (command.length < 2) {

    if (env.config.installableAddons) {
      // if we have specified installable addons, see if it is one
      for (let key of Object.keys(env.config.installableAddons)) {
        if (str.startsWith(key + '-')) {
          env.config.missingAddons = env.config.missingAddons || {};
          if (!env.config.missingAddons[key]) {
            env.config.missingAddons[key] = env.config.installableAddons[key];
          }
          break;
        }
      }
    }

    // it may be 1 less as we cut the initial '{'
    return null;
  }
  return command;
}


  
 
/**
 * @param {string} str
 * @param {import('./Commands').CommandSpecDef} spec
 * @param {Environment} env
 * @param {number} attrStartPosition
 * 
 * @return {{consumed: string, attributes: NodeAttribute[]}}
 */
function getAttributeBlocks(str, spec, env, attrStartPosition) {
  let attributes = [];

  let nodeDepth = 0;
  let name = null;

  // We didn't type it fully because it can have other objects than just TokenType
  /** @type {object[]} */
  let tokens = [];
  let current = '';
  let currentRaw = '';

  // We don't type annotate this method
  // because it can have other objects than just TokenType
  function emitToken(t) {
    t.position = position;
    tokens.push(t);
  }

  /**
   * @returns {void}
   */
  function pushAttribute() {
    if (current) {
      emitToken({
        type: 'TEXT',
        text: current
      });
    }

    if (['run', 'button'].includes(spec.commandName) && attributes.length === 0) {
      pushBlockTokens(tokens);
    }
    if (['urlload', 'urlsend', 'dbselect', 'dbinsert', 'dbdelete', 'dbupdate'].includes(spec.commandName) && ['finish', 'error', 'begin'].includes(name)) {
      pushBlockTokens(tokens);
    }

    attributes.push(new NodeAttribute({
      name,
      value: tokens,
      mode: currentMode(),
      start: start + attrStartPosition,
      mid: mid + attrStartPosition,
      end: position + attrStartPosition,
      raw: currentRaw.slice(0, currentRaw.length - 1)
    }));

    currentRaw = '';
    current = '';
    tokens = [];

    start = position;
    mid = position;
    name = null;
  }

  /**
   * @returns {'equation'|'bsql'|'no-names'|undefined}
   */
  function currentMode() {
    if (spec.positionalDef && 
        (spec.positionalDef.type === 'equation' || spec.positionalDef.type === 'lambda' || spec.positionalDef.list) &&
        spec.positional[0] <= attributes.length + 1 &&
        spec.positional[1] >= attributes.length + 1) {
      return 'equation';
    }

    if (name && spec.named[name] && (spec.named[name].type === 'lambda' || spec.named[name].list)) {
      return 'equation';
    }

    if (name && spec.named[name] && spec.named[name].type === 'equation') {
      return 'equation';
    }

    if (spec.positionalDef &&
      spec.positionalDef.type === 'bsql' &&
      spec.positional[0] <= attributes.length + 1 &&
      spec.positional[1] >= attributes.length + 1) {
      return 'bsql';
    }

    if (spec.positional[0] > 0 && spec.positional[0] === spec.positional[1] && attributes.length < spec.positional[0]) {
      // We don't need to escape '=' in the mandatory positional elements
      return 'no-names';
    }
  }


  let position = 0;
  let start = position;
  let mid = position;


  while (position < str.length) {
    let isStatic = (name ? (spec.named[name] && spec.named[name].static) : (spec.positionalDef && spec.positionalDef.static));
    if (currentMode() === 'equation') {
      let lexed = lex(str.slice(position), env, { lexComment: ['run', 'button'].includes(spec.commandName) && attributes.length === 0 });
      if (!['END_ATTRIBUTE', 'END_COMMAND'].includes(lexed.termination)) {
        throw new ParseError();
      }
      let isList = (name ? (spec.named[name] && spec.named[name].list) : spec.positionalDef.list);
      if (isList) {
        // lists we want to use the equation parsing rule as we need to support quotes
        // but we want to pass them on as text as they may not be a valid equation
        tokens = lexed.tokens.map(x => {
          if (x.type === 'COMMAND') {
            return x;
          } else {
            return {
              type: 'TEXT',
              text: x.source,
            };
          }
        });
      } else {
        tokens = lexed.tokens;
      }
      let startPosition = position;
      position += lexed.position;
      currentRaw = str.slice(startPosition, position);
      pushAttribute();
      if ('END_COMMAND' === lexed.termination) {
        return {
          consumed: str.slice(0, position),
          attributes
        };
      } else {
        // END_ATTRIBUTE
        continue;
      }
    } else if (currentMode() === 'bsql') {
      let lexed = lexSQLWithCommands(str.slice(position), env);
      if (!['END_ATTRIBUTE', 'END_COMMAND'].includes(lexed.termination)) {
        throw new ParseError();
      }

      tokens = lexed.tokens;
      
      let startPosition = position;
      position += lexed.position;
      currentRaw = str.slice(startPosition, position);
      pushAttribute();
      if ('END_COMMAND' === lexed.termination) {
        return {
          consumed: str.slice(0, position),
          attributes
        };
      } else {
        // END_ATTRIBUTE
        continue;
      }
    } else {
      let char = str[position];
      currentRaw += char;
      position++;
      if (char === '\\') {
        if (position === str.length) {
          throw new ParseError();
        }
        let char = str[position];
        currentRaw += char;
        position++;
        if (char === ' ') {
          current += '\u2E31';
        } else if (char === '{') {
          current += '\u2E32';
        } else if (char === 'n') {
          current += '\n';
        } else if (char === 't') {
          current += '\t';
        } else if (char === 'r') {
          current += '\r';
        } else {
          current += char;
        }
      } else if (char === '=') {
        if (!nodeDepth) {
          if (['no-names', 'equation', 'bsql'].includes(currentMode())) {
            current += char;
          } else if (name !== null) {
            current += char;
          } else {
            // it's a name so no embedded commands will be evaluated
            // let's collapse everything down
            let tokenStr = '';
            for (let token of tokens) {
              if (token.type === 'TEXT') {
                tokenStr += token.text;
              } else {
                tokenStr += token.command;
              }
            }
            name = (tokenStr + current).trim().replace(new RegExp('\u2E31', 'g'), ' ');
            tokens = [];
            current = '';
            currentRaw = '';
            mid = position;
          }
        } else {
          current += char;
        }
      } else if (char === '{') {
        let command = processCommandInAttribute(str.slice(position - 1), env);
        if (command) {
          if (current) {
            emitToken({
              type: 'TEXT',
              text: current
            });
            current = '';
          }
          if (isStatic) {
            // When static we don't allow embedded commands, so we emit it as text
            emitToken({
              type: 'COMMAND',
              command,
              invalidCommand: true
            });
          } else {
            emitToken({
              type: 'COMMAND',
              command
            });
          }
          let startPosition = position;
          position += command.length - 1;
          currentRaw += str.slice(startPosition, position);
        } else {
          current += char;
          nodeDepth++;
        }
      } else if (char === '}') {
        if (nodeDepth === 0) {
          pushAttribute();
          return {
            consumed: str.slice(0, position),
            attributes
          };
        } else {
          current += char;
          nodeDepth--;
        }
      } else if (char === ';') {
        if (!nodeDepth) {
          pushAttribute();
        } else {
          current += char;
        }
      } else {
        current += char;
      }
    }
  }
  throw new ParseError(); // can't find end of attributes
}


/**
 * Create the tree from the ops.
 * 
 * @param {object[]} ops - Ops or ParseNodes
 * @param {Environment} env
 */
export function createTree(ops, env) {
  let domRoot = new ParseNode('root', 'root', { config: { quickItems: env.config.quickentry }, inputs: [], derived: [] });
  let currentRoot = domRoot;
  let position = -10;

  /** @type {ParseNode[]} */
  let block = [];
  let currentList = {
    el: undefined,
    type: 'ordered',
    indent: 0
  };
  let treeContext = /** @type {Parameters<createTableTree>['4']} */ ({});

  /**
   * @param {object} styles - if no styles, you should pass blank object to this {}, not null or undefined
   * @param {object=} attr
   */
  function addBlockParagraph(styles, attr = {}) {
    let p = new ParseNode('el', attr.blockquote ? 'blockquote' : 'p', { style: styles });
    p.startPosition = position--;
    p.importPrefix += '_paragraph';

    for (let i = block.length - 1; i >= 0; i--) {
      if (block[i].tag === 'span' && block[i].children.length === 1 && Object.keys(block[i].info.style).length === 0) {
        block[i] = block[i].children[0];
      }
    }
    if (block.length === 1) {
      if (block[0].tag === 'text' && block[0].info.message === '') {
        currentRoot.addChild(p);
        return;
      }
    }
    p.addChildren(block);
    currentRoot.addChild(p);
  }

  let isStyled = false;
  
  for (let i = 0; i < ops.length; i++) {
    let op = ops[i];
    if (!isStyled) {
      if (op instanceof ParseNode && op.quillAttributes && Object.keys(op.quillAttributes).length) {
        isStyled = true;
      } else if (op.attributes && Object.keys(op.attributes).length) {
        isStyled = true;
      }
    }

    if (op instanceof ParseNode) {
      let { wrappers, styles } = getStyling(op.quillAttributes);
      for (let wrapper of wrappers) {
        wrapper.startPosition = position--;
        wrapper.importPrefix += '_' + wrapper.tag;
      }
      if (op.tag === 'form' || op.tag === 'button') {
        op.info.style = styles;
      }
      
      if (op.tag === 'form' && (['toggle_start',  'toggle_end',  'if_start',  'if_else', 'if_elseif', 'if_end', 'repeat_start', 'repeat_end', 'note_start', 'note_end'].includes(op.info.type))) {
        block.push(op);
      } else {
        if (!wrappers.length && Object.keys(styles).length) {
          wrappers.push(new ParseNode('el', 'span', {}));
          wrappers[0].startPosition = position--;
          wrappers[0].importPrefix += '_span';
        }
        if (Object.keys(styles).length) {
          wrappers[0].info = Object.assign(wrappers[0].info || {}, { style: styles });
          wrappers[0].attrs = wrappers[0].info;
        }
        if (wrappers[0]) {
          wrappers[wrappers.length - 1].addChild(op);
          block.push(wrappers[0]);
        } else {
          block.push(op);
        }
      }
    } else {
      let attr = op.attributes || {};

      let { wrappers, styles } = getStyling(op.attributes);
      for (let wrapper of wrappers) {
        wrapper.startPosition = position--;
        wrapper.importPrefix += '_' + wrapper.tag;
      }

      if (op.insert === '\n') {
        if (attr.tableCol || attr.tableCellLine) {
          if (!treeContext.tableEl) {
            block = block.filter(x => x.tag !== 'text' || x.info.message !== '');
            if (block.length) {
              // this scenario can occur if we {import} a table inline with other text
              addBlockParagraph(styles, attr);
            }
          }

          position = createTableTree(op, domRoot, position, block, treeContext);
          block = [];
          currentList = {
            el: undefined,
            type: 'ordered',
            indent: 0
          };
        } else if (attr.list) {
          attr.indent = attr.indent || 0;
          let li = new ParseNode('el', 'li', { style: styles });
          li.startPosition = position--;
          li.importPrefix += '_li';

          li.addChildren(block);
          if (!currentList.el || (currentList.indent === attr.indent && currentList.type !== attr.list)) {
            let parent = currentRoot;
            if (currentList.el) {
              parent = currentList.el.parent;
            }
            /** @type {ParseNode} */
            let oldEl = currentList.el;
            if (oldEl) {
              // Removing the margin between two inline lists
              oldEl.parent.children[oldEl.parent.children.length - 1].attrs.style.marginBottom = '0px';
            }
            currentList = {
              el: new ParseNode('el', attr.list === 'ordered' ? 'ol' : 'ul', {
                style: {
                  marginTop: currentList.el ? '0px' : undefined
                }
              }),
              type: attr.list,
              indent: attr.indent
            };

            currentList.el.startPosition = position--;
            currentList.el.importPrefix += '_olul';
            if (!oldEl) {
              let e = currentList.el;
              for (let i = attr.indent; i > 0; i--) {
                let tmpParent = new ParseNode('el', attr.list === 'ordered' ? 'ol' : 'ul', {
                  style: {}
                });
                tmpParent.addChild(e);
                tmpParent.startPosition = position--;
                tmpParent.importPrefix += '_tolul';
                e = tmpParent;
              }
            }

            currentList.el.addChild(li);
            parent.addChild(currentList.el.root());
          } else if (currentList.type === attr.list && currentList.indent === attr.indent) {
            currentList.el.addChild(li);
          } else {
            if (currentList.indent > attr.indent) {
              while (currentList.indent > attr.indent) {
                currentList.el.attrs.style.marginBottom = '0px';
                currentList.el = currentList.el.parent;
                currentList.indent--;
              }
              currentList.type = currentList.el.tag === 'ol' ? 'ordered' : 'bullet';

              if (attr.list !== currentList.type) {
                let tmpCurrentList = {
                  el: new ParseNode('el', attr.list === 'ordered' ? 'ol' : 'ul', {
                    style: {
                      marginTop: '0px'
                    }
                  }),
                  type: attr.list,
                  indent: attr.indent
                };
                tmpCurrentList.el.startPosition = position--;
                tmpCurrentList.el.importPrefix += '_ttolul';
                currentList.el.attrs.style.marginBottom = '0px';
                currentList.el.parent.addChild(tmpCurrentList.el);
                currentList = tmpCurrentList;
              }
              currentList.el.addChild(li);
            } else {
              while (currentList.indent < attr.indent) {
                let parent = currentList.el;
                currentList.el = new ParseNode('el', attr.list === 'ordered' ? 'ol' : 'ul', {
                  style: {}
                });
                currentList.el.startPosition = position--;
                currentList.el.importPrefix += '_tttolul';
                currentList.type = attr.list;
                parent.addChild(currentList.el);
                currentList.indent++;
              }
              currentList.el.addChild(li);
            }
          }
        } else {
          addBlockParagraph(styles, attr);
          currentList = {
            el: undefined,
            type: 'ordered',
            indent: 0
          };
         
          if (treeContext.tableEl) {
            treeContext.tableEl = null;
          }
        }
        block = [];
      } else {
        if ('textAlign' in styles) {
          delete styles['textAlign'];
        }
        if (Object.keys(styles).length && !wrappers.length) {
          wrappers.push(new ParseNode('el', 'span'));
          wrappers[0].startPosition = position--;
          wrappers[0].importPrefix += '_span';
        }
        let el = wrappers[0];
        if (el) {
          el.attrs = Object.assign(el.info || {}, { style: styles });
          el.info = el.attrs;
        }
        let node;
        if (typeof op.insert === 'string') {
          node = new ParseNode('expand', 'text', op.insert);
          node.startPosition = position--;
          node.importPrefix += '_t';
        } else {
          if (op.insert['insert-image']) {
            node = new ParseNode('el', 'img', {
              src: op.insert['insert-image'].url,
              width: attr.width,
              height: attr.height,
              style: styles
            });
            node.startPosition = position--;
            node.importPrefix += '_image';
            isStyled = true;
          } else if (
            op.insert['image']
            || op.insert['video']
            || op.insert['code-block']
          ) {
            //Some chrome extension inserted an image. ignore this
            console.warn('Unknown insertion: ' + JSON.stringify(op));
            continue;
          } else {
            throw new Error('Unknown insertion: ' + JSON.stringify(op));
          }
        }
        if (wrappers.length) {
          wrappers[wrappers.length - 1].addChild(node);
        } else {
          el = node;
        }
        block.push(el);
      }
    }
  }

  if (block.length) {
    addBlockParagraph({});
  }

  removeWrapperDiv(domRoot);
  
  domRoot.isStyled = isStyled;
  return domRoot;
}

/**
 * 
 * @param {DeltaOpType} op 
 * @param {ParseNode} domRoot 
 * @param {number} position 
 * @param {ParseNode[]} block 
 * @param {object} treeContext 
 * @param {ParseNode=} treeContext.tableEl
 * @param {ParseNode=} treeContext.colgroupEl
 * @param {ParseNode=} treeContext.tbodyEl
 * @param {ParseNode=} treeContext.rowEl
 * @param {string=} treeContext.currentRow
 * @param {ParseNode=} treeContext.cellEl
 * @param {string=} treeContext.currentCell
 * @param {string[]=} treeContext.columns
 * @param {number=} treeContext.rowCursor
 * @param {number=} treeContext.columnCursor
 * @param {{[key: number]: {[key: number]: boolean}}=} treeContext.skippedCells
 */
function createTableTree(op, domRoot, position, block, treeContext = {}) {
  let attr = op.attributes || {};
  // If there is no table
  if (!treeContext.tableEl
    // If its a tableCol and no colGroupEl,
    // then we need a new table
    || (
      !treeContext.colgroupEl
      && attr.tableCol
    )
  ) {
    if (!treeContext.tableEl
      && !attr.tableCol
    ) {
      console.error('Found a table without tableCol');
    }
    const tableNode = new ParseNode('el', 'table', {
      style: {
        borderCollapse: 'collapse',
        tableLayout: 'fixed'
      }
    });

    const rootDivNode = new ParseNode('el', 'div', {
    });
    rootDivNode.addChild(tableNode);
    rootDivNode.startPosition = position--;
    tableNode.startPosition = position--;
    domRoot.addChild(rootDivNode);

    treeContext.tableEl = tableNode;
    tableNode.attrs.width = 0;

    treeContext.colgroupEl = new ParseNode('el', 'colgroup');
    treeContext.colgroupEl.startPosition = position--;

    tableNode.addChild(treeContext.colgroupEl);

    treeContext.tbodyEl = new ParseNode('el', 'tbody');
    treeContext.tbodyEl.startPosition = position--;

    tableNode.addChild(treeContext.tbodyEl);
    treeContext.columns = [];
    treeContext.currentCell = null;
    treeContext.cellEl = null;
    treeContext.currentRow = null;
    treeContext.rowEl = null;
    treeContext.skippedCells = {};
    treeContext.rowCursor = -1;
  }
  if (attr.tableCol) {
    const col = new ParseNode('el', 'col', {
      style: {
      }
    });
    col.startPosition = position--;
    const width = col.attrs.width = attr.tableCol.width;
    treeContext.colgroupEl.addChild(col);
    if (width) {
      if (width === 'auto') {
        // Table width has been initialized with zero. Remove it.
        delete treeContext.tableEl.attrs.width;
      }
      if (treeContext.tableEl.attrs.width !== undefined) {
        treeContext.tableEl.attrs.width = 
          (treeContext.tableEl.attrs.width || 0) + parseInt(width);
        treeContext.tableEl.info.style.width = treeContext.tableEl.attrs.width + 'px';
      }

      treeContext.columns.push(width);
    }
    setTableAlignment(treeContext.tableEl, attr.tableCol?.align);
  } else if (attr.tableCellLine) {
    //Reset for next table to create
    treeContext.colgroupEl = null;

    let {
      cell,
      colspan,
      row,
      rowspan,
      ...styles
    } = attr.tableCellLine;
    if (!treeContext.rowEl || treeContext.currentRow !== row) {
      treeContext.rowEl = new ParseNode('el', 'tr', {
        style: {
        }
      });
      treeContext.rowEl.startPosition = position--;
      treeContext.currentRow = row;
      treeContext.tbodyEl.addChild(treeContext.rowEl);
      treeContext.currentCell = null;
      treeContext.columnCursor = 0;
      treeContext.rowCursor++;
    }
    while (treeContext.skippedCells[treeContext.rowCursor]?.[treeContext.columnCursor]) {
      treeContext.columnCursor++;
    }
    let cellEl = treeContext.cellEl;
    if (!cellEl || treeContext.currentCell !== cell) {
      //club border styles
      ['Width', 'Style', 'Color'].forEach((style) => {
        let isXSame = false,
          isYSame = false,
          left = styles['borderLeft' + style] || DEFAULT_BORDER_STYLES[style],
          right = styles['borderRight' + style] || DEFAULT_BORDER_STYLES[style],
          top = styles['borderTop' + style] || DEFAULT_BORDER_STYLES[style],
          bottom = styles['borderBottom' + style] || DEFAULT_BORDER_STYLES[style];
        if (left === right) {
          isXSame = true;
        }
        if (top === bottom) {
          isYSame = true;
        }
        if (isXSame && isYSame
          && left === top
        ) {
          styles['border' + style] = top || '1px';

        } else if (isXSame && isYSame) {
          styles['border' + style] = top + ' ' + right;
        } else if (isXSame) {
          styles['border' + style] = top + ' ' + right + ' ' + bottom;
        } else {
          styles['border' + style] = top + ' ' + right + ' ' + bottom + ' ' + left;
        }
        delete styles['borderLeft' + style];
        delete styles['borderRight' + style];
        delete styles['borderTop' + style];
        delete styles['borderBottom' + style];
      });

      if (attr['align']) {
        styles['textAlign'] = attr['align'];
      }
      if (attr['direction']) {
        styles['direction'] = attr['direction'];
      }
      cellEl = treeContext.cellEl = new ParseNode('el', 'td', {
        style: {
          ...DEFAULT_CELL_STYLES,
          ...styles
        }
      });
      cellEl.startPosition = position--;
      cellEl.attrs.colSpan = colspan ? colspan : undefined;
      cellEl.attrs.rowSpan = rowspan ? rowspan : undefined;
      treeContext.rowEl.addChild(cellEl);

      treeContext.currentCell = cell;
      const numColspan = parseInt(colspan, 10) || 1;
      const numRowspan = parseInt(rowspan, 10) || 1;
      let currentColumn = treeContext.columnCursor;
      if (currentColumn < treeContext.columns.length
        && treeContext.columns[currentColumn]) {
        let width = 0;
        for (let index = 0; index < numColspan; index++) {
          const colIndex = treeContext.columnCursor + index,
            colWidth = treeContext.columns[colIndex];
          if (colWidth === 'auto') {
            width = 0;
            break;
          }
          width += parseInt(treeContext.columns[colIndex], 10);
        }
        if (width) {
          cellEl.info.style.width = `${width}px`;
        }
      }
      for (let rowIndex = 1; rowIndex < numRowspan; rowIndex++) {
        const rowInSkippedCells = treeContext.skippedCells[treeContext.rowCursor + rowIndex] = treeContext.skippedCells[treeContext.rowCursor + rowIndex] || {};
        for (let colIndex = 0; colIndex < numColspan; colIndex++) {
          rowInSkippedCells[currentColumn + colIndex] = true;
        }
      }
      treeContext.columnCursor += numColspan;
    } else {
      const newLine = new ParseNode('el', 'br');
      newLine.startPosition = position--;
      cellEl.addChild(newLine);
    }
    cellEl.addChildren(block);
  }
  return position;
}

/**
 * For the approaches used, please follow.
 * https://docs.google.com/document/d/1E01__4_74rI0UuVEykE3C79v4y7k2rjylVZFAwxNByo/edit
 * 
 * @param {ParseNode} tableEl 
 * @param {('left' | 'center' | 'right')} alignment 
 */
function setTableAlignment(tableEl, alignment) {
  const tableStyles = tableEl.info.style;
  
  if (alignment === 'left') {
    // remove styles
    delete tableStyles['marginLeft'];
    delete tableStyles['marginRight'];
    delete tableEl.parent.attrs.align;
  }
  if (!['right', 'center'].includes(alignment)) {
    return;
  }

  tableStyles['marginLeft'] = 'auto';
  if (alignment === 'center') {
    tableStyles['marginRight'] = 'auto';
  }
  tableEl.parent.attrs.align = alignment;
}


/**
 * Remove the outer div in case it is unnecessary. E.g. only one child who is a 'p'.
 * 
 * @param {ParseNode} dom
 * @returns {void}
 */
function removeWrapperDiv(dom) {
  if (dom.children && dom.children.length === 1) {
    let child = dom.children[0];
    if (child.tag === 'p' && child.info) {
      if (child.children.length === 0) {
        dom.removeChild(child);
        return;
      } else {
        let styles = child.info.style;
        if (styles) {
          // Block styles should remain as <p>
          if (Object.keys(styles).length) {
            return;
          }
        }
        dom.removeChild(child);
        // dom.addChildren is relatively slow for this,
        // it was taking ~3% of snippet time.
        // So use this to optimize re-parenting.
        dom.children = child.children;
        child.children = [];
        for (let c of dom.children) {
          c.parent = dom;
        }
      }
    }
  }
}


/**
 * @param {Environment=} env - environment object
 * @returns {void}
 */
function generateCommandCache(env) {
  let commands = /** @type {Object<string, import('./Commands').CommandDef|ActiveAddonType>} */ (COMMANDS);
  
  if (env.config.addons && Object.keys(env.config.addons).length) {
    commands = Object.assign({}, commands);

    for (let key in env.config.addons) {
      commands[key.toUpperCase()] = env.config.addons[key]; 
    }
  }

  if (env.config.extraTestCommands && Object.keys(env.config.extraTestCommands).length) {
    Object.assign(commands, env.config.extraTestCommands);
  }

  // Command names should be sorted longest to shortest otherwise a shorter
  // one could trigger when a longer one needs to match.
  let entries = Object.entries(commands).sort((a, b) => b[0].length - a[0].length);
  let keys = [], keyAttributes = [];
  for (let entry of entries) {
    keys.push(entry[0]);
    if (entry[1].invalidIn !== 'attribute') {
      keyAttributes.push(entry[0]);
    }
  }
  
  env.config.commandCache = {
    commands,
    validAnywhere: keys,
    validInAttributes: keyAttributes
  };
}

// Are we running the test environment
const IS_TEST = typeof vi !== 'undefined';

/**
 * Parse Blaze text.
 * 
 * @param {string} str
 * @param {string} mode
 * @param {Environment} env - environment object
 * 
 * @return {Promise<ParseNode[]>} the resulting parsed nodes
 */
async function parseText(str, mode, env) {
  let nodes = [];
  let initialLength = str.length;
  let emitPosition = 0;
  let nextIsBlock = null;
  let consumeBuffer = '';
  let lastConsumed = '';
  /** @type {{ node: ParseNode, type: string}[]} */
  let blockCommands = [];

  /**
   * @param {ParseNode} command
   */
  function rejectBlock(command) {
    command.tag = 'text';
    command.info = { message: command.info.raw };
    nodes.splice(nodes.indexOf(command), 1);
  }

  /**
   * @param {ParseNode} command
   * @returns {void}
   */
  function addBlock(type, command) {
    let [tag, portion] = type.split('_');
    if (portion === 'start') {
      blockCommands.push({
        node: command,
        type
      });
      return;
    } else {
      if (!blockCommands.length) {
        rejectBlock(command);
        return;
      }
      let lastCommand = blockCommands[blockCommands.length - 1];
      let [lastTag, lastPortion] = lastCommand.type.split('_');
      while (lastTag !== tag && lastTag === 'toggle') {
        blockCommands.pop().node.info.end = null;
        if (!blockCommands.length) {
          rejectBlock(command);
          return;
        }
        lastCommand = blockCommands[blockCommands.length - 1];
        [lastTag, lastPortion] = lastCommand.type.split('_');
      }
      if (lastTag !== tag) {
        rejectBlock(command);
        return;
      }
      if (!lastCommand.node.info.type) {
        // The start is an error
        if (!(portion === 'else' || portion === 'elseif')) {
          blockCommands.pop();
        }
        rejectBlock(command);
        return;
      }
      let isError = !command.info.type;
      if (tag === 'toggle' || tag === 'note' || tag === 'repeat' || tag === 'link' || tag === 'action') {
        if (isError) {
          rejectBlock(blockCommands.pop().node);
          return;
        }
        blockCommands.pop().node.info.end = command;
        command.info.start = lastCommand.node;
        return;
      } else if (tag === 'if') {
        let start;
        if (lastPortion === 'start') {
          start = lastCommand.node;
        } else {
          start = lastCommand.node.info.start;
        }

        if (portion === 'else') {
          if (isError) {
            return;
          }
          if (lastPortion === 'start' || lastPortion === 'elseif') {
            start.info.else = command;
            command.info.start = start;
            blockCommands.push({ node: command, type });
          } else {
            rejectBlock(command);
          }
        } else if (portion === 'elseif') {
          if (isError) {
            return;
          }
          if (lastPortion === 'start' || lastPortion === 'elseif') {
            command.info.start = start;
            blockCommands.push({
              node: command,
              type
            });
          } else {
            rejectBlock(command);
          }
        } else if (portion === 'end') {
          if (isError) {
            rejectBlock(blockCommands.pop().node);
            return;
          }
          
          command.info.start = start;

          let lastC = blockCommands.pop();
          while (lastC.node !== start) {
            lastC.node.info.end = command;
            lastC = blockCommands.pop();
          }
          lastC.node.info.end = command;
        }
      }
    }
  }

  /**
   * Create an expand node in the stream.
   * 
   * @param {string} type - node type
   * @param {string|import('./ParseNode').InfoType} info 
   * @param {Set=} dependencies 
   */
  async function emit(type, info, dependencies) {
    if (typeof info !== 'string') {
      info.raw = consumeBuffer;
    }
    
    if (consumeBuffer.includes(BREAK_TAG)) {
      info = consumeBuffer[0];
      str = consumeBuffer.slice(1) + str;
      type = 'text';
    }
    
    let endPosition = initialLength - str.length;
    let n = type === 'error' ? new ParseNode('error', undefined, info) : new ParseNode('expand', type, info);
    n.startPosition = emitPosition;
    n.endPosition = endPosition;
    
    if (IS_TEST) {
      // For testing - detects invalid hierarchies in the `locations`
      // Note if this occurs, that means there is a bug.
      //
      // Disable when not testing due to perf impact.
      for (let i = 1; i < env.locations.length - 1; i++) {
        if (env.locations[i].startsWith('local_data -') && env.locations[i] !== 'local_data - root') {
          for (let j = 0; j < i; j++) {
            if (!env.locations[i].includes(env.locations[j])) {
              console.log('INVALID_LOCATIONS', env.locations);
              console.log((new Error()).stack);
              throw new Error('Invalid location path');
            }
          }
        }
      }
    }

    // Get dependencies (also used in suggestion bar)

    let storeId = 'root';
    for (let i = env.locations.length - 1; i >= 0; i--) {
      if (env.locations[i].startsWith('local_data - ')) {
        storeId = env.locations[i].slice('local_data - '.length);
        break;
      }
    }

    if (dependencies && storeId !== 'root') {
      dependencies = new Set([...dependencies].map(dep => {
        return storeId + ' %% ' + dep;
      }));
    }
    n.dependencies = dependencies;

    if ((typeof info !== 'string') && info.attributes) {
      for (let attr of info.attributes.position) {
        let deps = await attr.getDependencies(env);
        if (deps) {
          n.addDependencies(new Set([...deps].map(dep => {
            if (dep.includes('%%')) {
              return dep;
            }
            return storeId  + ' %% ' + dep;
          })));
        }
      }
    }
    
  
    if (type !== 'text') {
      nodes.push(n);
    }
    emitPosition = endPosition;

    if (nextIsBlock) {
      if (type !== 'text') {
        addBlock(nextIsBlock, n);
      }
      nextIsBlock = null;
    }
    return n;
  }

  /**
   * 
   * @param {string[]|string} items 
   * @param {boolean} throwOnFailure 
   * @returns {string}
   */
  function consume(items, throwOnFailure = false) {
    if (!Array.isArray(items)) {
      items = [items];
    }
    for (let item of items) {
      if (str.startsWith(item)) {
        str = str.substr(item.length);
        lastConsumed = item;
        consumeBuffer += lastConsumed;
        return item;
      }
    }

    if (throwOnFailure) {
      throw new ParseError();
    }
  }

  function clearConsumeBuffer() {
    consumeBuffer = '';
  }


  /**
   * @param {import('./Commands').CommandSpecDef | AddonSpecType} spec 
   * @param {boolean} hasAttrs 
   * @param {import('./ParserUtils').AttributesType} origAttrs 
   * @param {number} attrStartPosition 
   */
  function consumeAttributes(spec, hasAttrs, origAttrs, attrStartPosition = 0) {
    /** @type {NodeAttribute[]} */
    let attrs;
    if (hasAttrs) {
      let blocks = getAttributeBlocks(str, spec, env, attrStartPosition);
      attrs = blocks.attributes;
      consume(blocks.consumed);
    } else {
      attrs = [];
      consume('}');
    }

    Object.assign(origAttrs.position, attrs);


    /** @type {import('./ParserUtils').AttributesType['keys']} */
    let keys = {};
    attrs.forEach(attr => {
      if (attr.name) {
        keys[attr.name] = attr;
      }
    });
    origAttrs.keys = keys;
  
    attrs.forEach((attr, i) => {
      if (attr.name !== null) {
        let base = spec.named[attr.name];
        if (!base) {
          throw new ParseError(`Unknown setting "${attr.name}"`);
        } else {
          attr.list = base.list;
          if (attr.list && !['positional', 'keys'].includes(attr.list)) {
            throw new ParseError(`Unknown list type "${attr.list}"`);
          }
          attr.type = base.type;
          attr.config = base.config;
          attr.typeError = base.typeError;
          attr.constant = base.constant || false;
          attr.repeatable = base.repeatable;
        }
      } else {
        attr.positional = true;
        if (spec.positionalDef) {
          if ('constant' in spec.positionalDef) {
            attr.constant = spec.positionalDef.constant;
          }
          if (i === 0 && 'name' in spec.positionalDef) {
            attr.name = spec.positionalDef.name;
          }
          if ('type' in spec.positionalDef) {
            attr.type = spec.positionalDef.type;
          }
          if ('config' in spec.positionalDef) {
            attr.config = spec.positionalDef.config;
          }
        }
      }
    });

    if ('isAddon' in spec && spec.isAddon) {
      // Fill in addon attribute defaults
      if (spec.positionalDef) {
        if (attrs[0] && (!attrs[0].name || attrs[0].positional)) {
          if (spec.positionalDef.name) {
            attrs[0].name = spec.positionalDef.name;
            attrs[0].type = spec.positionalDef.type;
            attrs[0].config = spec.positionalDef.config;
            attrs[0].positional = true;
            attrs[0].list = (spec.positionalDef.list && spec.positionalDef.type !== 'lambda' && !Array.isArray(spec.positionalDef.type)) ? spec.positionalDef.list : undefined;
          }
        } else {
          if (spec.positionalDef.required) {
            throw new ParseError(`Setting "${spec.positionalDef.name}" is required`);
          }
        }
      }

      for (let name in spec.named) {
        if (name !== 'trim' && !attrs.find(x => x.name === name)) {
          if (spec.named[name].required) {
            throw new ParseError(`Setting "${name}" is required`);
          } else {
            let attr = new NodeAttribute({
              name,
              list: (spec.named[name].list && spec.named[name].type !== 'lambda' && !Array.isArray(spec.named[name].type)) ? spec.named[name].list : undefined,
              value: spec.named[name].default,
              mode: 'attribute',
              type: spec.named[name].type,
              config: spec.named[name].config,
              fillIn: true
            });
            attrs.push(attr);
            keys[attr.name] = attr;
          }
        }
      }
    }

    
    Object.assign(origAttrs.position, attrs);


    // Don't allow re-declaring keys (except for attributes marked as repeatable)
    let names = {};
    attrs.forEach(attr => {
      if (attr.name && !attr.positional && !attr.repeatable) {
        if (attr.name in names) {
          throw new ParseError(`The setting "${attr.name}" cannot be repeated`);
        }
        names[attr.name] = true;
      }
    });
    
    
    // Validate the attributes
    let lastPositional = 0;
    for (let i = 0; i < attrs.length; i++) {
      if ((!attrs[i].name) || attrs[i].positional) {
        lastPositional = i + 1;
      }
    }
      
    // Ensure the right number of position arguments;
    if (spec.positional[0] && lastPositional < spec.positional[0]) {
      if (spec.positional[0] === spec.positional[1]) {
        throw new ParseError(`Must have ${spec.positional[0]} positional settings`);
      } else {
        throw new ParseError(`Must have at least ${spec.positional[0]} positional settings`);
      }
    }
    if (lastPositional && !spec.positional[1]) {
      throw new ParseError('Cannot have positional settings');
    } else if (lastPositional > spec.positional[1]) {
      throw new ParseError(`Must have at most ${spec.positional[1]} positional settings`);
    }

    for (let name in spec.named) {
      if (spec.named[name].required && !(name in keys)) {
        throw new ParseError(`The setting "${name}" is required`);
      }
    }

    let res = {
      keys,
      position: attrs,
      spec
    };

    if ('rejectFn' in spec && spec.rejectFn) {
      if (spec.rejectFn(res)) {
        throw new ParseError(spec.rejectFn(res));
      }
    }
  }

  if (!env.config.commandCache) {
    generateCommandCache(env);
  }

  /** @type {string} */
  let addonNamespaceCache = undefined;
  let addonNamespace = () => {
    if (addonNamespaceCache === undefined) {
      if (env.config.addonNamespace) {
        addonNamespaceCache = env.config.addonNamespace.toUpperCase();
      } else {
        let addonStr = env.locations.find(x => x.includes('addon['));
        if (addonStr) {
          let match = addonStr.match(/addon\[(.+?)-.+\]/);
          if (match) {
            addonNamespaceCache = match[1].toUpperCase();
          } else {
            addonNamespaceCache = null;
          }
        } else {
          addonNamespaceCache = null;
        }
      }
    }
    return addonNamespaceCache;
  };

  const VALID_COMMANDS = (mode === 'attribute' ? env.config.commandCache.validInAttributes : env.config.commandCache.validAnywhere).filter(x => {
    let command = env.config.commandCache.commands[x];
    
    if (command.invalidIn === 'addon') {
      // Commands that can't be used in addons (e.g. potentially something like {import})
      if (addonNamespace()) {
        return false;
      }
    }

    if (mode === 'addon') {
      if (command.invalidIn === 'attribute') {
        // Addon type should ban all invalidin 'attribute' except for {if}, {repeat} and {note}
        if (!(x === 'IF' || x === 'ELSEIF' || x === 'ELSE' || x === 'ENDIF' || x === 'REPEAT' || x === 'ENDREPEAT' || x === 'NOTE' || x === 'ENDNOTE')) {
          return false;
        }
      }
    }

    // Addons can only include other addons from the same namespace
    if (addonNamespace() && x.includes('-')) {
      if (!x.startsWith(addonNamespace() + '-')) {
        return false;
      }
    }

    if (env.config.usedCommandsWhitelist) {
      let c = x;
      if (c === 'ENDREPEAT') {
        c = 'REPEAT';
      } else if (c === 'ELSEIF' || c === 'ELSE' || c === 'ENDIF') {
        c = 'IF';
      } else if (c === 'ENDLINK') {
        c = 'LINK';
      } else if (c === 'ENDACTION') {
        c = 'ACTION';
      } else if (c === 'ENDFORMTOGGLE') {
        c = 'FORMTOGGLE';
      } else if (c === 'ENDNOTE') {
        c = 'NOTE';
      }

      if (env.config.commandWhitelist && !env.config.commandWhitelist.includes(c)) {
        // We don't remove it if the user chose not to exclude it from the whitelisted as
        // we want an "admin denied" error to show up, rather than it just not working.
        return true;
      }

      if (!env.config.usedCommandsWhitelist.includes(c)) {
        // This command was not used by the user, so it's not a valid command.
        // So we filter it out.
        return false;
      }
    }

    return true;
  });


  let BANNED_COMMANDS = [];
  if (env.config.commandWhitelist) {
    BANNED_COMMANDS = VALID_COMMANDS.filter(key => {
      let whitelisted = false;
      for (let white of env.config.commandWhitelist) {
        if (key === white || key === 'END' + white || (key === 'ELSE' && white === 'IF') || (key === 'ELSEIF' && white === 'IF')) {
          whitelisted = true;
          break;
        }
      }
      return !whitelisted;
    });
  }

  while (str.length > 0) {
    /** @type {import('./ParserUtils').AttributesType} */
    let attrs;
    let command;
    let commandText;
    try {
      if (str[0] === '{') {

        let matched;
          
        for (let key of VALID_COMMANDS) {
          command = env.config.commandCache.commands[key];
          nextIsBlock = 'block' in command && command.block && command.subType;
          commandText = key;
          if (consume('{' + key.toLowerCase())) {
            // Bad error, so...
            // @ts-ignore
            if (str[0] !== ':' && str[0] !== '}' && key !== '=') {
              str = lastConsumed + str;
              consumeBuffer = '';
              break;
            }
            attrs = {
              position: [],
              keys: {},
              spec: command.attributes
            };

            if (command.attributes) {
              let spec = command.attributes;
              try {
                let hasAttrs = ('bare' in command && command.bare) || consume(':');
                if (hasAttrs || ('addon' in command)) {
                  consumeAttributes(spec, !!hasAttrs, attrs, emitPosition + consumeBuffer.length);
                } else if (spec.positional[0]) {
                  if (consume('}')) {
                    if (spec.positionalDef) {
                      throw new ParseError('You need to specify the ' + spec.positionalDef.name);
                    }
                  } else {
                    throw new ParseError();
                  }
                } else if (('rejectFn' in spec) && spec.rejectFn && spec.rejectFn(attrs)) {
                  if (consume('}')) {
                    throw new ParseError(spec.rejectFn(attrs));
                  } else {
                    throw new ParseError();
                  }
                } else {
                  consume('}', true);
                }
              } catch (err) {
                if ((err instanceof ParseError) && err.message) {
                  throw new ParseError(err.message);
                } else {
                  throw err;
                }
              }
            } else {
              consume('}', true);
            }

            if (BANNED_COMMANDS.includes(key)) {
              if (env.config.commandWhitelistErrorFn) {
                throw new ParseError(env.config.commandWhitelistErrorFn(key.toLowerCase()));
              } else {
                throw new ParseError(`The command {${key.toLowerCase()}} is not allowed by your organization. Please contact your Text Blaze administrator.`);
              }
            }

            if ('addon' in command) {
              if (command.addon.addonOptions.connected) {
                if (env.config.connectedAddonWhitelist) {
                  if (!env.config.connectedAddonWhitelist.includes(key.toLowerCase())) {
                    throw new ParseError(fillErrorTemplate(env.config.connectedAddonWhitelistErrorTemplate || 'The command {command} is not Connected.', { command: '{' + key.toLowerCase() + '}' }));
                  }
                }
              }
              let error = quickValidateAddonSpec({
                positional: command.attributes.positionalDef && [command.attributes.positionalDef],
                named: command.attributes.namedDef
              });
              if (error) {
                throw new ParseError(error);
              } else {
                let valid = true;
                /** @type {string[]} */
                let validDomains;
                // env.config.domain will be null when tokenizing
                if (env.config.domain !== null && command.addon.data.options
                  && command.addon.data.options.addon
                  && command.addon.data.options.addon.display
                  && command.addon.data.options.addon.display.valid_hosts) {

                  validDomains = command.addon.data.options.addon.display.valid_hosts;

                  if (validDomains.length && (env.config.stage !== 'tokenization')) {
                    valid = false;
                    for (let domain of validDomains) {
                      if (env.config.domain === domain || env.config.domain.endsWith('.' + domain)) {
                        valid = true;
                        break;
                      }
                    }
                  }
                }
                if (valid) {
                  await emit('addon', {
                    addon: command.addon,
                    addonConfigData: command.addonConfigData,
                    approvedGrants: command.approvedGrants,
                    command: command.command,
                    name: command.name,
                    spec: command.attributes,
                    attributes: attrs
                  });
                } else {
                  /** @type {(command: ActiveAddonType) => string} */
                  const defaultErrorFn = (command) => isElectronApp() ? `The command "${command.name}" may only be used in our browser extension` : `The command "${command.name}" may only be used on ${/** @type {string[]} */ (validDomains).join(',')}`;
                  const errorFn = env.config.invalidDomainErrorFn || defaultErrorFn;

                  await emit('error', {
                    message: errorFn(command),
                    chicklet: command.name,
                    node: /** @type {any} */ ({
                      tag: command.name,
                      info: {
                        command: commandText,
                        attributes: attrs
                      }
                    })
                  });
                }
              }
            } else {
              await command.fn(attrs, emit, env, !!addonNamespace());
            }
            matched = true;
            break;
          }
        }

        if (!matched) {

          if (env.config.installableAddons) {
            // if we have specified installable addons, see if it is one
            for (let key of Object.keys(env.config.installableAddons)) {
              if (consume('{' + key + '-')) {
                env.config.missingAddons = env.config.missingAddons || {};
                if (!env.config.missingAddons[key]) {
                  env.config.missingAddons[key] = env.config.installableAddons[key];
                }
                matched = true;
                break;
              }
            }
          }

          // Look for an assignment
          if (mode !== 'attribute' && str.includes('=') && VALID_COMMANDS.includes('=')) {
            nextIsBlock = null;
            
            /** @type {string} */
            let cmd = null;
            /** @type {string} */
            let name = null;

            let match = str.match(/^{([A-Za-z][A-Za-z_0-9]*)=/);
            if (match) {
              cmd = match[1];
              name = cmd;
            }
            if (!cmd && str[1] === '`') {
              // Note `lex` trims the string by default so if we don't check that the first char is '`',
              // there is potential for an infinite loop or other breakage.
              let lexed = lex(str.slice(1), env, { singleToken: true });
              let token = lexed.tokens[0];
              if (
                lexed.termination === 'SINGLE_TOKEN'
                && token.type === 'IDENTIFIER'
                && str[lexed.position + 1] === '='
              ) {
                cmd = token.source;
                name = token.identifier;
              }
            }

              
            if (cmd) {
              consume('{' + cmd + '=');
              command = COMMANDS['='];
              commandText = '=';
              attrs = {
                position: [],
                keys: {},
                spec: command.attributes
              };
              consumeAttributes(command.attributes, true, attrs, emitPosition + consumeBuffer.length);
              attrs.keys['name'] = new NodeAttribute({
                name: 'name',
                value: name,
                stage: 'final',
                constant: true,
                raw: cmd
              });
              attrs.keys['silent'] = new NodeAttribute({
                name: 'silent',
                value: true,
                stage: 'final',
                constant: true
              });

              if (BANNED_COMMANDS.includes('=')) {
                if (env.config.commandWhitelistErrorFn) {
                  throw new ParseError(env.config.commandWhitelistErrorFn('='));
                } else {
                  throw new ParseError('Formula assignment with the command ({=}) is not allowed by your organization. Please contact your Text Blaze administrator.');
                }
              }

              await command.fn(attrs, emit);
              matched = true;
            }
          }
        }

        if (!matched) {
          nextIsBlock = null;
          let chr = str[0];
          str = str.slice(1);
          await emit('text', chr);
        }
        clearConsumeBuffer();
      } else {
        nextIsBlock = null;
        let chr = str[0];
        str = str.slice(1);
        await emit('text', chr);
      }
    } catch (err) {
      if (err instanceof ParseError) {
        if (err.message) {
          await emit('error', {
            message: err.message,
            node: /** @type {any} */ ({
              tag: 'tag' in command ? command.tag : command.name,
              info: {
                command: commandText,
                attributes: attrs,
                type: (command && 'subType' in command) ? command.subType : undefined
              }
            })
          });
        } else {
          await emit('text', consumeBuffer);
        }
        clearConsumeBuffer();
      } else if (err.message && err.message.indexOf('nearley') === 0) {
        await emit('error', 'Invalid Formula');
        clearConsumeBuffer();
      } else {
        throw err;
      }
    }
  }

  blockCommands.forEach(command => {
    let tag = command.type.split('_')[0];
    if (tag === 'toggle') {
      command.node.info.end = null;
    } else {
      rejectBlock(command.node);
    }
  });

  for (let node of nodes) {
    if (node instanceof ParseNode) {
      if (node.info.raw) {
        delete node.info.raw;
      }
    }
  }

  return nodes;
}