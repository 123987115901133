import { captureException } from '@sentry/browser';
import { DESKTOP_PROTOCOL, isElectronApp } from './flags';
import { PROPAGATE_ERROR, getDataFromClient } from './desktop_shared_utils';
import { getSiteItemGroupingKey, isEmptyInsert } from './snippet_processor/DownstreamProcess';
import { getHTMLInsertionStats } from './shared_utilities';

const CURSOR_PLACEHOLDER = '[{!~TB!!/]';
let IS_EXTENSION_DETECTED = false;

/**
 * 
 * @param {boolean} value 
 */
export function setExtensionDetected(value) {
  IS_EXTENSION_DETECTED = value;
}

/**
 * @param {(string|{tag: string, type: string})[]} parts 
 * @return {{lastCursorIndex: boolean, cleanedParts: string[]}}
 */
function processLastCursor(parts) {
  /** @type {string[]} */
  const cleanedParts = [];
  /** @type {boolean} */
  let lastCursorIndex = false;
  for (let i = parts.length - 1; i >= 0; i--) {
    const part = parts[i];
    if ((typeof part !== 'string') && part.tag === 'cursor') {
      if (!lastCursorIndex) {
        cleanedParts.push(CURSOR_PLACEHOLDER);
        lastCursorIndex = true;
      }
    }
    if (typeof part === 'string') {
      cleanedParts.push(part);
    }
  }
  cleanedParts.reverse();
  return { lastCursorIndex, cleanedParts };
}

export function findCursorPlacementAndEmptyInsert(res, isForm = false) {
  /** @type {import('./snippet_processor/DownstreamProcess').ReplacementPart[]} */
  const replacements = res.replacement;
  for (let i = 0; i < replacements.length; i++) {
    /** @type { { textStr: string, htmlStr?: string} } */
    let running = {
      textStr: ''
    };
    const replacement = replacements[i];
    if (replacement.type === 'string') {
      if (replacement.htmlStrArr) {
        running.htmlStr = '';
        let htmlProcessed = processLastCursor(replacement.htmlStrArr);
        for (const part of htmlProcessed.cleanedParts) {
          running.htmlStr += part;
        }
        if (htmlProcessed.lastCursorIndex) {
          let { count } = getHTMLInsertionStats(running.htmlStr, CURSOR_PLACEHOLDER, true, isForm);
          replacement.snippetCursorMoveCount = count;
        }
        replacement.isEmpty = isEmptyInsert('html', running);
      } else {
        running.textStr = '';
        let textProcessed = processLastCursor(replacement.textStrArr);
        for (const part of textProcessed.cleanedParts) {
          running.textStr += part;
        }
        if (textProcessed.lastCursorIndex) {
          let { count } = getHTMLInsertionStats(running.textStr, CURSOR_PLACEHOLDER, false, isForm);
          replacement.snippetCursorMoveCount = count;
        }
        replacement.isEmpty = isEmptyInsert('text', running);
      }
    }
  }
}

/**
 * @typedef {{ type: 'log-uid', data: string } | 
 * { type: 'send-assistant-data', data: string } | 
 * { type: 'send-snippet-on-request', data: string } | 
 * { type: 'show-message', data: { title: string, message: string } | { content: string, showUpgrade?: boolean, installExtension?: boolean }} | 
 * { type: 'auth-status', data: { loggedIn: boolean, email?: string, uid?: string }} | 
 * { type: 'replacement-data', data: import('./store_platform').ReplacementData } | 
 * { type: 'form-data', data: { nextFormData: import('./snippet_processor/DownstreamProcess').NextFormDataType, locale: string, windowHandle: number }} | 
 * { type: 'do-side-channel-blockdata', data: { sideChannel: Parameters<import('./snippet_processor/DownstreamProcess').NativeSideChannelFn>[0], blockData: Parameters<import('./snippet_processor/DownstreamProcess').NativeSideChannelFn>[1] }} |
 * { type: 'do-side-channel', data: Parameters<import('./snippet_processor/DownstreamProcess').NativeSideChannelFn>[0] } |
 * { type: 'id-token', data: { fbToken: string } } |
 * { type: 'credentials', data: { credentials: Required<{ token: string }> | {}, error: string }} |
 * { type: 'user-settings-data', data: object } |
 * { type: 'empty-replacement-response', data: boolean } |
 * { type: 'process-replacement-response', data: { textString: string, htmlString: string }} |
 * { type: 'load-ai-window', insertionType: import('./store_platform').InsertionType } |
 * { type: 'ai-data', data: { formId: number, insertionType: import('./store_platform').InsertionType, aiData: { windowHandle: number, shortcutToClear: string }}} |
 * { type: 'restricted-apps', data: Array<string> } |
 * { type: 'autowrite', data: import('./store_platform').AISnippetDataType } |
 * { type: 'autowrite', data: { model: import('./components/AppEmbed/App').AIModelType }} |
 * { type: 'get_credentials', data: { email: string, token: string, uid: string } | { provider: 'JWT', data: string }} |
 * { type: 'get-snippets-by-shortcut', data: SnippetObjectType[] }
 * } CheckerReceiveMessageType
 * 
 * @typedef {{ type: 'close-assistant' } | 
 * { type: 'edit-snippet', data: string } | 
 * { type: 'new-snippet' } | 
 * { type: 'open-dashboard' } | 
 * { type: 'insert-snippet', data: { id: string }}
 * } AssistantReceiveMessageType
 * 
 * @typedef {{ type: 'form-finish-main', data: import('./components/AppDesktop/AppForm').FormDataType } | 
 * { type: 'close-form-main' } | 
 * { type: 'form-request-snippet', data: string } | 
 * { type: 'do-side-channel-blockdata', data: { sideChannel: Parameters<import('./snippet_processor/DownstreamProcess').NativeSideChannelFn>[0], blockData: Parameters<import('./snippet_processor/DownstreamProcess').NativeSideChannelFn>[1] }} |
 * { type: 'copy-last-form-data' } |
 * { type: 'form-dirty', data: { formId: string, dirty: boolean }}
 * } FormReceiveMessageType
 * 
 * @typedef {{ type: 'signout' } | 
 * { type: 'try-me-box', data: boolean } | 
 * { type: 'editable-focused', data: boolean } | 
 * { type: 'enable-debug', data: boolean } | 
 * { type: 'upgrade' } |
 * { type: 'rate-ms-store' } |
 * { type: 'confirm-checker' } | 
 * { type: 'assistantAction', subType: 'insert-text' | 'copy' | 'create-snippet' | 'regenerate', data: import('./components/AutoWriteChat/AutoWriteChat').SendActionDownstreamDataType, isWebpageEmbedded: boolean}
 * } DashboardReceiveMessageType
 * 
 * @typedef {{ type: 'assistantAction', subType: 'insert-text' | 'copy' | 'create-snippet' | 'regenerate', data: import('./components/AutoWriteChat/AutoWriteChat').SendActionDownstreamDataType, isWebpageEmbedded: boolean} |
 * { type: 'autowrite', subType: 'chatLoaded' } |
 * { type: 'autowrite', subType: 'close', data: import('./components/AutoWriteChat/AutoWriteChat').CloseAIChatPopupConfig } |
 * { type: 'route-to-checker', message: { type: 'autowrite', subType: 'toggleConfig', name: string }} |
 * { type: 'route-to-checker', message: { type: 'autowrite', subType: 'saveModel', model: import('./components/AppEmbed/App').AIModelType }} |
 * { type: 'copy-text', text: string }
 * } AIReceiveMessageType
 * 
 * @typedef {{ type: 'autowrite', subType: 'closeSidebar' | 'sidebarLoaded' } |
 * { type: 'route-to-checker', message: { type: 'autowrite', subType: 'saveModel', model: import('./components/AppEmbed/App').AIModelType }} |
 * { type: 'insert_replacement', snippet_id?: string, details?: SnippetTextInsertionDetails } |
 * { type: 'open-url', data: string }
 * } SidebarReceiveMessageType
 * 
 * @typedef {{ type: 'listeners-attached' }} CommonReceiveMessageType
 * 
 * @param {CheckerReceiveMessageType | AssistantReceiveMessageType | FormReceiveMessageType | DashboardReceiveMessageType | CommonReceiveMessageType | AIReceiveMessageType | SidebarReceiveMessageType} data
 */
export function sendMessageToClient(data) {
  // send one way message to client - for now it is just electron app but it can be extended for other platforms
  if (isElectronApp()) {
    const electron = window['electronAPI'];
    if (electron.sendMsg) {
      // new binary
      electron.sendMsg(data);
    } else {
      captureException('sendMsg API not defined');
    }
  }
}

/**
 * 
 * @param {string} url 
 */
export function navigateToURL(url) {
  sendMessageToClient({
    type: 'open-url',
    data: url
  });
}

/** @type {import('./snippet_processor/DownstreamProcess').NativeGetClipboardFn} */
export function getClipboard() {
  const errorStr = 'CLIPBOARD ERROR';
  return getDataFromClient({
    type: 'clipboard-request'
  }, { text: errorStr, html: errorStr });
}

/**
 * 
 * @returns {{ error: string }}
 */
export function getSiteCommandResponseForDesktop() {
  // We are showing different error messages in preview for {site} command based on if the desktop app has detected the extension or not
  const error = IS_EXTENSION_DETECTED ? '{site} command preview will only work in the browser.' : '{site} command is only supported in our browser extension.';
  return { error };
}

/** @type {import('./snippet_processor/DownstreamProcess').NativeGetAllTabsDataFn} */
export function getSiteData(items, tabId, frameId) {
  return Promise.resolve({ selectorData: items.map((item, index) => ({
    tabId: 0,
    data: [{ res: getSiteCommandResponseForDesktop(), index, item }],
    item,
    title: 'Not found',
    favicon: null
  })), needsTabSelectInSiteCommand: false, usedSiteTabSelections: items.reduce((obj, item, index) => ({
    ...obj,
    [getSiteItemGroupingKey(item)]: {
      tabId: 0,
      res: [...(obj[getSiteItemGroupingKey(item)]?.res || []), { res: getSiteCommandResponseForDesktop(), index, item }]
    },
  }), {}), });
}

/** @type {import('./snippet_processor/DownstreamProcess').NativeSideChannelFn} */
export function processSideChannelItems(sideChannel, blockData) {
  // For backward compatibility, we are sending two messages
  // TODO: Deprecate old binaries then remove the first send message
  sendMessageToClient({
    type: 'do-side-channel',
    data: sideChannel
  });

  sendMessageToClient({
    type: 'do-side-channel-blockdata',
    data: { sideChannel, blockData }
  });
}

/** @type {import('./snippet_processor/DownstreamProcess').NativeRemoteCommandFn} */
export function handleRemoteCommand(info) {
  const type = info.type;
  return getDataFromClient({
    type: 'remote-command-request',
    data: { type, info }
  }, PROPAGATE_ERROR);
}

/**
 * Open desktop app via deeplink by removing
 * the protocol and host prefix, and forwarding
 * pathname, hash, and search parameters
 * @param {string} url 
 */
export function openDashboardLinkInDesktop(url) {
  const stripPrefix = new URL(url).origin;
  // Replace only the first occurrence intentionally
  const urlSuffix = url.replace(stripPrefix, '');
  const deepLinkURL = DESKTOP_PROTOCOL + '://tbsite/' + urlSuffix;
  // Do not use window.open because it will fail 
  // in case popup permission are not granted
  // Note: this doesn't actually update the browser href
  window.location.href = deepLinkURL;
}

export function writeToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function readFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}