import { newName, ParseError } from './ParserUtils';
import { getLocale } from '../locales';
import { shiftsToSeconds } from './time_shift';
import moment from 'moment';
import { getOS } from '../engine_utilities';
import { astSQL } from './SQL';
import { findType, toStr } from './Equation';
import equal from 'fast-deep-equal';
import { isAddonCommand } from '../utilities';
import { parse } from './Parser';
import { domToStream, fillDom, trimDom, trimNotes } from './SnippetProcessor';
import { escapeBSQLName } from '../components/Database/bsql_utilities';
import { snippetOrPrompt } from '../aiBlaze';

/**
 * 
 * @param {string} format 
 * @param {string} [locale] 
 * @returns 
 */
export function isValidDateOrTimeFormat(format, locale) {
  // We want to make sure the format can successfully format and re-parse 
  // a date or time otherwise it won't work in the date or time picker.
  // Trying three different date-times to avoid the issue if suppose today's day matches with it
  const dateTimeStrings = ['2015-11-13T22:55Z', '2020-03-04T20:35Z', '2010-01-02T18:40Z', '2005-07-18T03:25Z'].map((dateTimeString) => {
    let baseDate = moment.utc(dateTimeString);
    if (locale) {
      baseDate = baseDate.locale(locale);
    }
    let formattedDate = baseDate.format(format);
    let newDate = locale ? moment(formattedDate, format, locale) : moment(formattedDate, format);

    return newDate.isValid() && (
      newDate.year() === baseDate.year() ||
      newDate.month() === baseDate.month() ||
      newDate.date() === baseDate.date() ||
      (newDate.hours() === baseDate.hours() || newDate.hours() === baseDate.hours() - 12) ||
      newDate.minutes() === baseDate.minutes() ||
      newDate.weekday() === baseDate.weekday());
  });
  let valid = true;
  for (const v of dateTimeStrings) {
    valid = valid && v;
  }
  return valid;
}

/**
 * 
 * @param {string} format 
 * @param {string} [locale] 
 * @returns 
 */
export function getDateTimeConfig(format, locale) {
  // Trying three different date-times to avoid the issue if suppose today's day matches with it
  const dateTimeStrings = ['2015-11-13T22:55Z', '2020-03-04T20:35Z', '2010-01-02T18:40Z', '2005-07-18T03:25Z'].map((dateTimeString) => {
    let baseDate = moment.utc(dateTimeString);
    if (locale) {
      baseDate = baseDate.locale(locale);
    }
    let formattedDate = baseDate.format(format);
    let newDate = locale ? moment(formattedDate, format, locale) : moment(formattedDate, format);

    return {
      date: (newDate.year() === baseDate.year() || newDate.month() === baseDate.month() || newDate.date() === baseDate.date() || newDate.weekday() === baseDate.weekday()),
      time: (newDate.hours() === baseDate.hours() || newDate.hours() === baseDate.hours() - 12 || newDate.minutes() === baseDate.minutes())
    };
  });
  let config = { date: true, time: true };
  for (const v of dateTimeStrings) {
    config = { date: config.date && v.date, time: config.time && v.time };
  }
  return config;
}


/**
 * Non-addon URL domains should be static.
 *
 * @param {import('./ParserUtils').NodeAttribute} attribute
 *
 * @return {string}
 */
export function getStaticDomain(attribute) {
  let url = attribute.snippetText();

  let data;
  try {
    data = new URL(url);
  } catch (_e) {
    // URL parsing will fail if we have a command
    // in the protocol part of the url for instance.
    // Err on the side of caution if we can't determine
    // the host precisely.
    return null;
  }
  let origin = decodeURIComponent(data.origin);
  let username = decodeURIComponent(data.username);
  let password = decodeURIComponent(data.password);
  let port = decodeURIComponent(data.port);
  let protocol = decodeURIComponent(data.protocol);
  if (origin.includes('{') || origin.includes('}')
    || username.includes('{') || username.includes('}')
    || password.includes('{') || password.includes('}')
    || port.includes('{') || port.includes('}')
    || protocol.includes('{') || protocol.includes('}')) {
    // It has a dynamic command and we can't
    // statically analyze it
    return null;
  }

  return decodeURIComponent(data.hostname).toLowerCase();
}


/**
 * @param {import('./ParserUtils.js').AttributesType} attributes
 * @param {import('./DataContainer').Environment} env
 * @param {'select'|'insert'|'update'|'delete'} type
 */
function processBSQL(attributes, env, type) {
  let newQuery = '';
  let parameters = [];

  /** @type {import('./Lexer.js').TokenType[]} */
  let tokens = attributes.position[0].evaluated;

  let querySkeleton = getQuerySkeleton(attributes.position[0]);
  let databaseId = attributes.keys['space'].snippetText().trim();

  let doError = () => {
    throw new ParseError(fillErrorTemplate(env.config.databaseQueryWhitelistErrorTemplate || 'Cannot use {command} with this query. You can reconfigure the folder to allow access', {
      command: 'a DB command'
    }));
  };
  if (env.config.databaseQueryWhitelist && !env.config.databaseQueryWhitelist[databaseId]?.includes(querySkeleton)) {
    doError();
  }

  for (let token of tokens) {
    if (token.type === 'COMMAND') {
      parameters.push(token.command);
    } else if (token.type === 'IDENTIFIER' && token.identifier.startsWith('@')) {
      let name = escapeBSQLName(token.identifier.slice(1));
      parameters.push(`{=${name}}`);
    }
  }

  // We want to use the canonical skeleton query form,
  // as we validate this on the backend. We could also do this
  // on the backend but we would need to keep the JS/Python
  // versions in sync.
  
  // @ts-ignore
  newQuery = getQuerySkeleton({
    evaluated: tokens
  });


  let ast = astSQL(tokens, env);

  if (ast.type !== 'query') {
    throw new ParseError('Invalid BSQL query');
  }


  if (ast.info.type !== type) {
    throw new ParseError(`Query was not an ${type} query`);
  }

  let useFormWildcard = false;
  if (type === 'update' || type === 'insert') {
    if (!ast.info.info.values) {
      // populate with user form data
      useFormWildcard = true;
    }
  }

  return { tokens, ast, parameters, newQuery, useFormWildcard };       
}

/** @type {CommandDef['attributes']['named']} */
const BEGIN_ERROR_FINISH_BASE = {
  begin: {
    priority: -10, // -0.9
    description: 'Code that runs when the request starts',
    placeholder: 'isloading = true',
    type: 'equation',
    config: {
      blockAST: true,
    }
  },
  error: {
    priority: -10, // -0.9
    description: 'Code that runs when the request fails. error contains the error response',
    placeholder: 'result = error',
    type: 'equation',
    config: {
      blockAST: true,
    }
  },
  finish: {
    priority: -10, // -0.9
    description: 'Code that runs when the request completes. data is the response data, and status is the response status.',
    placeholder: 'result = data',
    type: 'equation',
    config: {
      blockAST: true,
    }
  },
};

/** @type {CommandDef['attributes']['named']} */
const DEFAULT_DB_COMMAND_PARAMS = Object.freeze({
  space: {
    priority: 2,
    required: true,
    description: 'Space to operate on',
    placeholder: '',
    type: 'database',
    static: true
  },
  ...BEGIN_ERROR_FINISH_BASE,
});

/** @type {CommandDef['attributes']['named']} */
const DEFAULT_SIDECHANNEL_SETTINGS = Object.freeze({
  completed: {
    priority: -0.91, // -10, // deprecated
    placeholder: '(res, status) -> "ERROR" if status <> 200 else ""',
    description: 'Function called when the request completes. If the returned message is non-empty, creates a browser notification with that message.',
    type: 'lambda',
  },
  instant: {
    priority: -10, // -0.9
    description: 'If yes, the command will run as soon as it is visible. If no, the command will only run on insertion',
    placeholder: false,
    type: 'boolean',
  }
});

async function getBSQLFinalParameters(parameters, env) {
  let i = 0;
  let finalParameters = [];
  for (let info of parameters) {
    let isAddon = isAddonCommand(info);

    let myEnv = env.derivedConfig({ 
      mode: isAddon ? 'addon' : 'attribute',
      // need to make sure we can evaluate formula's
      usedCommandsWhitelist: env.config.usedCommandsWhitelist.slice().concat('=')
    }, 'embedded-parameter - ' + i++);

    let parsed = await parse({ ops: [{ insert: info }] }, myEnv);

    if (isAddon) {
      // parent noData settings shouldn't propagate to addons, as the addon will be evaluated in a separate parse()
      delete myEnv.config.noData;
    }

    let evaluated = trimDom(await fillDom(parsed, myEnv));
    trimNotes(evaluated);

    let invalidCommand = evaluated.find(x => x.type === 'expand' && !['text', 'html'].includes(x.tag));
    if (invalidCommand) {
      throw new ParseError('Cannot include this command in a formula.');
    }
    let error = evaluated.find(x => x.type === 'error');
    if (error) {
      throw new ParseError(error.info.message);
    }
    finalParameters.push(domToStream(evaluated, 'text').join(''));
  }

  return finalParameters;
}

/**
 * Gets the skeleton of a BSQL query.
 * 
 * Applies these transforms to make it robust to basic changes:
 * 
 *   - lowercases keywords and identifiers
 *   - replaces commands with placeholders (?)
 *   - replace all whitespace with a single space
 * 
 * @param {import('./ParserUtils').NodeAttribute} attribute
 * 
 * @return {string}
 */
export function getQuerySkeleton(attribute) {
  let tokenId = 0;
  
  let query = attribute.evaluated.map(token => {
    if (token.type === 'IDENTIFIER') {
      if (token.identifier[0] === '@') {
        tokenId++;
        // It's a dynamic input
        return `$${tokenId}`;
      } else {
        return token.source.toLowerCase();
      }
    } else if (token.isKeyword) {
      return token.source.toLowerCase();
    } else if (token.type === 'WS') {
      return ' ';
    } else if (token.type === 'COMMAND') {
      tokenId++;
      return `$${tokenId}`;
    }

    return token.source;
  }).join('');


  return query;
}


/**
 * Priority cutoffs: 
 *   0 - Shown in suggestion bar list and popover top section
 *   > -1 - Shown in suggestion bar popper, popover advanced
 *   = -1 - Shown in popover advanced
 *   < -1 - Not shown anywhere
 */

/** @type {CommandDef['attributes']['named']} */
const URL_BASE = {
  'method': {
    priority: -.7,
    description: 'HTTP method used to request the URL (e.g. GET or POST)',
    placeholder: 'GET',
    type: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
    typeError: '"method" must be one of "GET", "POST", "PUT", "PATCH", or "DELETE"',
    config: {
      insensitive: true
    }
  },
  'body': {
    priority: -.8,
    description: 'Body of the HTTP request',
    type: 'string'
  },
  'headers': {
    priority: -.9,
    description: 'Headers for the HTTP request',
    placeholder: 'Cache-Control:no-cache, Accept:application/json',
    type: 'string',
    list: /** @type {ListType} */ ('keys')
  },
  ...BEGIN_ERROR_FINISH_BASE, 
};


/** @type {CommandDef['attributes']['named']} */
const FORM_BASE = {
  name: {
    priority: 2,
    description: 'Name of the form field',
    placeholder: 'Label',
    type: 'string',
    static: true
  },
  formatter: {
    priority: -1,
    description: 'Function formatting the value of the form field',
    placeholder: '(value) -> upper(value)',
    type: 'lambda'
  }
};


/**
 * @param {import('./ParserUtils').AttributesType} finalAttrs 
 * @param {import('./DataContainer').Environment} env 
 */
const URL_PROCESS_BASE = async (finalAttrs, env) => {
  let url = await finalAttrs.position[0].value(env);
  
  if (!isURL(url)) {
    throw new ParseError('Invalid URL: ' + url);
  }

  let method = finalAttrs.keys['method'] ? (await finalAttrs.keys['method'].value(env)).toUpperCase() : 'GET';

  let headers = finalAttrs.keys['headers'] ? await finalAttrs.keys['headers'].value(env) : undefined;

  try {
    new Headers(headers);
  } catch (e) {
    throw new ParseError('Invalid headers');
  }

  let body = finalAttrs.keys['body'] ? await finalAttrs.keys['body'].value(env) : undefined;

  if (['GET', 'HEAD'].includes(method) && body) {
    throw new ParseError(method + ' requests cannot include a body.');
  }

  const results = await getBeginFinishError(finalAttrs, env);

  return {
    url,
    method,
    body,
    headers,
    ...results,
  };
};

/**
 * @param {import('./ParserUtils').AttributesType} finalAttrs 
 * @param {import('./DataContainer').Environment} env 
 * @returns {Promise<{ finish?: any, begin?: any, error?: any }>}
 */
async function getBeginFinishError(finalAttrs, env) {
  const returnValue = {};
  const keys = ['finish', 'begin', 'error'];
  for (const key of keys) {
    try {
      returnValue[key] = finalAttrs.keys[key] ? await finalAttrs.keys[key].ast(env, 'Block') : false;
    } catch (e) {
      throw new ParseError('Invalid code – ' + e.message);
    }
  }
  return returnValue;
}

/**
 * @param {import('./ParserUtils').AttributesType} finalAttrs 
 * @param {import('./DataContainer').Environment} env 
 * @returns {Promise<{ finish?: any, begin?: any, error?: any, isloading, haserror }>}
 */
async function getDBSelectBasics(finalAttrs, env) {
  const isloading = finalAttrs.keys.isloading ? await finalAttrs.keys.isloading.value(env) : null;
  const haserror = finalAttrs.keys.haserror ? await finalAttrs.keys.haserror.value(env) : null;
  const result = await getBeginFinishError(finalAttrs, env);

  return { isloading, haserror, ...result };
}

/**
 * @param {import('./ParserUtils').AttributesType} finalAttrs 
 * @param {import('./DataContainer').Environment} env 
 */
async function getSidechannelParams(finalAttrs, env) {
  const completed = finalAttrs.keys['completed'] ? await finalAttrs.keys['completed'].value(env) : undefined;
  const instant = finalAttrs.keys['instant'] ? await finalAttrs.keys['instant'].value(env) : undefined;
  return { instant, completed };
}

/**
 * @param {import('./ParserUtils').AttributesType} finalAttrs 
 * @param {import('./DataContainer').Environment} env 
 */
async function DB_PROCESS_BASE(finalAttrs, env) {
  const { completed, instant } = await getSidechannelParams(finalAttrs, env);
  const results = await getBeginFinishError(finalAttrs, env);
  if (completed && results.finish) {
    throw new ParseError('Cannot set both completed and finish in the same command');
  }
  if (completed && results.error) {
    throw new ParseError('Cannot set both completed and error in the same command');
  }

  return {
    completed,
    instant,
    ...results,
  };
}

/**
 * @param {string} template
 * @param {Object<string, string>} data
 * 
 * @return {string}
 */
export function fillErrorTemplate(template, data) {
  if ('domain' in data) {
    template = template.replace(/{domain}/g, data.domain);
  }
  if ('command' in data) {
    template = template.replace(/{command}/g, data.command);
  }
  return template;
}


/**
 * KEY: the text for the command. E.g. {key}.}
 */


/**
 * @typedef {object} CommandSpecDef
 * @property {string=} commandName
 * @property {function=} rejectFn
 * @property {Object<string, UserDefinedPropertyType>=} named
 * @property {UserDefinedPropertyType=} positionalDef
 * @property {number[]=} positional
 */


/**
  * @typedef {object} CommandDef
  * @property {string} tag the name of the tag in the dom tree
  * @property {function(import('./ParserUtils').AttributesType, function(string, import('./ParseNode').InfoType, object=): Promise, import("./DataContainer").Environment=, boolean=): Promise} fn takes the parsed attributes and creates the relevant node
  * @property {string=} subType
  * @property {string=} description
  * @property {CommandSpecDef=} attributes
  * @property {(function|string)=} preview
  * @property {('attribute'|'addon')=} invalidIn string of areas this tag is not in. Valid values:
  *    - attribute: not valid as an attribute in another tag. e.g. {blah: {in_attribute}}
  *    - addon: not valid in addon (e.g. key)
  * @property {boolean=} interaction specifies a user interaction. the generate dom will be split at these points and each
  * split part will be added separately
  * @property {boolean=} remove removed at post-process step (see postProcessDom in SnippetProcessor)
  * @property {boolean=} sidechannel extracted from the stream prior to insertion and provided in sideChannel
  * @property {boolean=} bare causes a ':' to not be used when parsing parameters. E.g {=1+1}
  * @property {boolean=} block
  * @property {boolean=} allowedInAiBlaze AI Blaze only supports a subset of commands
  */

/** @type {Object<string, CommandDef>} */
export const COMMANDS = {
  'CLIPBOARD': {
    tag: 'clipboard',
    description: 'Insert clipboard contents',
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'clipboard',
        attributes
      });
    },
    allowedInAiBlaze: true
  },


  'CLICK': {
    tag: 'click',
    description: 'Simulate mouse click',
    invalidIn: 'attribute',
    interaction: true,
    preview: 'CLICK',
    attributes: {
      positional: [0, 0],
      named: {
        'selector': {
          type: 'selector',
          priority: 1.5,
          description: 'CSS selector to click on a specific part of the page',
          placeholder: '#element-id',
          static: true,
          config: {
            commandType: 'click',
            needsClick: true,
            disableCrossIframe: true,
          }
        },
        'xpath': {
          type: 'string',
          priority: -10,
          description: 'XPath to click on a specific part of the page',
          placeholder: '//div',
          static: true
        },
        'maxdelay': {
          type: 'time',
          priority: -10,
          placeholder: '2s',
          description: 'How long to wait until the element to click becomes visible or interactive',
          config: {
            duration: true,
            showUnits: ['seconds']
          },
          typeError: 'Invalid delay'
        },
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'click',
        attributes,
        process: async (finalAttrs, env) => {
          if (finalAttrs.keys['selector'] && finalAttrs.keys['xpath']) {
            throw new ParseError('Only one of "xpath" or "selector" may be used.');
          }

          const summary = {
            command: 'click',
            type: 'click',
          };

          if (finalAttrs.keys.selector) {
            summary.selector = await finalAttrs.keys.selector.value(env);
          }

          if (finalAttrs.keys.xpath) {
            summary.xpath = await finalAttrs.keys.xpath.value(env);
          }
          const hasSelectorOrXpath = !!(summary.selector || summary.xpath);
          const providedTimeoutSeconds = finalAttrs.keys.maxdelay ? shiftsToSeconds(await finalAttrs.keys.maxdelay.value(env)) : undefined;
          if (providedTimeoutSeconds && !hasSelectorOrXpath) {
            throw new ParseError('Selector or xpath must be supplied to use a max delay');
          }
          const defaultTimeoutSeconds = hasSelectorOrXpath ? 1 : 0;
          const usedTimeoutSeconds = providedTimeoutSeconds === undefined ? defaultTimeoutSeconds : providedTimeoutSeconds;
          if (usedTimeoutSeconds < 0) {
            throw new ParseError('Max delay must be greater than or equal to 0');
          }
          if (usedTimeoutSeconds > 60) {
            throw new ParseError('Max delay must be less than or equal to 60 seconds');
          }
          if (Math.round(usedTimeoutSeconds * 10) !== usedTimeoutSeconds * 10) {
            throw new ParseError('Max delay must be a multiple of 100ms');
          }
          summary.timeout = usedTimeoutSeconds;
          return summary;
        }
      });
    }
  },

  'CURSOR': {
    tag: 'cursor',
    description: 'Place cursor after insertion',
    invalidIn: 'attribute',
    preview: 'CURSOR',
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'cursor',
        attributes
      });
    }
  },

  'ERROR': {
    tag: 'alert',
    description: 'Displays an error message',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'message',
        type: 'string',
        description: 'The error message',
        placeholder: 'Form is invalid'
      },
      named: {
        block: {
          priority: 2,
          type: 'boolean',
          description: 'Prevents the form from being submitted while the error is shown',
          placeholder: 'yes',
          required: false
        },
        show: {
          priority: -1,
          type: ['default', 'validate'],
          description: '"validate" will show the error only after you attempt to insert the snippet',
          placeholder: 'default',
          required: false
        }
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'error',
        attributes,
        process: async (finalAttrs, env) => {
          let message = await finalAttrs.position[0].value(env);
          let blocking = finalAttrs.keys['block'] ? await finalAttrs.keys['block'].value(env) : false;
          let show = finalAttrs.keys['show'] ? await finalAttrs.keys['show'].value(env) : 'default';
          return {
            type: 'error',
            message,
            blocking,
            show
          };
        }
      });
    },
    allowedInAiBlaze: true
  },


  'URLSEND': {
    tag: 'remote',
    description: 'Send a message to a URL',
    subType: 'url_ping',
    sidechannel: true,
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'url',
        type: 'string',
        description: 'The url to message',
        placeholder: 'https://example.com/test'
      },
      named: Object.assign({}, URL_BASE, DEFAULT_SIDECHANNEL_SETTINGS)
    },
    fn: async function(attributes, emit, _env, addonNamespace) {
      await emit(this.tag, {
        command: 'urlsend',
        type: this.subType,
        attributes,
        process: async (finalAttrs, env) => {
          if (!addonNamespace) {
            let domain = getStaticDomain(attributes.position[0]);
            if (domain === null) {
              throw new ParseError('{urlsend} URL must have a fixed domain like "https://example.com"');
            }
            if (domain === '') {
              // For example: URL == "htts://google.com"
              throw new ParseError('{urlsend} URL is invalid');
            }
            if (env.config.pingHostWhitelist || env.config.loadHostWhitelist) {
              let allowedHosts = (env.config.pingHostWhitelist || []).concat(env.config.loadHostWhitelist || []);
              if (!allowedHosts.includes(domain)) {
                throw new ParseError(fillErrorTemplate(env.config.pingHostWhitelistErrorTemplate || 'Cannot use {command} with {domain}', {
                  domain,
                  command: '{urlsend}'
                }));
              } 
            }
          }
          
          let base = await URL_PROCESS_BASE(finalAttrs, env);
          let others = await getSidechannelParams(finalAttrs, env);
          if (base.finish && others.completed) {
            throw new ParseError('Cannot set both completed and finish in the same command');
          }
          if (base.error && others.completed) {
            throw new ParseError('Cannot set both completed and error in the same command');
          }
          return Object.assign(base, others);
        }
      });
    }
  },

  'DBSELECT': {
    tag: 'remote',
    description: 'Loads data from Data Blaze',
    subType: 'dbselect',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'query',
        type: 'bsql',
        description: 'The BSQL query to load data',
        placeholder: 'SELECT column FROM table'
      },
      named: {
        multiple: {
          type: 'boolean',
          priority: 1.5,
          placeholder: 'no',
          description: 'Whether to get multiple rows as a list. If off, will only get one row',
          static: true
        },
        menu: {
          type: 'boolean',
          priority: 1.5,
          placeholder: 'yes',
          description: 'Show a menu to select a row',
          static: true
        },
        cols: {
          type: 'number',
          priority: 1.4,
          placeholder: '20',
          description: 'The width of the menu in columns of text',
          config: {
            minimum: 0
          }
        },
        default: {
          priority: -.9,
          description: 'Default value while waiting for the load',
          placeholder: '',
          type: 'string',
          list: 'keys',
          constant: true
        },
        name: {
          priority: 1.6,
          description: 'Form variable for results (if ommitted and multiple is off, each field will be placed in its own form variable)',
          placeholder: 'name',
          type: 'identifier',
          static: true,
          holder: true
        },
        isloading: {
          priority: -.95,
          description: 'Form name set to `yes` while loading the data',
          placeholder: 'name',
          type: 'identifier',
          static: true
        },
        haserror: {
          priority: -.96,
          description: 'Form name set to `yes` if an error is encountered',
          placeholder: 'name',
          type: 'identifier',
          static: true
        },
        debounce: {
          type: 'time',
          priority: -0.961,
          placeholder: '0.5s',
          static: true,
          description: 'Debounce the selects a small amount if your inputs change frequently',
          config: {
            duration: true,
            showUnits: ['seconds']
          },
          typeError: 'Invalid debounce'
        },
        ...DEFAULT_DB_COMMAND_PARAMS
      }
    },
    fn: async function(attributes, emit, env) {
      let labelName = newName();
      let menu = attributes.keys.menu ? await attributes.keys.menu.value(env) : false;
      let name = attributes.keys.name ? await attributes.keys.name.value(env) : null;
      let cols = attributes.keys['cols'] ? +(await attributes.keys['cols'].value(env)) : undefined;
      if (env.config.isOneoffFormula && (menu || cols || name)) {
        const errorName = menu ? 'menu' : (cols ? 'cols' : 'name');
        throw new ParseError(`Cannot use dbselect with ${errorName} in a code block`);
      }

      await emit(this.tag, {
        command: 'dbselect',
        type: this.subType,
        attributes,
        labelName,
        menu,
        process: async (finalAttrs, env, noQuery = false) => {
          let newQuery = '';
          let parameters = [];
          let blurb = ['Read from table'];

          let def = finalAttrs.keys.default ? (await finalAttrs.keys.default.value(env)) : null;
          let multiple = finalAttrs.keys.multiple ? await finalAttrs.keys.multiple.value(env) : false;

          try {
            if (!noQuery) {
              let processed = processBSQL(finalAttrs, env, 'select');

              newQuery = processed.newQuery;
              parameters = processed.parameters;
              blurb = ['Read from ', processed.ast.info.info.base.info.from.info];
            }
          } catch (e) {
            throw new ParseError('Invalid Data Blaze query – ' + e.message);
          }

          let tokens = attributes.position[0].evaluated;

          let ast = astSQL(tokens, env);
            
          if (!multiple || !name) {
            // get defaults
            let columns = findType(ast, 'select_option');
            if (def) {
              let defaultKeys = Object.keys(def);
              columns = columns.map(column => column.info.alias.info.toLowerCase());
              if (!equal(defaultKeys.sort(), columns.sort())) {
                throw new ParseError('If you specify "default", the keys must match the columns in the select');
              }
            } else {
              def = {};
              for (let column of columns) {
                if (multiple) {
                  def[column.info.alias.info.toLowerCase()] = '[]';
                } else {
                  def[column.info.alias.info.toLowerCase()] = '';
                }
              }
            }
          }

          let names = [];
          for (let name of ast.info.info.base.info.columns.map(col => col.info.alias.info)) {
            names.push(name);
          }



          let debounceMs = finalAttrs.keys['debounce'] ? shiftsToSeconds(await finalAttrs.keys['debounce'].value(env)) * 1000 : 0;

          const results = await getDBSelectBasics(finalAttrs, env);
          
          return {
            query: newQuery,
            parameters: await getBSQLFinalParameters(parameters, env),
            databaseId: await finalAttrs.keys['space'].value(env),
            folderid: env.config.snippet['folderid'],
            multiple,
            name,
            default: def,
            names,
            labelName,
            menu,
            sqlBlurb: blurb,
            debounceMs,
            cols,
            ...results,
          };
        }
      });
    },
    allowedInAiBlaze: true
  },

  'DBUPDATE': {
    tag: 'remote',
    description: 'Updates data in Data Blaze',
    subType: 'dbupdate',
    sidechannel: true,
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'query',
        type: 'bsql',
        description: 'The BSQL query to update data',
        placeholder: 'UPDATE table SET column = value WHERE column == other'
      },
      named: {
        ...DEFAULT_DB_COMMAND_PARAMS,
        ...DEFAULT_SIDECHANNEL_SETTINGS,
        autoaddfields: {
          type: 'boolean',
          priority: 1.5,
          placeholder: 'no',
          description: 'Automatically add new fields in the table where needed',
          static: true
        }
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'dbupdate',
        type: this.subType,
        attributes,
        process: async (finalAttrs, env) => {
          let newQuery = '';
          let parameters = [];
          let blurb = ['Update table'];
          let useFormWildcard;

          try {
            let processed = processBSQL(finalAttrs, env, 'update');
            newQuery = processed.newQuery;
            parameters = processed.parameters;
            useFormWildcard = processed.useFormWildcard;
            blurb = ['Update ', processed.ast.info.info.table.info];
          } catch (e) {
            throw new ParseError('Invalid Data Blaze query – ' + e.message);
          }

          let res = Object.assign({
            query: newQuery,
            parameters: await getBSQLFinalParameters(parameters, env),
            databaseId: await finalAttrs.keys['space'].value(env),
            folderid: env.config.snippet['folderid'],
            sqlBlurb: blurb,
            autoaddfields: ('autoaddfields' in finalAttrs.keys) ? (await finalAttrs.keys['autoaddfields'].value(env)) : false
          }, await DB_PROCESS_BASE(finalAttrs, env));

          if (useFormWildcard) {
            let data = env.data.flattenData();
            let useData = Object.create(null);
            for (let key in data) {
              if (!key.startsWith('{auto_name}_')) {
                useData[key] = toStr(data[key]);
              }
            }
            // @ts-ignore
            res.set = useData;
          }

          return res;
        }
      });
    }
  },


  'DBINSERT': {
    tag: 'remote',
    description: 'Inserts new rows in Data Blaze',
    subType: 'dbinsert',
    sidechannel: true,
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'query',
        type: 'bsql',
        description: 'The BSQL query to insert data',
        placeholder: 'INSERT INTO table SET field = value'
      },
      named: {
        ...DEFAULT_DB_COMMAND_PARAMS,
        ...DEFAULT_SIDECHANNEL_SETTINGS,
        autoaddfields: {
          type: 'boolean',
          priority: 1.5,
          placeholder: 'no',
          description: 'Automatically add new fields in the tables where needed',
          static: true
        }
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'dbinsert',
        type: this.subType,
        attributes,
        process: async (finalAttrs, env) => {
          let newQuery = '';
          let parameters = [];
          let blurb = ['Insert row into table'];
          let useFormWildcard;

          try {
            let processed = processBSQL(finalAttrs, env, 'insert');
            newQuery = processed.newQuery;
            parameters = processed.parameters;
            useFormWildcard = processed.useFormWildcard;
            blurb = ['Insert row into ', processed.ast.info.info.table.info];
          } catch (e) {
            throw new ParseError('Invalid Data Blaze query – ' + e.message);
          }

          let res = Object.assign({
            query: newQuery,
            parameters: await getBSQLFinalParameters(parameters, env),
            databaseId: await finalAttrs.keys['space'].value(env),
            folderid: env.config.snippet['folderid'],
            sqlBlurb: blurb,
            autoaddfields: ('autoaddfields' in finalAttrs.keys) ? (await finalAttrs.keys['autoaddfields'].value(env)) : false
          }, await DB_PROCESS_BASE(finalAttrs, env));

          if (useFormWildcard) {
            let data = env.data.flattenData();
            let useData = Object.create(null);
            for (let key in data) {
              if (!key.startsWith('{auto_name}_')) {
                useData[key] = toStr(data[key]);
              }
            }
            // @ts-ignore
            res.set = useData;
          }

          return res;
        }
      });
    }
  },


  'DBDELETE': {
    tag: 'remote',
    description: 'Deletes data in Data Blaze',
    subType: 'dbdelete',
    sidechannel: true,
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'query',
        type: 'bsql',
        description: 'The BSQL query to delete data',
        placeholder: 'DELETE FROM table WHERE column == value'
      },
      named: {
        ...DEFAULT_DB_COMMAND_PARAMS,
        ...DEFAULT_SIDECHANNEL_SETTINGS,
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'dbdelete',
        type: this.subType,
        attributes,
        process: async (finalAttrs, env) => {
          let newQuery = '';
          let parameters = [];
          let blurb = ['Delete row from table'];
      
          try {
            let processed = processBSQL(finalAttrs, env, 'delete');
            newQuery = processed.newQuery;
            parameters = processed.parameters;
            blurb = ['Delete row from ', processed.ast.info.info.table.info];
          } catch (e) {
            throw new ParseError('Invalid Data Blaze query – ' + e.message);
          }
                
          return Object.assign({
            query: newQuery,
            parameters: await getBSQLFinalParameters(parameters, env),
            databaseId: await finalAttrs.keys['space'].value(env),
            folderid: env.config.snippet['folderid'],
            sqlBlurb: blurb
          }, await DB_PROCESS_BASE(finalAttrs, env));
        }
      });
    }
  },


  'URLLOAD': {
    tag: 'remote',
    description: 'Load data from a URL',
    subType: 'url_load',
    remove: true,
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'url',
        type: 'string',
        description: 'The url to load',
        placeholder: 'https://example.com/test'
      },
      named: Object.assign({}, URL_BASE, {
        start: {
          priority: -1, // -10, // deprecated
          description: 'Function called when the request starts',
          placeholder: '() -> ["loading": yes]',
          type: 'lambda'
        },
        done: {
          priority: 2, // -10, // deprecated
          description: 'Function called when the request completes',
          placeholder: '(res) -> ["value": res]',
          type: 'lambda'
        },
        debounce: {
          type: 'time',
          priority: -0.91,
          placeholder: '0.5s',
          static: true,
          description: 'Debounce the requests a small amount if your inputs change frequently',
          config: {
            duration: true,
            showUnits: ['seconds']
          },
          typeError: 'Invalid debounce'
        },
      })
    },
    fn: async function(attributes, emit, _env, addonNamespace) {
      await emit(this.tag, {
        command: 'urlload',
        type: this.subType,
        attributes,
        process: async (finalAttrs, env) => {
          if (!addonNamespace) {
            let domain = getStaticDomain(attributes.position[0]);
            if (domain === null) {
              throw new ParseError('{urlload} URL must have a fixed domain like "https://example.com"');
            }
            if (domain === '') {
              // For example: URL == "htts://google.com"
              throw new ParseError('{urlload} URL is invalid');
            }
            let doError = () => {
              throw new ParseError(fillErrorTemplate(env.config.loadHostWhitelistErrorTemplate || 'Cannot use {command} with {domain}', {
                domain,
                command: '{urlload}'
              }));
            };
            if (env.config.pingHostWhitelist && !env.config.loadHostWhitelist) {
              doError();
            }
            if (env.config.loadHostWhitelist && !env.config.loadHostWhitelist.includes(domain)) {
              doError();
            } 
          }

          let debounceMs = finalAttrs.keys['debounce'] ? shiftsToSeconds(await finalAttrs.keys['debounce'].value(env)) * 1000 : 0;


          const base = await URL_PROCESS_BASE(finalAttrs, env);
          const done = finalAttrs.keys['done'] ? await finalAttrs.keys['done'].value(env) : undefined;
          const start = finalAttrs.keys['start'] ? await finalAttrs.keys['start'].value(env) : undefined;
          if (done && base.finish) {
            throw new ParseError('Cannot set both done and finish in the same command');
          }
          if (done && base.error) {
            throw new ParseError('Cannot set both done and error in the same command');
          }
          if (start && base.begin) {
            throw new ParseError('Cannot set both begin and start in the same command');
          }
      
          return Object.assign(base, {
            done,
            start,
            debounceMs,
          });
        }
      });
    },
    // TODO: enable by end of September 2024
    // allowedInAiBlaze: true,
  },


  '=': {
    tag: 'calc',
    description: 'Evaluate dynamic formula',
    bare: true,
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'formula',
        type: 'equation',
        description: 'The formula to evaluate',
        placeholder: '7 * 8'
      },
      named: Object.assign({}, {
        'format': {
          type: 'numeric_format',
          priority: 1,
          placeholder: ',',
          description: 'Formats the numeric results',
        }
      })
    },
    fn: async function(attributes, emit, env) {
      let tree;
      
      try {
        tree = await attributes.position[0].ast(env);
      } catch (e) {
        throw new ParseError('Invalid formula – ' + e.message);
      }

      await emit(this.tag, {
        command: '=',
        attributes,
        process: async (finalAttrs, env) => {
          return {
            eqn: tree,
            format: finalAttrs.keys['format'] ? await finalAttrs.keys['format'].value(env) : undefined,
            silent: finalAttrs.keys['silent'] ? await finalAttrs.keys['silent'].value(env) : false
          };
        }
      });
    },
    allowedInAiBlaze: true
  },



  'RUN': {
    tag: 'run',
    description: 'Evaluate code',
    invalidIn: 'attribute',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'code',
        type: 'equation',
        description: 'The code to evaluate',
        placeholder: 'x = 1'
      }
    },
    fn: async function(attributes, emit, env) {
      let tree;
      
      try {
        tree = await attributes.position[0].ast(env, 'Block');
      } catch (e) {
        throw new ParseError('Invalid code – ' + e.message);
      }

      await emit(this.tag, {
        command: 'run',
        attributes,
        process: async (_finalAttrs, _env) => {
          return {
            statements: tree
          };
        }
      });
    },
    allowedInAiBlaze: true,
  },



  'BUTTON': {
    tag: 'button',
    description: 'A button you can click',
    invalidIn: 'attribute',
    subType: 'button',
    remove: true,
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'code',
        type: 'equation',
        description: 'The code to evaluate when clicked',
        placeholder: 'x = 1'
      },
      named: {
        label: {
          priority: 1.6,
          type: 'string',
          description: 'The button label',
          placeholder: 'Click Me',
          required: true
        },
        disabled: {
          priority: 1.3,
          type: 'boolean',
          description: 'Disables the button',
          placeholder: 'no'
        }
      }
    },
    fn: async function(attributes, emit, env) {
      try {
        await attributes.position[0].ast(env, 'Block');
      } catch (e) {
        throw new ParseError('Invalid code – ' + e.message);
      }

      await emit(this.tag, {
        command: 'button',
        attributes,
        process: async (finalAttrs, env) => {
          return {
            type: 'button',
            buttonLabel: await finalAttrs.keys.label.value(env),
            buttonDisabled: finalAttrs.keys.disabled ? await finalAttrs.keys.disabled.value(env) : false
          };
        }
      });
    },
    allowedInAiBlaze: true,
  },

  'SITE': {
    tag: 'site',
    description: 'Include context from website',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'data type',
        type: ['url', 'domain', 'path', 'protocol', 'query', 'hash', 'title', 'text', 'html'],
        config: {
          insensitive: false
        },
        description: 'The type of data to select',
        placeholder: 'title',
        static: true
      },
      named: {
        'selector': {
          type: 'selector',
          priority: 1.6,
          description: 'CSS selector to extract a specific part of the page',
          placeholder: '#element-id',
          static: true,
          config: {
            commandType: 'include',
            supportsPage: true,
          }
        },
        'xpath': {
          type: 'string',
          priority: -10,
          description: 'XPath to extract a specific part of the page',
          placeholder: '//div',
          static: true
        },
        'multiple': {
          type: 'boolean',
          priority: 1.5,
          placeholder: 'yes',
          description: 'When CSS selectors are used, whether to return multiple matches',
          static: true
        },
        'select': {
          type: ['yes', 'no', 'ifneeded'],
          placeholder: 'ifneeded',
          config: {
            insensitive: false
          },
          priority: 0.89,
          description: 'Whether to show the tab selector. yes=always shows. no=does not show (only matches the current page). ifneeded=shows only when multiple tabs are matching.',
          typeError: 'select must be either one of yes/no/ifneeded',
          static: true
        },
        'page': {
          type: 'string',
          placeholder: 'https://domain.com/*',
          priority: 0.9,
          description: 'Page URLs to match for the site command. We will fetch data from these tabs.',
          static: true
        },
        'group': {
          type: 'string',
          placeholder: 'groupname',
          priority: -10,
          description: 'Site commands with the same page and the same group name are grouped together',
          static: true
        },
        'frame': {
          type: ['self', 'top'],
          config: {
            insensitive: false
          },
          placeholder: 'top',
          priority: 0.1,
          description: 'What frame to extract the contents from',
          typeError: '"frame" must be one of "top" or "self"',
          static: true
        },
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'site',
        attributes,
        process: (finalAttrs, env) => {
          return getSiteSummary(finalAttrs, env);
        }
      });
    },
    allowedInAiBlaze: true
  },

  'KEY': {
    tag: 'key',
    description: 'Simulate key press',
    invalidIn: 'attribute',
    interaction: true,
    preview: (info) => {
      let str = info.str;
      return {
        text: str.toUpperCase()
      };
    },
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'key',
        type: 'key',
        description: 'The key to insert',
        placeholder: 'a'
      },
      named: {
        win: {
          type: 'key',
          priority: -1,
          description: 'Override when on Windows',
          placeholder: 'a'
        },
        mac: {
          type: 'key',
          priority: -1,
          description: 'Override when on a Macintosh',
          placeholder: 'a'
        },
        linux: {
          type: 'key',
          priority: -1,
          description: 'Override when on Linux',
          placeholder: 'a'
        },
        cros: {
          type: 'key',
          priority: -1,
          description: 'Override when on a Chromebook',
          placeholder: 'a'
        }
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'key',
        attributes,
        process: async (finalAttrs, env) => {
          // Attempt to load os-specific version if available, otherwise use default
          let attr = finalAttrs.keys[getOS()] || finalAttrs.position[0];

          let keySequence = await attr.value(env);
          let keySequenceLC = keySequence.toLocaleLowerCase();
          let parts = keySequenceLC.split('-');
          let keyLC = parts.pop();
          let key = keySequence.split('-').pop();
          let modifiers = parts;
          if (keySequenceLC === '-') {
            key = '-';
            keyLC = '-';
            modifiers = [];
          }

          if (key.length !== 1 && !['escape', 'enter', 'return', 'tab', 'leftarrow', 'uparrow', 'rightarrow', 'downarrow', 'backspace', 'space', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'].includes(key)) {
            throw new ParseError(`Unknown key "${key}"`);
          }
          modifiers = modifiers.map(m => {
            if (m === 'meta') {
              return 'cmd';
            } else if (m === 'option') {
              return 'alt';
            }
            return m;
          });
          modifiers.forEach(m => {
            if (!['ctrl', 'alt', 'shift', 'cmd'].includes(m)) {
              throw new ParseError(`Unknown key modifier "${m}"`);
            }
          });

          let keyCode;
          if (keyLC === 'escape') {
            keyCode = 27;
          } else if (keyLC === 'tab') {
            keyCode = 9;
          } else if (keyLC === 'backspace') {
            keyCode = 8;
          } else if (keyLC === 'enter' || keyLC === 'return') {
            keyCode = 13;
          } else if (keyLC === 'leftarrow') {
            keyCode = 37;
          } else if (keyLC === 'rightarrow') {
            keyCode = 39;
          } else if (keyLC === 'uparrow') {
            keyCode = 38;
          } else if (keyLC === 'downarrow') {
            keyCode = 40;
          } else if (['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'].includes(key)) {
            keyCode = 111 + +key.slice(1);
          } else {
            keyCode = keyLC.toUpperCase().charCodeAt(0);
          }

          return {
            str: keySequence,
            key,
            keyCode,
            ctrl: modifiers.includes('ctrl'),
            alt: modifiers.includes('alt'),
            shift: modifiers.includes('shift'),
            cmd: modifiers.includes('cmd')
          };
        }
      });
    }
  },

  
  'TIME': {
    tag: 'datetime',
    description: 'Current time or date',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'time/date format',
        type: 'time_format',
        description: 'How to format the date or time',
        placeholder: 'MMM YYYY',
        config: {
          preview: true
        }
      },
      named: {
        shift: {
          type: 'time',
          priority: 2,
          placeholder: '+2W',
          description: 'Shift backwards or forwards in time',
          config: {
            duration: false
          }
        },
        at: {
          type: 'string',
          priority: 1,
          placeholder: '2019-01-20',
          description: 'Use a specific date and time',
        },
        pattern: {
          type: 'time_format',
          priority: 1,
          placeholder: 'YYYY-MM-DD',
          description: 'Specify how to read the date and time set in "at"',
          config: {
            preview: false
          }
        },
        locale: {
          type: 'string',
          priority: -0.5,
          placeholder: 'en-us',
          description: 'Locale code setting the country and language for formatting',
        }
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'time',
        attributes,
        process: async (finalAttrs, env) => {
          let at = finalAttrs.keys['at'] ? await finalAttrs.keys['at'].value(env) : null;
          let pattern = finalAttrs.keys['pattern'] ? await finalAttrs.keys['pattern'].value(env) : null;
          if (pattern && !at) {
            throw new ParseError('You must specify "at" if you specify "pattern".');
          }

          if (pattern !== null && !pattern.trim(0)) {
            throw new ParseError('"pattern" cannot be blank.');
          }
          if (at !== null && !at.trim(0)) {
            throw new ParseError('"at" cannot be blank.');
          }

          let specification = await finalAttrs.position[0].value(env);
          let locale = finalAttrs.keys['locale'] ? getLocale(await finalAttrs.keys['locale'].value(env)).moment() : null;
          let shift = finalAttrs.keys['shift'] ? await finalAttrs.keys['shift'].value(env) : null;
          return {
            specification,
            locale,
            shift,
            at,
            pattern
          };
        }
      });
    },
    allowedInAiBlaze: true
  },


  'IMAGE': {
    tag: 'image',
    description: 'Insert an image',
    invalidIn: 'attribute',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'url',
        type: 'string',
        description: 'The image url',
        placeholder: 'https://example.com/test.jpg'
      },
      named: {
        width: {
          type: 'number',
          priority: 2,
          placeholder: '100',
          description: 'The width of the image in pixels',
          config: {
            minimum: 0
          }
        },
        height: {
          type: 'number',
          priority: 2,
          placeholder: '100',
          description: 'The height of the image in pixels',
          config: {
            minimum: 0
          }
        }
      }
    },
    fn: async function(attributes, emit, _env, addonNamespace) {
      await emit(this.tag, {
        command: 'image',
        attributes,
        process: async (finalAttrs, env) => {
          let src = await finalAttrs.position[0].value(env);
          let lowerSrc = src.toLowerCase();

          if (!(lowerSrc.startsWith('http:') || lowerSrc.startsWith('https:'))) {
            throw new ParseError('Invalid URL for {image}: ' + src);
          }

          if (!addonNamespace) {
            let domain = getStaticDomain(attributes.position[0]);
            if (domain === null) {
              throw new ParseError('{image} URL must have a fixed domain like "https://example.com"');
            }
            if (env.config.pingHostWhitelist || env.config.loadHostWhitelist) {
              let allowedHosts = (env.config.pingHostWhitelist || []).concat(env.config.loadHostWhitelist || []);
              if (!allowedHosts.includes(domain)) {
                throw new ParseError(fillErrorTemplate(env.config.pingHostWhitelistErrorTemplate || 'Cannot use {command} with {domain}', {
                  domain,
                  command: '{image}'
                }));
              } 
            }
          }

          return {
            imgAttrs: {
              src: src,
              width: finalAttrs.keys['width'] ? await finalAttrs.keys['width'].value(env) : undefined,
              height: finalAttrs.keys['height'] ? await finalAttrs.keys['height'].value(env) : undefined
            }
          };
        }
      });
    }
  },

  'WAIT': {
    tag: 'wait',
    description: 'Briefly pause insertion',
    invalidIn: 'attribute',
    interaction: true,
    preview: (info) => {
      return {
        text: 'WAIT ' + info.rawDelay
      };
    },
    attributes: {
      positional: [0, 0],
      named: {
        delay: {
          type: 'time',
          priority: 2,
          placeholder: '2s',
          description: 'How long to wait before continuing',
          config: {
            duration: true,
            showUnits: ['seconds', 'minutes']
          },
          typeError: 'Invalid delay'
        }
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'wait',
        attributes,
        process: async (finalAttrs, env) => {
          let shifts = finalAttrs.keys['delay'] ? await finalAttrs.keys['delay'].value(env) : [{
            shift: 1,
            units: 'seconds'
          }];

          let delay = shiftsToSeconds(shifts);

          if (delay < 0) {
            throw new ParseError('The delay for {wait} must be greater than or equal to 0.');
          }

          return {
            delay,
            rawDelay: finalAttrs.keys['delay'] ? finalAttrs.keys['delay'].snippetText() : '1s'
          };
        }
      });
    }
  },


  'IMPORT': {
    tag: 'import',
    description: 'Import another snippet',
    invalidIn: 'addon',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'shortcut',
        type: 'shortcut',
        description: `Shortcut of the ${snippetOrPrompt} to insert`,
        placeholder: '/go',
        // {imports} are evaluated outside the main snippet flow,
        // so things like equations within them aren't caught be
        // featureUsage. Disabling dynamic behavior completely also
        // makes static analysis of the snippet easier.
        static: true
      },
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'import',
        attributes,
        process: async (finalAttrs, env) => {
          return finalAttrs.position[0].value(env);
        }
      });
    },
    allowedInAiBlaze: true
  },


  'SNIPPET': {
    tag: 'snippet',
    description: 'Snippet properties (e.g. the snippet shortcut)',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'property',
        type: 'string',
        description: 'The property of the snippet to insert',
        placeholder: 'shortcut',
        static: true
      },
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'snippet',
        attributes,
        process: async (finalAttrs, env) => {
          return {
            item: await finalAttrs.position[0].value(env)
          };
        }
      });
    }
  },


  'USER': {
    tag: 'user',
    description: 'User properties (e.g. their email)',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'property',
        type: 'string',
        description: 'The property of the user to insert',
        placeholder: 'email',
        static: true
      },
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'user',
        attributes,
        process: async (finalAttrs, env) => {
          return {
            item: await finalAttrs.position[0].value(env)
          };
        }
      });
    },
    allowedInAiBlaze: true,
  },


  'FORMDATE': {
    tag: 'form',
    description: 'Enter a date',
    invalidIn: 'attribute',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'format',
        type: 'time_format',
        description: 'How to format the date or time',
        placeholder: 'YYYY-MM-DD',
        constant: true,
        config: {
          preview: true,
          datetime_only: true
        }
      },
      named: Object.assign({}, FORM_BASE, {
        cols: {
          type: 'number',
          priority: 1.5,
          placeholder: '20',
          description: 'The width of the field in columns of text',
          config: {
            minimum: 0
          }
        },
        start: {
          type: 'datetime',
          priority: 1.9,
          placeholder: '2020-01-01',
          description: 'The earliest date that can be selected'
        },
        end: {
          type: 'datetime',
          priority: 1.8,
          placeholder: '2020-12-31',
          description: 'The latest date that can be selected'
        },
        default: {
          type: 'datetime',
          constant: true,
          priority: 2,
          placeholder: '2020-01-01',
          description: 'The default date for the field',
        }
      })
    },
    fn: async function(attributes, emit, env) {
      let fi = await formInfo('date', attributes, env);

      if (attributes.position[0].name !== 'format') {
        throw new ParseError('format setting is required');
      }

      let format = await attributes.position[0].value(env, 'YYYY-MM-DD HH:mm');
      if (!format.trim()) {
        throw new ParseError('format setting cannot be blank');
      }

      if (!isValidDateOrTimeFormat(format, env.config.locale)) {
        throw new ParseError('"' + format + '" is not a valid date or time format – try YYYY-MM-DD or HH:mm');
      }

      for (let key of ['start', 'end', 'default']) {
        if (attributes.keys[key]) {
          if (!attributes.keys[key].config) {
            attributes.keys[key].config = {};
          }
          attributes.keys[key].config.format = format;
        }
      }

      await emit(this.tag, {
        command: 'formdate',
        type: 'date',
        attributes,
        formInfo: fi,
        format
      }, new Set([fi.name]));
    },
    allowedInAiBlaze: true
  },


  'FORMTEXT': {
    tag: 'form',
    description: 'Single-line text field',
    invalidIn: 'attribute',
    attributes: {
      positional: [0, 0],
      named: Object.assign({}, FORM_BASE, {
        cols: {
          type: 'number',
          priority: 1.5,
          placeholder: '20',
          description: 'The width of the field in columns of text',
          config: {
            minimum: 0
          }
        },
        width: { /** deprecated -- {img} used height/width w/ pixels */
          priority: -10,
          type: 'number'
        },
        default: {
          type: 'string',
          constant: true,
          priority: 2,
          placeholder: 'Placeholder',
          description: 'The default value for the field',
        }
      })
    },
    fn: async function(attributes, emit, env) {
      let fi = await formInfo('single_line', attributes, env);
      await emit(this.tag, {
        command: 'formtext',
        type: 'single_line',
        attributes,
        formInfo: fi
      }, new Set([fi.name]));
    },
    allowedInAiBlaze: true
  },


  'FORMPARAGRAPH': {
    tag: 'form',
    description: 'Multi-line text field',
    invalidIn: 'attribute',
    attributes: {
      positional: [0, 0],
      named: Object.assign({}, FORM_BASE, {
        cols: {
          type: 'number',
          priority: 1.5,
          placeholder: '20',
          description: 'The width of the field in columns of text',
          config: {
            minimum: 0
          }
        },
        rows: {
          type: 'number',
          priority: 1.5,
          placeholder: '4',
          description: 'The height of the field in rows of text',
          config: {
            minimum: 0
          }
        },
        width: { /** deprecated -- {img} used height/width w/ pixels */
          type: 'number',
          priority: -10
        },
        height: { /** deprecated -- {img} used height/width w/ pixels */
          type: 'number',
          priority: -10
        },
        default: {
          type: 'string',
          constant: true,
          priority: 2,
          placeholder: 'Placeholder',
          description: 'The default value for the field',
          config: {
            multiline: true
          }
        }
      })
    },
    fn: async function(attributes, emit, env) {
      let fi = await formInfo('multi_line', attributes, env);
      await emit(this.tag, {
        command: 'formparagraph',
        type: 'multi_line',
        attributes,
        formInfo: fi
      }, new Set([fi.name]));
    },
    allowedInAiBlaze: true
  },

  
  'FORMMENU': {
    tag: 'form',
    description: 'Select value from a menu',
    invalidIn: 'attribute',
    attributes: {
      positional: [0, 1000], // might be none due to having a default, and the values param
      named: Object.assign({}, FORM_BASE, {
        cols: {
          type: 'number',
          priority: 1.5,
          placeholder: '20',
          description: 'The width of the field in columns of text',
          config: {
            minimum: 0
          }
        },
        width: { // deprecated -- {img} used height/width w/ pixels
          type: 'number',
          priority: -10
        },
        default: {
          type: 'string',
          constant: true,
          priority: 2,
          placeholder: 'Placeholder',
          description: 'The default value for the menu',
          repeatable: true
        },
        multiple: {
          type: 'boolean',
          constant: true,
          priority: 1.4,
          placeholder: 'yes',
          description: 'Whether the user can select multiple items',
        },
        values: {
          type: 'string',
          list: 'positional',
          priority: -1,
          description: 'The menu options'
        },
        itemformatter: {
          type: 'lambda',
          priority: -1,
          placeholder: '(value) -> upper(value)',
          description: 'Function which formats individual menu items'
        }
      }),
      rejectFn: (attrs) => {
        let positional = attrs.position.filter(x => !x.name);
        if (positional.length === 0 && attrs.keys['default'] === undefined && attrs.keys['values'] === undefined) {
          return 'At least one menu option is required';
        }
        if (positional.length && attrs.keys['values'] !== undefined) {
          return 'Cannot specify "values" and positional options';
        }
      }
    },
    fn: async function(attributes, emit, env) {
      let fi = await formInfo('menu', attributes, env);

      if (!fi.multiple && attributes.position.filter(x => x.name === 'default').length > 1) {
        throw new ParseError('Cannot specify more than one default if "multiple" is not "yes"');
      }

      await emit(this.tag, {
        command: 'formmenu',
        type: 'menu',
        attributes,
        formInfo: fi
      }, new Set([fi.name]));
    },
    allowedInAiBlaze: true
  },


  'FORMTOGGLE': {
    tag: 'form',
    description: 'Checkbox to toggle contents',
    invalidIn: 'attribute',
    block: true,
    subType: 'toggle_start',
    attributes: {
      positional: [0, 0],
      named: Object.assign({}, FORM_BASE, {
        default: {
          type: 'boolean',
          constant: true,
          priority: 2,
          placeholder: 'yes',
          description: 'The default value for the toggle',
        }
      })
    },
    fn: async function(attributes, emit, env) {
      let fi = await formInfo(this.subType, attributes, env);
      await emit(this.tag, {
        command: 'formtoggle',
        type: this.subType,
        attributes,
        formInfo: fi
      }, new Set([fi.name]));
    },
    allowedInAiBlaze: true
  },

  
  'ENDFORMTOGGLE': {
    tag: 'form',
    invalidIn: 'attribute',
    block: true,
    subType: 'toggle_end',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true
  },


  'NOTE': {
    tag: 'note',
    description: 'Notes/guidance that is not inserted',
    invalidIn: 'attribute',
    block: true,
    subType: 'note_start',
    attributes: {
      positional: [0, 0],
      named: {
        insert: {
          priority: -1, // TODO: enable
          type: 'boolean',
          description: 'Whether the note contents is included when inserted',
          placeholder: 'no',
          static: true,
          required: false
        },
        preview: {
          priority: -1, // TODO: enable
          type: 'boolean',
          description: 'Whether the note contents is included in the form preview',
          placeholder: 'yes',
          static: true,
          required: false,
          default: true
        }
      }
    },
    fn: async function(attributes, emit, env) {
      let insert = false;
      if (attributes.keys.insert) {
        insert = await attributes.keys.insert.value(env);
      }
      let preview = true;
      if (attributes.keys.preview) {
        preview = await attributes.keys.preview.value(env);
      }

      await emit(this.tag, {
        command: 'note',
        type: this.subType,
        attributes,
        process: async (_finalAttrs, _env) => {
          return {
            insert,
            preview
          };
        }
      });
    },
    allowedInAiBlaze: true,
  },


  'ENDNOTE': {
    tag: 'note',
    invalidIn: 'attribute',
    block: true,
    subType: 'note_end',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true,
  },


  'LINK': {
    tag: 'link',
    description: 'Insert a dynamic link',
    invalidIn: 'attribute',
    block: true,
    subType: 'link_start',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'url',
        type: 'string',
        description: 'The link\'s url',
        placeholder: 'https://example.com/'
      },
      named: {
        window: {
          type: ['current', 'new'],
          priority: 2,
          placeholder: 'current',
          description: 'Whether to open the link in a new window/tab.'
        }
      }
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'link',
        type: this.subType,
        attributes
      });
    }
  },

  'ENDLINK': {
    tag: 'link',
    invalidIn: 'attribute',
    block: true,
    subType: 'link_end',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        type: this.subType,
        attributes
      });
    }
  },


  'ACTION': {
    tag: 'action',
    description: 'Create an action grouping',
    invalidIn: 'attribute',
    interaction: true,
    block: true,
    subType: 'action_start',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        command: 'action',
        type: this.subType,
        attributes,
        process: async (_finalAttrs, _env) => {
          return {
            start: true
          };
        }
      });
    }
  },


  'ENDACTION': {
    tag: 'action',
    invalidIn: 'attribute',
    block: true,
    subType: 'action_end',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        type: this.subType,
        attributes,
        process: async (_finalAttrs, _env) => {
          return {
            start: false
          };
        }
      });
    }
  },
  

  'IF': {
    tag: 'form',
    description: 'Include content conditional on a formula',
    invalidIn: 'attribute',
    block: true,
    subType: 'if_start',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'condition',
        type: 'equation',
        description: 'The condition to test',
        placeholder: '100 > 5'
      },
      named: {}
    },
    fn: async function(attributes, emit, env) {
      try {
        await attributes.position[0].ast(env);
      } catch (e) {
        throw new ParseError('Invalid formula for {if} – ' + e.message);
      }

      await emit(this.tag, {
        command: 'if',
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true
  },
  

  'ELSEIF': {
    tag: 'form',
    invalidIn: 'attribute',
    block: true,
    subType: 'if_elseif',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'condition',
        type: 'equation',
        description: 'The condition to test',
        placeholder: '100 > 5'
      },
      named: {}
    },
    fn: async function(attributes, emit, env) {
      try {
        await attributes.position[0].ast(env);
      } catch (e) {
        throw new ParseError('Invalid formula for {elseif} – ' + e.message);
      }

      await emit(this.tag, {
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true
  },


  'ELSE': {
    tag: 'form',
    invalidIn: 'attribute',
    block: true,
    subType: 'if_else',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true
  },

  
  'ENDIF': {
    tag: 'form',
    invalidIn: 'attribute',
    block: true,
    subType: 'if_end',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true
  },


  'REPEAT': {
    tag: 'form',
    description: 'Repeat contents one or more times',
    invalidIn: 'attribute',
    block: true,
    subType: 'repeat_start',
    attributes: {
      positional: [1, 1],
      positionalDef: {
        name: 'times',
        type: 'equation',
        description: 'How many times to repeat (or iterate over a list)',
        placeholder: '10'
      },
      named: {
        locals: {
          type: 'identifier',
          description: 'Form variable that will be populated with any local form data',
          priority: -1,
          default: 'name',
          static: true,
          holder: true
        }
      }
    },
    fn: async function(attributes, emit, env) {
      let tree;
      try {
        tree = await attributes.position[0].ast(env);
      } catch (e) {
        throw new ParseError('Invalid formula for {repeat} – ' + e.message);
      }
      if (tree.type === 'list_comprehension' && tree.info.for.info.args.length > 2) {
        // iterator only take (value, key/index)
        throw new ParseError('Too many iterator arguments for {repeat}.');
      }

      if (attributes.keys.locals && !attributes.keys.fillIn) {
        // Validate it's a valid name
        await attributes.keys.locals.value(env);
      }

      await emit(this.tag, {
        command: 'repeat',
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true
  },

  
  'ENDREPEAT': {
    tag: 'form',
    invalidIn: 'attribute',
    block: true,
    subType: 'repeat_end',
    attributes: {
      positional: [0, 0],
      named: {}
    },
    fn: async function(attributes, emit) {
      await emit(this.tag, {
        type: this.subType,
        attributes
      });
    },
    allowedInAiBlaze: true
  }
};


for (let type in COMMANDS) {
  if (!COMMANDS[type].attributes) {
    COMMANDS[type].attributes = {
      positional: [0, 0],
      named: {}
    };
  }

  let spec = COMMANDS[type].attributes;
  spec.named = Object.assign({
    trim: {
      type: ['yes', 'no', 'left', 'right'],
      config: {
        insensitive: false
      },
      priority: -.99,
      placeholder: 'yes',
      description: 'Trim whitespace around the command',
      typeError: '"trim" must be one of "yes", "no", "left" or "right"'
    }
  }, spec.named);
  spec.commandName = type.toLowerCase();

  COMMANDS[type].fn.bind(COMMANDS[type]);
}


function isURL(url) {
  return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b(.*)$/i.test(url);
}


/**
 * @param {string} type 
 * @param {import('./ParserUtils').AttributesType} attributes 
 * @param {import('./DataContainer').Environment} env 
 * 
 * @return {Promise<object>}
 */
async function formInfo(type, attributes, env) {
  let name = attributes.keys.name ? await attributes.keys.name.value(env) : null;

  if (attributes.keys.width && attributes.keys.cols) {
    throw new ParseError('Cannot use both "width" and "cols".');
  }
  if (attributes.keys.height && attributes.keys.rows) {
    throw new ParseError('Cannot use both "height" and "rows".');
  }

  let label;
  let key;
  if (name) {
    label = name;
  }
  if (name) {
    name = name.toLocaleLowerCase();
  } else {
    name = newName();
  }
  // This is making quick entries to generate only one instead multiple
  // Ignoring the issue for now as it is a edge case
  key = name.trim();

  
  let multiple = attributes.keys.multiple && (await attributes.keys.multiple.value(env));
  /** @type {import("./ParserUtils").NodeAttribute|import("./ParserUtils").NodeAttribute[]} */
  let def;
  if (multiple || type === 'formmenu') {
    def = attributes.position.filter(x => x.name === 'default');

    if (!multiple
      && def && def.length > 1 && attributes.keys.values) {
      throw new ParseError('You cannot have multiple "default" settings when using "values".');
    }

    if (!def.length) {
      def = undefined;
    }
  } else {
    def = attributes.keys.default;
  }

  return {
    default: def,
    type,
    multiple,
    format: type === 'date' && (await attributes.position[0].value(env)),
    label,
    key,
    name
  };
}

/** @typedef {{ command: 'site', type: 'site', part: 'title'|'text'|'html'|'url', multiple: boolean, frame: 'top'|'self', select: 'yes'|'no'|'ifneeded', page: string, selector?: string, xpath?: string, urlPart?: 'url'|'domain'|'path'|'protocol'|'query'|'hash', group: string }} SiteCommandDefinition */

/**
 * @param {import('./ParserUtils').AttributesType} attrs 
 * @param {import('./DataContainer').Environment} env 
 */
export async function getSiteSummary(attrs, env) {
  let part = await attrs.position[0].value(env);
  if (!['url', 'domain', 'path', 'protocol', 'query', 'hash', 'title', 'text', 'html'].includes(part)) {
    throw new ParseError('Unknown site type: ' + part);
  }
  if (!['text', 'html'].includes(part)) {
    if (attrs.keys['selector']) {
      throw new ParseError('"selector" may only be used with "html" or "text" site commands.');
    }
    if (attrs.keys['xpath']) {
      throw new ParseError('"xpath" may only be used with "html" or "text" site commands.');
    }
    if (attrs.keys['multiple']) {
      throw new ParseError('"multiple" may only be used with HTML or text site commands.');
    }
  }
  if (attrs.keys['multiple'] && !(attrs.keys['selector'] || attrs.keys['xpath'])) {
    throw new ParseError('"multiple" may only be used with "selector".');
  }
  if (attrs.keys['selector'] && attrs.keys['xpath']) {
    throw new ParseError('Only one of "xpath" or "selector" may be used.');
  }

  const page = attrs.keys.page ? await attrs.keys.page.value(env) : '';
  /**
   * https://data.blaze.today/space/5p0NkY2CAYbgvDkpYsHh8o/table/3vAAsNAqEvgx4NYlskgIaD/row/4Cokg6H8IfP1gDYCTmm3Bb
   * Note that the default value of the select field is dynamic
   * 
   * This is so as to satisfy the following use cases:
   * 1. In case user has specified a page, then they most likely
   * want to select data from any open tab. So it makes sense
   * to keep that as a default. There is only one use case for select=no
   * and that is to avoid running the snippet on a wrong page (by matching the URL).
   * But that is a rarer use case comparatively.
   * 
   * 2. If user has not specified a page, then for backwards compatibility, we
   * must use select=no. If select defaults to =ifneeded, then by implementation we
   * will match the selector against every open tab (not just the current tab).
   * That will break site selectors created before the page= era.
   */
  const selectDefault = !!page ? 'ifneeded' : 'no';

  /**
   * @type {SiteCommandDefinition}
   */
  const res = {
    command: 'site',
    type: 'site',
    part: ['title', 'text', 'html'].includes(part) ? part : 'url',
    frame:  attrs.keys.frame ? await attrs.keys.frame.value(env) : 'top',
    multiple: attrs.keys.multiple ? await attrs.keys.multiple.value(env) : false,
    select: attrs.keys.select ? await attrs.keys.select.value(env) : selectDefault,
    page,
    group: attrs.keys.group ? await attrs.keys.group.value(env) : '',
  };

  if (attrs.keys.selector) {
    res.selector = await attrs.keys.selector.value(env);
  }

  if (attrs.keys.xpath) {
    res.xpath = await attrs.keys.xpath.value(env);
  }

  if (res.part === 'url') {
    res.urlPart = part;
  }

  return res;
}